import { useEffect, useState } from "react";
import { apiGetProfileData } from "../api/user";
import DisplayUser from "../components/Profile/DisplayUser";
import EditForm from "../components/Profile/EditForm";
import BasicLayout from "../components/Layout/BasicLayout";
import ReactGA from "react-ga";

const Profile = () => {
  const [editMode, setEditMode] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    apiGetProfileData().then((res) => setUserData(res));
  }, []);

  return (
    <BasicLayout navbar={true}>
      {editMode === false ? (
        <DisplayUser userData={userData} setEditMode={setEditMode} />
      ) : (
        <EditForm
          userData={userData}
          setUserData={setUserData}
          setEditMode={setEditMode}
        />
      )}
    </BasicLayout>
  );
};

export default Profile;
