import React, { useState, useEffect } from "react";
import PaymentMethod from "../components/Payment/PaymentMethod";
import StripeWrapper from "../components/Payment/StripeWrapper";
import RegisterPlans from "../components/Payment/RegisterPlans";
import { PrimaryButton } from "../components/utils/DefaultButtons";
import BasicLayout from "../components/Layout/BasicLayout";
import StepOrProgress from "../components/Payment/StepOrProgress";
import ReactGA from "react-ga";

const renderPaymentStep = ({
  step,
  planType,
  paymentSchedule,
  setPlanType,
  setPaymentSchedule,
  clientSecret,
  setClientSecret,
  setStep,
}) => {
  switch (step) {
    case 1:
      return (
        <RegisterPlans
          setPlanType={setPlanType}
          planValue={planType}
          setSchedule={setPaymentSchedule}
          schedule={paymentSchedule}
          setClientSecret={setClientSecret}
          setStep={setStep}
          step={step}
        />
      );
    case 2:
      return (
        <div className="mx-auto sm:w-[85%] md:w-[80%] lg:w-[75%] xl:w-[60%]">
          <p className="text-center mt-4 text-gray-500">
            All payments are processed via Stripe. 16 Bit does not store your
            credit card information. If you have subscribed for a free trial,
            your credit card will not be charged until the trial period ends.
          </p>
          <StripeWrapper baseUrl="setup-payment" clientSecret={clientSecret}>
            <PaymentMethod
              setStep={setStep}
              returnUrl="register-success"
              clientSecret={clientSecret}
            />
          </StripeWrapper>
        </div>
      );
    default:
      return;
  }
};

const SetupPayment = () => {
  const [step, setStep] = useState(1);
  const [planType, setPlanType] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [paymentSchedule, setPaymentSchedule] = useState("monthly");

  const onClickBack = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <BasicLayout>
        <div className="flex flex-col gap-2 p-4">
          <StepOrProgress step={step} />
          <p className="text-bold justify-center text-center">
            DO NOT USE the back button provided by the browser or you will lose
            your progress!
          </p>
          {renderPaymentStep({
            step,
            planType,
            paymentSchedule,
            setPlanType,
            setPaymentSchedule,
            clientSecret,
            setClientSecret,
            setStep,
          })}
          {step === 2 && planType !== "" ? (
            // <div className="flex flex-row justify-between gap-2 mx-10 my-4">
            <div>
              <div className="flex flex-row w-full justify-center my-4 ">
                <PrimaryButton label={"Back"} onClick={onClickBack} />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </BasicLayout>
    </>
  );
};

export default SetupPayment;
