import NormalDistGraph from "./NormalDistGraph";
import { format_age } from "./ResultHelperFuncs";
import { useState } from "react";
import { useEffect } from "react";
import { apiGetInference } from "../../api/prediction";
import { useSearchParams } from "react-router-dom";
import { Base64DecodeUrl } from "../../api/urlEncoding";
import TableKeyValue from "../Table/TableKeyValue";

export default function ResultTab(props) {
  const { resultData, graphData } = props;
  const [data, setData] = useState("");
  const [, setInferenceId] = useState(null);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const fetchData = async (setter) => {
      let inference_id = Base64DecodeUrl(searchParams.get("id")); // get the inference id from props
      setInferenceId(inference_id);
      let r = await apiGetInference(inference_id);
      //setter(r);
      let bone_age = 0;
      bone_age = r.bone_age_months;
      if (
        bone_age >= r.chronologic_age_months - 2 * r.std_dev &&
        bone_age <= r.chronologic_age_months + 2 * r.std_dev
      ) {
        setData("Appropriate");
      }
      if (bone_age < r.chronologic_age_months - 2 * r.std_dev) {
        setData("Delayed");
      }
      if (bone_age > r.chronologic_age_months + 2 * r.std_dev) {
        setData("Advanced");
      }
    };
    fetchData(setData);
  }, [searchParams]);

  const inputRows = [
    {
      key: "Chronological Age",
      value: format_age(resultData.chronologic_age_months),
    },
    {
      key: "Sex",
      value: resultData.sex === true ? "Male" : "Female",
    },
  ];

  const outputRows = [
    {
      key: "Estimated Bone Age",
      value: resultData.formatted_prediction,
    },
    {
      key: "Standard Deviation",
      value: `${parseFloat(resultData.std_dev).toFixed(2)} months`,
    },
    {
      key: "Physis Z score",
      value: parseFloat(resultData.z_score).toFixed(1),
    },
    {
      key: "Percentile",
      value: `${parseFloat(resultData.percentile * 100).toFixed(0)} %`,
    },
  ];

  return (
    <div className="my-4">
      <h3 className="text-2xl text-center mt-2">Inputs</h3>
      <TableKeyValue rows={inputRows} />
      <h3 className="text-2xl text-center mt-2">Outputs</h3>
      <TableKeyValue rows={outputRows} />

      {/*Graph*/}
      <div className="hidden sm:block mb-6">
        <h5 className="mt-8 text-2xl text-center">
          Normal distribution for {resultData.sex === true ? "Male" : "Female"}{" "}
          age {format_age(resultData.chronologic_age_months)}{" "}
        </h5>
        <div className="mt-4 mr-6">
          {/* chart centered using global css */}
          <NormalDistGraph resultData={resultData} graphData={graphData} />
        </div>
        <div className="mt-4 text-xs">
          <p className="text-center">
            Appropriate bone age range defined as 2 SDs (using Brush Foundation
            data) above and below the Chronological Age.{" "}
          </p>
          <p className="text-center">
            (Greulich, W. W. & Pyle, S. I. (1959). Radiographic Atlas of
            Skeletal Development of the Hand and Wrist. Stanford University
            Press.)
          </p>
        </div>
      </div>
    </div>
  );
}
