import { useState, useEffect } from "react";

const getStyle = (level) => {
  switch (level) {
    case 1:
      return { marginLeft: `${level - 2}em`, listStyleType: "disc" };
    case 2:
      return { marginLeft: `${level - 2}em`, listStyleType: "circle" };
    case 3:
      return { marginLeft: `${level - 2}em`, listStyleType: "square" };
    case 4:
      return { marginLeft: `${level - 2}em`, listStyleType: "square" };
    case 5:
      return { marginLeft: `${level - 2}em`, listStyleType: "square" };
    default:
      return null;
  }
};

const TableOfContent = () => {
  const [headings, setHeadings] = useState([]);
  useEffect(() => {
    const elements = Array.from(
      document.querySelectorAll("h1, h2, h3, h4, h5")
    ).map((elem) => ({
      id: elem.id,
      text: elem.innerText,
      level: Number(elem.nodeName.charAt(1)),
    }));
    setHeadings(elements);
    console.log(headings);
  }, []);
  console.log(headings);
  return (
    <nav className="hidden md:block md:col-span-2">
      <ul>
        {headings.map((heading) => (
          <li key={heading.id} style={getStyle(heading.level)}>
            <a
              href={`#${heading.id}`}
              onClick={(e) => {
                e.preventDefault();
                document.querySelector(`#${heading.id}`).scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              {heading.text}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TableOfContent;
