import { PrimaryButton } from "../utils/DefaultButtons";
import { useState } from "react";
import StripeWrapper from "./StripeWrapper";
import PaymentMethod from "./PaymentMethod";
import { apiUpdatePaymentMethod } from "../../api/payment";
import TableCurrentPaymentMethod from "../Table/TableKeyValue";

const PaymentMethodDisplay = (props) => {
  const { paymentMethod } = props;

  const rows = [
    {
      key: "Brand",
      value: paymentMethod.brand.toUpperCase(),
    },
    {
      key: "Card Number",
      value: `**** **** **** ${paymentMethod.last4}`,
    },
    {
      key: "Expiry (MM/YYYY)",
      value: paymentMethod.expMonth + "/" + paymentMethod.expYear,
    },
  ];

  const [clientSecret, setClientSecret] = useState("");

  const handleClickUpdate = async () => {
    const res = await apiUpdatePaymentMethod();
    setClientSecret(res.clientSecret);
  };

  return (
    <>
      {clientSecret ? (
        <StripeWrapper clientSecret={clientSecret}>
          <PaymentMethod returnUrl="payments" clientSecret={clientSecret} />
        </StripeWrapper>
      ) : (
        <div>
          <TableCurrentPaymentMethod rows={rows} />
          <div className="flex justify-center my-8">
            <PrimaryButton label="Update Card" onClick={handleClickUpdate} />
          </div>
        </div>
      )}
    </>
  );
};
export default PaymentMethodDisplay;
