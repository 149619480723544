import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { apiGetInference } from "../../api/prediction";
import { maleAtlasData, femaleAtlasData } from "./AtlasData";
import { Base64DecodeUrl } from "../../api/urlEncoding";
import { apiUpdateInference, apiGetUserAccessInfo } from "../../api/prediction";
import Loading from "../utils/Loading";
import ResultAtlas from "./ResultAtlas";
import ResultTab from "./ResultTab";
import ResultReport from "./ResultReport";
import { getIndexFemale, getIndexMale } from "./ReportUtils.js";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../api/auth";
import { trackIncrementalProperty } from "../../api/mixpanel";
import { CancelButton, PrimaryButton } from "../utils/DefaultButtons";

const normalY = (x, mean, stdDev) =>
  Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));

const init_chart = (std_dev, mean) => {
  let x_array = [
    mean - 4 * std_dev,
    mean - 3 * std_dev,
    mean - 2 * std_dev,
    mean - 1 * std_dev,
    mean,
    mean + 1 * std_dev,
    mean + 2 * std_dev,
    mean + 3 * std_dev,
    mean + 4 * std_dev,
  ];

  let seriesData = x_array.map((x) => ({
    x: (x / 12).toFixed(2),
    y: normalY(x, mean, std_dev).toFixed(2),
  }));
  return seriesData;
};

export default function ResultFunc() {
  const [resultData, setResultData] = useState(null);
  const [viewOption, setViewOption] = useState("result");
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [img, setImg] = useState(null);
  const [atlasPosition, setAtlasPosition] = useState(0);
  const [nextOff, setNextOff] = useState(false);
  const [backOff, setBackOff] = useState(true);
  const [displayHints, setDisplayHints] = useState(false);
  const [displayReport, setDisplayReport] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [reportBoneAge, setReportBoneAge] = useState(null);
  const [inferenceId, setInferenceId] = useState(null);
  const [accessInfo, setAccessInfo] = useState({
    atlas: false,
    report: false,
    customreport: false,
  });
  const navigate = useNavigate();
  const [reportTitle, setReportTitle] = useState("Customize Report");
  const [feedbackAgeMonths, setFeedbackAgeMonths] = useState(0);
  const [feedbackAgeYears, setFeedbackAgeYears] = useState(0);
  const [buttonText, setButtonText] = useState("Copy to Clipboard");

  const FEMALE = false;
  const MALE = true;

  // default to male atlas data
  let atlasData = maleAtlasData;
  const email = getToken().sub;

  useEffect(() => {
    // Get the inference id from props
    let inference_id = Base64DecodeUrl(searchParams.get("id"));
    setInferenceId(inference_id);

    const fetchData = async (setter, imgSetter, graphSetter) => {
      if (!isNaN(parseInt(inference_id))) {
        let userAccessInfo = await apiGetUserAccessInfo();

        if (userAccessInfo["is_active"] === false) {
          navigate("/setup-payment");
        }

        let r = await apiGetInference(inference_id);
        setter(r);
        imgSetter(r.image_base_64);
        setLoading(false);
        setReportBoneAge(r.formatted_prediction);
        setAccessInfo(userAccessInfo);
        if (userAccessInfo["report"] === false) {
          setReportTitle("Feedback");
        } else {
          setReportTitle("Customize Report");
        }
        let atlasindex = 0;

        if (r.sex === FEMALE) {
          atlasindex = getIndexFemale(r.bone_age_months);
        } else if (r.sex === MALE) {
          atlasindex = getIndexMale(r.bone_age_months);
        }

        setAtlasPosition(atlasindex);
        if (r.sex === FEMALE) {
          if (atlasindex === 0) {
            setBackOff(true);
            setNextOff(false);
          } else if (atlasindex === 26) {
            setBackOff(false);
            setNextOff(true);
          } else {
            setBackOff(false);
            setNextOff(false);
          }
        }

        if (r.sex === MALE) {
          if (atlasindex === 0) {
            setBackOff(true);
            setNextOff(false);
          } else if (atlasindex === 30) {
            setBackOff(false);
            setNextOff(true);
          } else {
            setBackOff(false);
            setNextOff(false);
          }
        }

        let std = r.std_dev;
        let mean = r.chronologic_age_months; //r.mean;
        let data_calculated = init_chart(std, mean);
        const graph_data_formatted = data_calculated.map((_item, _index) => {
          return { name: _item["x"], pv: _item["y"] };
        });
        graphSetter(graph_data_formatted);
      }
    };
    fetchData(
      setResultData,
      setImg,
      setGraphData,
      setReportBoneAge,
      setAccessInfo
    );
  }, [searchParams]);

  if (loading === true || !img) {
    return <Loading />;
  } else {
    if (resultData.sex === false) {
      atlasData = femaleAtlasData;
    }
  }

  const openAtlas = async () => {
    trackIncrementalProperty(email, "Viewed Atlas");
    setViewOption("atlas");
    await apiUpdateInference({ atlas: true }, inferenceId);
  };

  const openResult = () => {
    trackIncrementalProperty(email, "Viewed Results");
    setViewOption("result");
  };
  const openReport = () => {
    trackIncrementalProperty(email, "Customized Report");
    setViewOption("report");
  };

  const showReport = async () => {
    trackIncrementalProperty(email, "Analysis Agreement");
    await apiUpdateInference({ agreement: true, feedback_age_months: null }, inferenceId);
    setDisplayReport("report");

    // edit_div only exists if access.
    if (displayReport === "report" && accessInfo["report"] === true) {
      document.getElementById("edit_div").focus();
    }
  };

  const showNoForm = async () => {
    //await apiUpdateInference({ agreement: false }, inferenceId);
    setDisplayReport("form");
  };

  const cancelForm = () => {
    setDisplayReport(null);
  };

  function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }

  const getDivText = async (e) => {
    trackIncrementalProperty(email, "Copied to Clipboard");
    //some cases navigator.clipboard doesnt work and require https secure conn hence used alternative approach
    copyToClipboard(document.getElementById("edit_div").textContent.trim());
    //navigator.clipboard.writeText(document.getElementById('edit_div').textContent);
    await apiUpdateInference({ copied: true }, inferenceId);
    setButtonText("Copied!");
    setTimeout(() => {
      setButtonText("Copy to Clipboard");
    }, 1000);
  };

  const changeBoneAge = async () => {
    // this function gets called when the bone age feedback is updated from the report form
    // store the number of months entered into the database
    let ageInMonths =
      parseInt(feedbackAgeMonths) + parseInt(feedbackAgeYears) * 12;
    let data = {
      agreement: false,
      feedback_age_months: ageInMonths,
    };

    await apiUpdateInference(data, inferenceId);
    setReportBoneAge(
      feedbackAgeYears.toString() +
        " years " +
        feedbackAgeMonths.toString() +
        " months"
    );
    setDisplayReport("report");
  };

  function MouseOver(event) {
    var el = document.getElementById("edit_div");
    var range = document.createRange();
    var sel = window.getSelection();
    range.setStart(el.childNodes[0], 2);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
    el.focus();
    // document.getElementById("edit_div").focus()
  }

  function MouseOff(event) {
    document.getElementById("edit_div").blur();
  }

  const styles = {
    tabSelected:
      "bg-gray-700 hover:bg-gray-700 h-11 text-white py-2 px-4 border border-gray-600",
    defaultTab:
      "bg-black hover:bg-gray-700 h-11 text-white py-2 px-4 border border-gray-600",
  };

  const reportFeedbackLimiter = (e, lowerLimit, upperLimit) => {
    let inputValue = e.target.value;
    if (isNaN(parseFloat(inputValue))) {
      e.target.value = "";
      return;
    }
    if (inputValue < lowerLimit) {
      e.target.value = lowerLimit;
      return;
    }
    if (inputValue > upperLimit) {
      e.target.value = upperLimit;
      return;
    }
  };

  return (
    <div className="text-white w-[95%] mx-auto">
      <h1 className="m-4 mt-8 text-center text-3xl">Bone Age Assessment</h1>
      <div className="grid grid-cols-1 gap-8 mx-auto sm:w-[90%] xl:grid-cols-2">
        <div className="flex flex-col items-center gap-4 mb-4">
          {/* for larger screens where width is greater than 640px I am using fixed width and height for the image container. 
          There is no cropping since image is using object-contain property.
          What is the problem with just using max width and height?
          Generally images don't have aspect ratio of 1:1 which means both height and width will never be 600px.
          */}
          <div className="max-w-[600px] max-h-[600px] sm:h-[600px] sm:w-[600px] xl:mt-[126px] bg-pureBlack">
            <img
              src={`data:image/jpg;base64,${img}`}
              alt="PatientImage"
              className="object-contain w-full h-full"
            />
          </div>
          <h3 className="text-2xl text-center">Patient Image</h3>
          <p>
            Algorithm output may be unreliable if image displayed is not
            oriented with fingers pointing upwards.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col sm:flex-row self-center">
            <button
              className={
                viewOption === "result" ? styles.tabSelected : styles.defaultTab
              }
              onClick={openResult}
            >
              View Results
            </button>
            <button
              className={
                viewOption === "atlas" ? styles.tabSelected : styles.defaultTab
              }
              onClick={openAtlas}
            >
              G&P Atlas
            </button>
            <button
              className={
                viewOption === "report" ? styles.tabSelected : styles.defaultTab
              }
              onClick={openReport}
            >
              {reportTitle}
            </button>
          </div>

          {viewOption === "result" && (
            <ResultTab resultData={resultData} graphData={graphData} />
          )}

          {viewOption === "atlas" && accessInfo["atlas"] && (
            <ResultAtlas
              atlasData={atlasData}
              nextOff={nextOff}
              backOff={backOff}
              atlasPosition={atlasPosition}
              displayHints={displayHints}
              setAtlasPosition={setAtlasPosition}
              setNextOff={setNextOff}
              setBackOff={setBackOff}
              setDisplayHints={setDisplayHints}
            />
          )}
          {viewOption === "atlas" && accessInfo["atlas"] === false && (
            <div>
              <h4 className="text-xl text-center mt-2">
                Please consult your reference atlas. To access Greulich & Pyle
                Atlas images and hints using Physis<sup>TM</sup>, you must
                upgrade your plan.
              </h4>
              <div className="flex justify-center my-4">
                <PrimaryButton
                  onClick={() => navigate("/payments")}
                  label={"Upgrade Plan"}
                />
              </div>
            </div>
          )}

          {viewOption === "report" && (
            <div>
              {displayReport === null && (
                <div className="my-4">
                  <h3 className="text-2xl text-center mt-2">Feedback</h3>
                  <h4 className="text-xl text-center">
                    Do you agree with the bone age estimation of{" "}
                    {resultData.formatted_prediction}?
                  </h4>
                  <div className="flex justify-center gap-4 mt-3">
                    {/* <button
                      className="bg-blue-600 hover:bg-primary font-bold py-2 px-4 rounded-full"
                      onClick={showReport}
                    >
                      Yes
                    </button>
                    <button
                      className="bg-blue-600 hover:bg-primary font-bold py-2 px-4 rounded-full"
                      onClick={showNoForm}
                    >
                      No
                    </button> */}
                    <PrimaryButton onClick={showReport} label={"Yes"} />
                    <PrimaryButton onClick={showNoForm} label={"No"} />
                  </div>
                </div>
              )}
              {displayReport === "report" && accessInfo["report"] === true && (
                <div>
                  <ResultReport
                    MouseOver={MouseOver}
                    MouseOff={MouseOff}
                    getDivText={getDivText}
                    buttonText={buttonText}
                    resultData={resultData}
                    reportBoneAge={reportBoneAge}
                  />
                </div>
              )}

              {displayReport === "report" && accessInfo["report"] === false && (
                <h4 className="text-2xl text-center my-4">
                  Thank you for your feedback!
                </h4>
              )}

              {displayReport === "form" && (
                <div className="mt-4">
                  <h1 className="text-2xl text-center">
                    What is the appropriate bone age?
                  </h1>
                  <div className="mt-4">
                    <div className="flex flex-row justify-center gap-3">
                      <div className="flex flex-row gap-2">
                        <input
                          className="h-8"
                          type="number"
                          id="years"
                          min="0"
                          max="20"
                          onKeyUp={(e) => {
                            if (parseInt(e.target.value) > 20)
                              e.target.value = 20;
                          }}
                          onKeyDown={(e) => {
                            if (parseInt(e.target.value < 0))
                              e.target.value = 0;
                          }}
                          placeholder="0"
                          onChange={(e) => {
                            let value = 0;
                            reportFeedbackLimiter(e, 0, 20);
                            if (e.target.value !== "") {
                              value = e.target.value;
                            }
                            setFeedbackAgeYears(value);
                          }}
                        ></input>
                        <p>years</p>
                      </div>
                      <div className="flex flex-row gap-2">
                        <input
                          className="h-8"
                          type="number"
                          id="months"
                          placeholder="0"
                          min="0"
                          max="11"
                          onKeyUp={(e) => {
                            if (parseInt(e.target.value > 11))
                              e.target.value = 1;
                          }}
                          onKeyDown={(e) => {
                            if (parseInt(e.target.value < 0))
                              e.target.value = 0;
                          }}
                          onChange={(e) => {
                            let value = 0;
                            reportFeedbackLimiter(e, 0, 11);
                            if (e.target.value !== "") {
                              value = e.target.value;
                            }
                            setFeedbackAgeMonths(value);
                          }}
                        ></input>
                        <p className="text-center">months</p>
                      </div>
                    </div>
                    <div className="flex flex-row gap-4 mt-4 justify-center my-4">
                      <PrimaryButton onClick={changeBoneAge} label={"Submit"} />
                      {/* <button
                        className="bg-blue-600 hover:bg-primary font-bold py-2 px-4 rounded-full"
                        type="submit"
                        onClick={changeBoneAge}
                      >
                        Submit
                      </button>
                      <button
                        className="border border-slate-400 font-bold py-2 px-4 rounded-full"
                        type="button"
                        onClick={cancelForm}
                      >
                        Cancel
                      </button> */}
                      <CancelButton onClick={cancelForm} label={"Cancel"} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
