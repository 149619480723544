import client from "./axios";

export const apiGetProfileData = async () => {
  const res = await client.get("/user/profile-data");
  return res.data;
};

export const apiUpdateUser = async (userData) => {
  const res = await client.post("/user/update-profile", userData);
  return res.data;
};

export const apiGetScanCount = async () => {
  const res = await client.get("/user/get-scan-count");
  return res.data;
};
