import client from './axios';

export const apiSubscribe = async (planType, schedule) => {
  try {
    const res = await client.get(`/pay/subscribe?stripe_price_id=${planType}`);
    return res.data;
} catch (err) {
  return { msg: 'failed' };
}
};

export const apiInitialPaymentSuccess = async () => {
  try {
    const res = await client.get('/pay/setup-initial-payment-success');
    return res.data;
} catch (err) {
  return { msg: 'failed' };
}
};

export const apiGetPlanDetails = async () => {
  try {
    const res = await client.get('/pay/get-plans');
    return res.data;
} catch (err) {
  return { msg: 'failed' };
}
};

export const apiGetUserPayData = async () => {
  try {
    const res = await client.get('/pay/user-payment-data');
    return res.data;
} catch (err) {
  return { msg: 'failed' };
}
};

export const apiUpdatePaymentMethod = async () => {
  try {
    const res = await client.get('/pay/update-user-payment-method');
    return res.data;
} catch (err) {
  return { msg: 'failed' };
}
};

export const apiChargeUserOutOfScan = async () => {
  try {
    const res = await client.get(`/pay/charge-user-out-of-scan`);
    return res.data;
  } catch (err) {
    return { msg: 'failed' };
  }
};

export const apiCheckIfCardUsed = async () => {
  try {
    const res = await client.get('/pay/check_if_card_used_before');
    return res.data;
} catch (err) {
  return { msg: 'failed' };
}
};
