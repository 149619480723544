import {
  AddUserIcon,
  CheckIcon,
  CreditCardIcon,
  SelectSubscriptionIcon,
} from "../utils/Icons";
const StepBar = (props) => {
  const { step } = props;

  return (
    <div className="flex flex-row mx-5 justify-center">
      <IconWithLabel label="Create Account" onStep={step} stepValue={0}>
        <AddUserIcon />
      </IconWithLabel>
      <Bar isDone={step > 0} />
      <IconWithLabel label="Choose Plan" onStep={step} stepValue={1}>
        <SelectSubscriptionIcon />
      </IconWithLabel>
      <Bar isDone={step > 1} />
      <IconWithLabel
        label="Enter Payment Information"
        onStep={step}
        stepValue={2}
      >
        <CreditCardIcon />
      </IconWithLabel>
      <Bar isDone={step > 2} />
      <IconWithLabel label="Start with Physis" onStep={step} stepValue={3}>
        <CheckIcon />
      </IconWithLabel>
    </div>
  );
};

const IconWithLabel = (props) => {
  const { onStep, label, stepValue } = props;

  let iconStyle =
    "rounded-full w-14 h-14 p-3 flex justify-center items-center absolute ";
  iconStyle +=
    onStep === stepValue
      ? "bg-gray-300 text-gray-800" // selected
      : onStep < stepValue
      ? "bg-slate-500" // incomplete
      : "bg-purple-700"; // complete

  return (
    <div className="flex flex-col gap-2 justify-center items-center">
      <div className={iconStyle}>{props.children}</div>
      <span className="justify-center text-md absolute mt-24">{label}</span>
    </div>
  );
};

const Bar = (props) => {
  const { isDone } = props;
  const color = isDone ? "bg-purple-700" : "bg-slate-500";
  const barStyle = `rounded-full ${color} h-1 w-1/4`;
  return <div className={barStyle}></div>;
};

export default StepBar;
