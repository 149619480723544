import { useState } from "react";
import ModalLayout from "../utils/ModalLayout";
import { apiSetUnsubFeedback } from "../../api/change_plans";

export const UnsubscribeFormModal = (props) => {

    const { setShowUnsubFeedbackForm } = props;

    const [q1, setQ1] = useState('')
    const [q2, setQ2] = useState('')

    const q1Text = "1. Why are you unsubscribing to Physis?";
    const q2Text = "2. What did you like most about using Physis?";

    const styles = {
      q : "flex text-gray-400 justify-start mt-4 mb-2",
      text: "flex flex-grow w-full px-1 h-24 text-black resize-vertical"
    }

    const onSubmit = async () => {
      await apiSetUnsubFeedback({'q1': q1, 'q2': q2});
      setShowUnsubFeedbackForm(false);
      window.location.reload();
    }

    const onClose = () => {
      setShowUnsubFeedbackForm(false);
      window.location.reload();
    }


    return (
      <ModalLayout onClose={onClose}>
        <div className="flex justify-center text-xl mb-2">Physis Feedback</div>
        <hr />
        <div className={styles.q}>{q1Text}</div>
        <textarea className={styles.text} value={q1} onChange={(e) => setQ1(e.target.value)}></textarea>
        <div className={styles.q}>{q2Text}</div>
        <textarea className={styles.text} value={q2} onChange={(e) => setQ2(e.target.value)}></textarea>
        <div className="flex flex-row gap-4 justify-center">
          <button 
          className='bg-gray-700 hover:bg-gray-600 text-gray-100 py-2 px-4 rounded mt-2'
          onClick={onSubmit}>
            Submit
          </button>
        </div>
      </ModalLayout>
    )
}

export default UnsubscribeFormModal;