const TermsOfServiceContent = () => {
  return (
    <div className="md:col-span-4">
      <p>
        <strong>Last updated: </strong>March 21, 2023
      </p>
      <p>
        Please read these terms and conditions carefully before using{" "}
        <strong>Our Service</strong>.
      </p>
      <h1 id="heading-1">Interpretation and Definitions</h1>
      <h2 id="heading-2">Interpretation</h2>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h2 id="heading-3">Definitions</h2>
      <p>
        For the purposes of these <strong>Terms and Conditions</strong>:
      </p>
      <p>
        <strong>Affiliate </strong>means an entity that controls, is controlled
        by or is under common control with a party, where "control" means
        ownership of 50% or more of the shares, equity interest or other
        securities entitled to vote for election of directors or other managing
        authority.
      </p>
      <p>
        <strong>Account</strong> means a unique account created for{" "}
        <strong>You</strong> to access our <strong>Service</strong> or parts of
        our <strong>Service</strong>.
      </p>
      <p>
        <strong>Country</strong> refers to: Ontario, Canada
      </p>
      <p>
        <strong>Company</strong> (referred to as either "the{" "}
        <strong>Company</strong>", "<strong>We</strong>", "<strong>Us</strong>"
        or "<strong>Our</strong>" in this Agreement) refers to 16 Bit Inc.
      </p>
      <p>
        <strong>Content </strong>refers to content such as text, images, or
        other information that can be posted, uploaded, linked to or otherwise
        made available by <strong>You</strong>, regardless of the form of that
        content.
      </p>
      <p>
        <strong>Device</strong> means any device that can access the{" "}
        <strong>Service</strong> such as a computer, a cellphone or a digital
        tablet.
      </p>
      <p>
        <strong>Feedback</strong> means feedback, innovations or suggestions
        sent by <strong>You</strong> regarding the attributes, performance or
        features of our <strong>Service</strong>.
      </p>
      <p>
        <strong>Free Trial </strong>refers to a limited period of time that may
        be free when purchasing a <strong>Subscription</strong>.
      </p>
      <p>
        <strong>Goods</strong> refer to the items offered for sale on the
        <strong> Service</strong>.
      </p>
      <p>
        <strong>Orders </strong>mean a request by <strong>You</strong> to
        purchase <strong>Goods</strong> from <strong>Us</strong>.
      </p>
      <p>
        <strong>Service</strong> refers to the <strong>Website</strong>.
      </p>
      <p>
        <strong>Subscriptions</strong> refer to the services or access to the{" "}
        <strong>Service</strong> offered on a subscription basis by the{" "}
        <strong>Company</strong> to <strong>You</strong>.
      </p>
      <p>
        <strong>Terms and Conditions</strong> (also referred as "
        <strong>Terms</strong>") mean these{" "}
        <strong>Terms and Conditions</strong> that form the entire agreement
        between <strong>You</strong> and the <strong>Company</strong> regarding
        the use of the <strong>Service</strong>.
      </p>
      <p>
        <strong>Third-party Social Media Service</strong> means any services or
        content (including data, information, products or services) provided by
        a third-party that may be displayed, included or made available by the{" "}
        <strong>Service</strong>.
      </p>
      <p>
        <strong>Website </strong>refers to Physis, accessible from
        https://physis.16bit.ai
      </p>
      <p>
        <strong>You</strong> means the individual accessing or using the{" "}
        <strong>Service</strong>, or the company, or other legal entity on
        behalf of which such individual is accessing or using the{" "}
        <strong>Service</strong>, as applicable.
      </p>
      <h1 id="heading-4">Acknowledgment</h1>
      <p>
        These are the <strong>Terms and Conditions</strong> governing the use of
        this <strong>Service </strong>and the agreement that operates between{" "}
        <strong>You </strong>and the <strong>Company</strong>. These{" "}
        <strong>Terms and Conditions</strong> set out the rights and obligations
        of all users regarding the use of the <strong>Service</strong>.
      </p>
      <p>
        Your access to and use of the <strong>Service</strong> is conditioned on{" "}
        <strong>Your</strong> acceptance of and compliance with these{" "}
        <strong>Terms and Conditions</strong>. These{" "}
        <strong>Terms and Conditions</strong> apply to all visitors, users and
        others who access or use the <strong>Service</strong>.
      </p>
      <p>
        By accessing or using the <strong>Service</strong>,<strong> You</strong>{" "}
        agree to be bound by these <strong>Terms and Conditions</strong>. If{" "}
        <strong>You</strong> disagree with any part of these{" "}
        <strong>Terms and Conditions</strong> then <strong>You</strong> may not
        access the <strong>Service</strong>.
      </p>
      <p>
        <strong>You</strong> represent that you are over the age of 18. The{" "}
        <strong>Company</strong> does not permit those under 18 to use the{" "}
        <strong>Service</strong>.
      </p>
      <p>
        <strong>Your</strong> access to and use of the <strong>Service</strong>{" "}
        is also conditioned on <strong>Your</strong> acceptance of and
        compliance with the <strong>Privacy Policy</strong> of the{" "}
        <strong>Company</strong>. <strong>Our Privacy Policy</strong> describes{" "}
        <strong>Our </strong>policies and procedures on the collection, use and
        disclosure of <strong>Your </strong>personal information when{" "}
        <strong>You </strong>use the <strong>Application</strong> or the{" "}
        <strong>Website </strong>and tells <strong>You</strong> about{" "}
        <strong>Your</strong> privacy rights and how the law protects{" "}
        <strong>You</strong>. Please read <strong>Our Privacy Policy</strong>{" "}
        carefully before using <strong>Our Service</strong>.
      </p>
      <h1 id="heading-5">Placing Orders for Goods</h1>
      <p>
        By placing an <strong>Order for Goods</strong> through the{" "}
        <strong>Service</strong>, <strong>You</strong> warrant that{" "}
        <strong>You </strong>are legally capable of entering into binding
        contracts.
      </p>
      <h2 id="heading-6">Your Information</h2>
      <p>
        If <strong>You</strong> wish to place an{" "}
        <strong>Order for Goods </strong>available on the{" "}
        <strong>Service</strong>, <strong>You</strong> may be asked to supply
        certain information relevant to <strong>Your Order</strong> including,
        without limitation, <strong>Your </strong>name,<strong> Your </strong>
        email, <strong>Your </strong>phone number, <strong>Your</strong> credit
        card number, the expiration date of <strong>Your </strong>credit card,{" "}
        <strong>Your</strong> billing address, and <strong>Your</strong>{" "}
        shipping information.
      </p>
      <p>
        <strong>You</strong> represent and warrant that: (i){" "}
        <strong>You </strong>have the legal right to use any credit or debit
        card(s) or other payment method(s) in connection with any{" "}
        <strong>Order</strong>; and that (ii) the information{" "}
        <strong>You</strong> supply to us is true, correct and complete.
      </p>
      <p>
        By submitting such information, <strong>You </strong>grant us the right
        to provide the information to payment processing third parties for
        purposes of facilitating the completion of <strong>Your Order</strong>.
      </p>
      <h2 id="heading-7">Order Cancellation</h2>
      <p>
        <strong>We</strong> reserve the right to refuse or cancel{" "}
        <strong>Your Order</strong> at any time for certain reasons including
        but not limited to:
      </p>
      <p>
        <strong>Goods</strong> availability
      </p>
      <p>
        Errors in the description or prices for <strong>Goods</strong>
      </p>
      <p>
        Errors in <strong>Your Order</strong>
      </p>
      <p>
        We reserve the right to refuse or cancel <strong>Your Order</strong> if
        fraud or an unauthorized or illegal transaction is suspected.
      </p>
      <h2 id="heading-8">Your Order Cancellation Rights</h2>
      <p>
        Any <strong>Goods</strong>
        <strong>you</strong> purchase can only be returned in accordance with
        these <strong>Terms and Conditions</strong> and{" "}
        <strong>Our Returns Policy</strong>.
      </p>
      <p>
        Our <strong>Returns Policy </strong>forms a part of these{" "}
        <strong>Terms and Conditions</strong>. Please read our{" "}
        <strong>Returns Policy</strong> to learn more about your right to cancel{" "}
        <strong>Your Order</strong>.
      </p>
      <p>
        <strong>Your </strong>right to cancel an <strong>Order</strong> only
        applies to <strong>Goods </strong>that are returned in the same
        condition as <strong>You</strong> received them. <strong>You </strong>
        should also include all of the products instructions, documents and
        wrappings. <strong>Goods </strong>that are damaged or not in the same
        condition as <strong>You </strong>received them or which are worn simply
        beyond opening the original packaging will not be refunded.{" "}
        <strong>You </strong>should therefore take reasonable care of the
        purchased <strong>Goods </strong>while they are in
        <strong> Your </strong>possession.
      </p>
      <p>
        <strong>We</strong> will reimburse <strong>You</strong> no later than 14
        days from the day on which <strong>We</strong> receive the returned{" "}
        <strong>Goods</strong>. <strong>We</strong> will use the same means of
        payment as <strong>You</strong> used for the <strong>Order</strong>, and{" "}
        <strong>You</strong> will not incur any fees for such reimbursement.
      </p>
      <p>
        <strong>You </strong>will not have any right to cancel an{" "}
        <strong>Order </strong>for the supply of any of the following{" "}
        <strong>Goods</strong>:
      </p>
      <p>
        The supply of<strong> Goods </strong>made to <strong>You</strong>r
        specifications or clearly personalized.
      </p>
      <p>
        The supply of <strong>Goods</strong> which according to their nature are
        not suitable to be returned, deteriorate rapidly or where the date of
        expiry is over.
      </p>
      <p>
        The supply of <strong>Goods </strong>which are not suitable for return
        due to health protection or hygiene reasons and were unsealed after
        delivery.
      </p>
      <p>
        The supply of <strong>Goods </strong>which are, after delivery,
        according to their nature, inseparably mixed with other items.
      </p>
      <p>
        The supply of digital content which is not supplied on a tangible medium
        if the performance has begun with <strong>Your</strong> prior express
        consent and <strong>You </strong>have acknowledged <strong>Your</strong>{" "}
        loss of cancellation right.
      </p>
      <h2 id="heading-9">Availability, Errors and Inaccuracies</h2>
      <p>
        <strong>We</strong> are constantly updating <strong>Our</strong>{" "}
        offerings of <strong>Goods </strong>on the <strong>Service</strong>. The{" "}
        <strong>Goods </strong>available on <strong>Our Service </strong>may be
        mispriced, described inaccurately, or unavailable, and{" "}
        <strong>We </strong>may experience delays in updating information
        regarding our <strong>Goods</strong> on the <strong>Service</strong> and
        in <strong>Our</strong> advertising on other websites.
      </p>
      <p>
        <strong>We</strong> cannot and do not guarantee the accuracy or
        completeness of any information, including prices, product images,
        specifications, availability, and services. <strong>We</strong> reserve
        the right to change or update information and to correct errors,
        inaccuracies, or omissions at any time without prior notice.
      </p>
      <h2 id="heading-10">Prices Policy</h2>
      <p>
        The <strong>Company </strong>reserves the right to revise its prices at
        any time prior to accepting an <strong>Order</strong>.
      </p>
      <p>
        The prices quoted may be revised by the <strong>Company </strong>
        subsequent to accepting an <strong>Order </strong>in the event of any
        occurrence affecting delivery caused by government action, variation in
        customs duties, increased shipping charges, higher foreign exchange
        costs and any other matter beyond the control of the{" "}
        <strong>Company</strong>. In that event, <strong>You</strong> will have
        the right to cancel <strong>Your Order</strong>.
      </p>
      <h2 id="heading-11">Payments</h2>
      <p>
        All <strong>Goods</strong> purchased are subject to a one-time payment.
        Payment can be made through various payment methods we have available,
        such as Visa, MasterCard, Affinity Card, American Express cards or
        online payment methods (PayPal, for example).
      </p>
      <p>
        Payment cards (credit cards or debit cards) are subject to validation
        checks and authorization by Your card issuer. If we do not receive the
        required authorization, <strong>We</strong> will not be liable for any
        delay or non-delivery of <strong>Your Order</strong>.
      </p>
      <h1 id="heading-12">Subscriptions</h1>
      <h2 id="heading-13">Subscription period</h2>
      <p>
        The<strong> Service</strong> or some parts of the{" "}
        <strong>Service </strong>are available only with a paid{" "}
        <strong>Subscription</strong>. <strong>You</strong> will be billed in
        advance on a recurring and periodic basis (such as daily, weekly,
        monthly or annually), depending on the type of{" "}
        <strong>Subscription</strong> plan you select when purchasing the{" "}
        <strong>Subscription</strong>.
      </p>
      <p>
        At the end of each period, <strong>Your Subscription </strong>will
        automatically renew under the exact same conditions unless
        <strong> You</strong> cancel it or the <strong>Company</strong> cancels
        it.
      </p>
      <h2 id="heading-14">Subscription cancellations</h2>
      <p>
        <strong>You </strong>may cancel <strong>Your Subscription</strong>{" "}
        renewal either through<strong> Your Account</strong> settings page only.
        The <strong>Company </strong>will not fulfill cancellation requests on
        your behalf sent through contact forms or email. In order to cancel{" "}
        <strong>your</strong> subscription, <strong>you</strong> must log in and
        go to "My Payments" in the top right dropdown under your email. Then
        click "Unsubscribe" - <strong>You</strong> will be immediately
        unsubscribed from your plan. <strong>You</strong> will not receive a
        refund for the fees <strong>You</strong> already paid for{" "}
        <strong>Your </strong>current <strong>Subscription </strong>period and
        <strong> You</strong> will be able to access the{" "}
        <strong>Service</strong> until the end of <strong>Your</strong> current{" "}
        <strong>Subscription</strong> period.
      </p>
      <h2 id="heading-15">Billing</h2>
      <p>
        <strong>You</strong> shall provide the <strong>Company</strong> with
        accurate and complete billing information including full name, address,
        state, zip code, telephone number, and a valid payment method
        information.
      </p>
      <p>
        Should automatic billing fail to occur for any reason, the{" "}
        <strong>Company</strong> will issue an electronic invoice indicating
        that you must proceed manually, within a certain deadline date, with the
        full payment corresponding to the billing period as indicated on the
        invoice.
      </p>
      <h2 id="heading-16">Fee Changes</h2>
      <p>
        The <strong>Company</strong>, in its sole discretion and at any time,
        may modify the <strong>Subscription</strong> fees. Any{" "}
        <strong>Subscription</strong> fee change will become effective at the
        end of the then-current <strong>Subscription </strong>period.
      </p>
      <p>
        The <strong>Company </strong>will provide <strong>You</strong> with
        reasonable prior notice of any change in <strong>Subscription </strong>
        fees to give <strong>You </strong>an opportunity to terminate{" "}
        <strong>Your Subscription</strong> before such change becomes effective.
      </p>
      <p>
        <strong>Your</strong> continued use of the <strong>Service </strong>
        after the <strong>Subscription </strong>fee change comes into effect
        constitutes <strong>Your </strong>agreement to pay the modified{" "}
        <strong>Subscription</strong> fee amount.
      </p>
      <h2 id="heading-17">Refunds</h2>
      <p>
        Except when required by law, paid <strong>Subscription</strong> fees are
        non-refundable.
      </p>
      <p>
        Certain refund requests for <strong>Subscriptions </strong>may be
        considered by the <strong>Company </strong>on a case-by-case basis and
        granted at the sole discretion of the <strong>Company</strong>.
      </p>
      <h2 id="heading-18">Free Trial</h2>
      <p>
        The <strong>Company </strong>may, at its sole discretion, offer a{" "}
        <strong>Subscription</strong> with a <strong>Free Trial </strong>for a
        limited period of time.
      </p>
      <p>
        <strong>You </strong>may be required to enter <strong>Your</strong>{" "}
        billing information in order to sign up for the{" "}
        <strong>Free Trial</strong>.
      </p>
      <p>
        If <strong>You </strong>do enter<strong> Your </strong>billing
        information when signing up for a <strong>Free Trial</strong>,{" "}
        <strong>You</strong> will not be charged by the{" "}
        <strong>Company </strong>until the <strong>Free Trial </strong>has
        expired. On the last day of the <strong>Free Trial </strong>period,
        unless <strong>You</strong> cancelled <strong>Your Subscription</strong>
        , <strong>You</strong> will be automatically charged the applicable{" "}
        <strong>Subscription</strong> fees for the type of{" "}
        <strong>Subscription You</strong> have selected.
      </p>
      <p>
        At any time and without notice, the <strong>Company</strong> reserves
        the right to (i) modify the terms and conditions of the{" "}
        <strong>Free Trial</strong> offer, or (ii) cancel such{" "}
        <strong>Free Trial </strong>offer.
      </p>
      <h1 id="heading-19">User Accounts</h1>
      <p>
        When <strong>You</strong> create an account with <strong>Us</strong>,{" "}
        <strong>You</strong> must provide <strong>Us</strong> information that
        is accurate, complete, and current at all times. Failure to do so
        constitutes a breach of the <strong>Terms</strong>, which may result in
        immediate termination of <strong>Your</strong> account on{" "}
        <strong>Our Service</strong>.
      </p>
      <p>
        <strong>You </strong>are responsible for safeguarding the password that{" "}
        <strong>You </strong>use to access the <strong>Service</strong> and for
        any activities or actions under <strong>Your</strong> password, whether{" "}
        <strong>Your </strong>password is with <strong>Our Service</strong> or a{" "}
        <strong>Third-Party Social Media Service</strong>.
      </p>
      <p>
        <strong>You </strong>agree not to disclose<strong> Your </strong>
        password to any third party. <strong>You </strong>must notify{" "}
        <strong>Us </strong>immediately upon becoming aware of any breach of
        security or unauthorized use of <strong>Your</strong> account.
      </p>
      <p>
        <strong>You</strong> may not use as a username the name of another
        person or entity or that is not lawfully available for use, a name or
        trademark that is subject to any rights of another person or entity
        other than <strong>You</strong> without appropriate authorization, or a
        name that is otherwise offensive, vulgar or obscene.
      </p>
      <h1 id="heading-20">Content</h1>
      <h2 id="heading-21">Your Right to Post Content</h2>
      <p>
        <strong>Our Service</strong> allows <strong>You</strong> to post{" "}
        <strong>Content</strong>. <strong>You</strong> are responsible for the{" "}
        <strong>Content </strong>that <strong>You</strong> post to the{" "}
        <strong>Service</strong>, including its legality, reliability, and
        appropriateness.
      </p>
      <p>
        By posting <strong>Content</strong> to the <strong>Service</strong>,{" "}
        <strong>You</strong> grant <strong>Us </strong>the right and license to
        use, modify, publicly perform, publicly display, reproduce, and
        distribute such <strong>Content</strong> on and through the{" "}
        <strong>Service</strong>. <strong>You</strong> retain any and all of{" "}
        <strong>Your</strong> rights to any <strong>Content You </strong>submit,
        post or display on or through the <strong>Service </strong>and{" "}
        <strong>You </strong>are responsible for protecting those rights.{" "}
        <strong>You</strong> agree that this license includes the right for{" "}
        <strong>Us</strong> to make <strong>Your Content</strong> available to
        other users of the <strong>Service</strong>, who may also use{" "}
        <strong>Your Content</strong> subject to these <strong>Terms</strong>.
      </p>
      <p>
        <strong>You </strong>represent and warrant that: (i) the{" "}
        <strong>Content </strong>is <strong>Yours</strong> (<strong>You</strong>{" "}
        own it) or <strong>You </strong>have the right to use it and grant
        <strong> Us </strong>the rights and license as provided in these{" "}
        <strong>Terms</strong>, and (ii) the posting of{" "}
        <strong>Your Content </strong>on or through the{" "}
        <strong>Service </strong>does not violate the privacy rights, publicity
        rights, copyrights, contract rights or any other rights of any person.
      </p>
      <h2 id="heading-22">Content Restrictions</h2>
      <p>
        The <strong>Company</strong> is not responsible for the content of the{" "}
        <strong>Service</strong>'s users. <strong>You</strong> expressly
        understand and agree that <strong>You</strong> are solely responsible
        for the <strong>Content</strong> and for all activity that occurs under
        your account, whether done so by <strong>You</strong> or any third
        person using <strong>Your </strong>account.
      </p>
      <p>
        <strong>You </strong>may not transmit any <strong>Content</strong> that
        is unlawful, offensive, upsetting, intended to disgust, threatening,
        libelous, defamatory, obscene or otherwise objectionable. Examples of
        such objectionable <strong>Content</strong> include, but are not limited
        to, the following:
      </p>
      <p>Unlawful or promoting unlawful activity.</p>
      <p>
        Defamatory, discriminatory, or mean-spirited content, including
        references or commentary about religion, race, sexual orientation,
        gender, national/ethnic origin, or other targeted groups.
      </p>
      <p>
        Spam, machine – or randomly – generated, constituting unauthorized or
        unsolicited advertising, chain letters, any other form of unauthorized
        solicitation, or any form of lottery or gambling.
      </p>
      <p>
        Containing or installing any viruses, worms, malware, trojan horses, or
        other content that is designed or intended to disrupt, damage, or limit
        the functioning of any software, hardware or telecommunications
        equipment or to damage or obtain unauthorized access to any data or
        other information of a third person.
      </p>
      <p>
        Infringing on any proprietary rights of any party, including patent,
        trademark, trade secret, copyright, right of publicity or other rights.
      </p>
      <p>
        Impersonating any person or entity including the Company and its
        employees or representatives.
      </p>
      <p>Violating the privacy of any third person.</p>
      <p>False information and features.</p>
      <p>
        The <strong>Company</strong> reserves the right, but not the obligation,
        to, in its sole discretion, determine whether or not any{" "}
        <strong>Content</strong> is appropriate and complies with this{" "}
        <strong>Terms</strong>, refuse or remove this <strong>Content.</strong>{" "}
        The <strong>Company</strong> further reserves the right to make
        formatting and edits and change the manner of any{" "}
        <strong>Content</strong>. The <strong>Company</strong> can also limit or
        revoke the use of the <strong>Service</strong> if <strong>You </strong>
        post such objectionable <strong>Content</strong>. As the{" "}
        <strong>Company </strong>cannot control all content posted by users
        and/or third parties on the <strong>Service</strong>, you agree to use
        the <strong>Service </strong>at your own risk. <strong>You </strong>
        understand that by using the <strong>Service You </strong>may be exposed
        to content that <strong>You</strong> may find offensive, indecent,
        incorrect or objectionable, and <strong>You</strong> agree that under no
        circumstances will the <strong>Company </strong>be liable in any way for
        any content, including any errors or omissions in any content, or any
        loss or damage of any kind incurred as a result of <strong>your</strong>{" "}
        use of any content.
      </p>
      <h2 id="heading-23">Content Backups</h2>
      <p>
        Although regular backups of <strong>Content</strong> are performed, the
        <strong> Company</strong> does not guarantee there will be no loss or
        corruption of data.
      </p>
      <p>
        Corrupt or invalid backup points may be caused by, without limitation,{" "}
        <strong>Content </strong>that is corrupted prior to being backed up or
        that changes during the time a backup is performed.
      </p>
      <p>
        The <strong>Company </strong>will provide support and attempt to
        troubleshoot any known or discovered issues that may affect the backups
        of <strong>Content</strong>. But <strong>You</strong> acknowledge that
        the <strong>Company</strong> has no liability related to the integrity
        of <strong>Content </strong>or the failure to successfully restore{" "}
        <strong>Content </strong>to a usable state.
      </p>
      <p>
        <strong>You </strong>agree to maintain a complete and accurate copy of
        any <strong>Content</strong> in a location independent of the{" "}
        <strong>Service</strong>.
      </p>
      <h1 id="heading-24">Copyright Policy</h1>
      <h2 id="heading-25">Intellectual Property Infringement</h2>
      <p>
        <strong>We</strong> respect the intellectual property rights of others.
        It is <strong>Our</strong> policy to respond to any claim that{" "}
        <strong>Content </strong>posted on the <strong>Service </strong>
        infringes a copyright or other intellectual property infringement of any
        person.
      </p>
      <p>
        If <strong>You</strong> are a copyright owner, or authorized on behalf
        of one, and <strong>You</strong> believe that the copyrighted work has
        been copied in a way that constitutes copyright infringement that is
        taking place through the <strong>Service</strong>, <strong>You </strong>
        must submit <strong>Your</strong> notice in writing to the attention of
        our copyright agent via email at https://www.16bit.ai/contact and
        include in <strong>Your</strong> notice a detailed description of the
        alleged infringement.
      </p>
      <p>
        <strong>You</strong> may be held accountable for damages (including
        costs and attorneys' fees) for misrepresenting that any{" "}
        <strong>Content</strong> is infringing <strong>Your</strong> copyright.
      </p>
      <h2 id="heading-26">
        DMCA Notice and DMCA Procedure for Copyright Infringement Claims
      </h2>
      <p>
        <strong>You</strong> may submit a notification pursuant to the Digital
        Millennium Copyright Act (DMCA) by providing our Copyright Agent with
        the following information in writing (see 17 U.S.C 512(c)(3) for further
        detail):
      </p>
      <p>
        An electronic or physical signature of the person authorized to act on
        behalf of the owner of the copyright's interest.
      </p>
      <p>
        A description of the copyrighted work that <strong>You</strong> claim
        has been infringed, including the URL (i.e., web page address) of the
        location where the copyrighted work exists or a copy of the copyrighted
        work.
      </p>
      <p>
        Identification of the URL or other specific location on the
        <strong> Service</strong> where the material that <strong>You </strong>
        claim is infringing is located.
      </p>
      <p>
        <strong>Your </strong>address, telephone number, and email address.
      </p>
      <p>
        A statement by <strong>You</strong> that <strong>You</strong> have a
        good faith belief that the disputed use is not authorized by the
        copyright owner, its agent, or the law.
      </p>
      <p>
        A statement by <strong>You</strong>, made under penalty of perjury, that
        the above information in <strong>Your </strong>notice is accurate and
        that <strong>You</strong> are the copyright owner or authorized to act
        on the copyright owner's behalf.
      </p>
      <p>
        <strong>You</strong> can contact us at https://www.16bit.ai/contactUpon
        receipt of a notification, the <strong>Company </strong>will take
        whatever action, in its sole discretion, it deems appropriate, including
        removal of the challenged content from the <strong>Service</strong>.
      </p>
      <h1 id="heading-27">Your Feedback to Us</h1>
      <p>
        <strong>You</strong> assign all rights, title and interest in any{" "}
        <strong>Feedback You </strong>provide the <strong>Company</strong>. If
        for any reason such assignment is ineffective, <strong>You</strong>{" "}
        agree to grant the <strong>Company</strong> a non-exclusive, perpetual,
        irrevocable, royalty free, worldwide right and license to use,
        reproduce, disclose, sub-license, distribute, modify and exploit such
        Feedback without restriction.
      </p>
      <h1 id="heading-28">Links to Other Websites</h1>
      <p>
        <strong>Our Service</strong> may contain links to third-party web sites
        or services that are not owned or controlled by the{" "}
        <strong>Company</strong>.
      </p>
      <p>
        The <strong>Company</strong> has no control over, and assumes no
        responsibility for, the content, privacy policies, or practices of any
        third party web sites or services. <strong>You</strong> further
        acknowledge and agree that the <strong>Company</strong> shall not be
        responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services.
      </p>
      <p>
        We strongly advise <strong>You</strong> to read the terms and conditions
        and privacy policies of any third-party web sites or services that{" "}
        <strong>You</strong> visit.
      </p>
      <h1 id="heading-29">Termination</h1>
      <p>
        <strong>We</strong> may terminate or suspend{" "}
        <strong>Your Account </strong>immediately, without prior notice or
        liability, for any reason whatsoever, including without limitation if{" "}
        <strong>You</strong> breach these <strong>Terms and Conditions</strong>.
      </p>
      <p>
        Upon termination, <strong>Your </strong>right to use the{" "}
        <strong>Service </strong>will cease immediately. If <strong>You</strong>{" "}
        wish to terminate <strong>Your Account</strong>, <strong>You</strong>{" "}
        may simply discontinue using the <strong>Service</strong>.
      </p>
      <h1 id="heading-30">Limitation of Liability</h1>
      <p>
        Notwithstanding any damages that <strong>You</strong> might incur, the
        entire liability of the <strong>Company </strong>and any of its
        suppliers under any provision of this <strong>Terms</strong> and{" "}
        <strong>Your </strong>exclusive remedy for all of the foregoing shall be
        limited to the amount actually paid by <strong>You </strong>through the{" "}
        <strong>Service</strong> or 100 USD if <strong>You </strong>haven't
        purchased anything through the <strong>Service</strong>.
      </p>
      <p>
        To the maximum extent permitted by applicable law, in no event shall the{" "}
        <strong>Company</strong> or its suppliers be liable for any special,
        incidental, indirect, or consequential damages whatsoever (including,
        but not limited to, damages for loss of profits, loss of data or other
        information, for business interruption, for personal injury, loss of
        privacy arising out of or in any way related to the use of or inability
        to use the <strong>Service</strong>, third-party software and/or
        third-party hardware used with the <strong>Service</strong>, or
        otherwise in connection with any provision of this{" "}
        <strong>Terms</strong>), even if the <strong>Company</strong> or any
        supplier has been advised of the possibility of such damages and even if
        the remedy fails of its essential purpose.
      </p>
      <p>
        Some states do not allow the exclusion of implied warranties or
        limitation of liability for incidental or consequential damages, which
        means that some of the above limitations may not apply. In these states,
        each party's liability will be limited to the greatest extent permitted
        by law.
      </p>
      <h1 id="heading-31">"AS IS" and "AS AVAILABLE" Disclaimer</h1>
      <p>
        The <strong>Service </strong>is provided to You "AS IS" and "AS
        AVAILABLE" and with all faults and defects without warranty of any kind.
        To the maximum extent permitted under applicable law, the{" "}
        <strong>Company</strong>, on its own behalf and on behalf of its{" "}
        <strong>Affiliates </strong>and its and their respective licensors and
        service providers, expressly disclaims all warranties, whether express,
        implied, statutory or otherwise, with respect to the{" "}
        <strong>Service</strong>, including all implied warranties of
        merchantability, fitness for a particular purpose, title and
        non-infringement, and warranties that may arise out of course of
        dealing, course of performance, usage or trade practice. Without
        limitation to the foregoing, the <strong>Company</strong> provides no
        warranty or undertaking, and makes no representation of any kind that
        the <strong>Service</strong> will meet <strong>Your </strong>
        requirements, achieve any intended results, be compatible or work with
        any other software, applications, systems or services, operate without
        interruption, meet any performance or reliability standards or be error
        free or that any errors or defects can or will be corrected.
      </p>
      <p>
        Without limiting the foregoing, neither the <strong>Company</strong> nor
        any of the <strong>company</strong>'s provider makes any representation
        or warranty of any kind, express or implied: (i) as to the operation or
        availability of the Service, or the information, content, and materials
        or products included thereon; (ii) that the Service will be
        uninterrupted or error-free; (iii) as to the accuracy, reliability, or
        currency of any information or content provided through the{" "}
        <strong>Service</strong>; or (iv) that the <strong>Service</strong>, its
        servers, the content, or e-mails sent from or on behalf of the{" "}
        <strong>Company</strong> are free of viruses, scripts, trojan horses,
        worms, malware, timebombs or other harmful components.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of certain types of
        warranties or limitations on applicable statutory rights of a consumer,
        so some or all of the above exclusions and limitations may not apply to{" "}
        <strong>You</strong>. But in such a case the exclusions and limitations
        set forth in this section shall be applied to the greatest extent
        enforceable under applicable law.
      </p>
      <h1 id="heading-32">Governing Law</h1>
      <p>
        The laws of the <strong>Country</strong>, excluding its conflicts of law
        rules, shall govern this <strong>Terms</strong> and{" "}
        <strong>Your </strong>use of the <strong>Service</strong>.{" "}
        <strong>Your</strong> use of the <strong>Application </strong>may also
        be subject to other local, state, national, or international laws.
      </p>
      <h1 id="heading-33">Disputes Resolution</h1>
      <p>
        If <strong>You </strong>have any concern or dispute about the{" "}
        <strong>Service</strong>, <strong>You</strong> agree to first try to
        resolve the dispute informally by contacting the{" "}
        <strong>Company</strong>.
      </p>
      <h2 id="heading-34">For European Union (EU) Users</h2>
      <p>
        If <strong>You</strong> are a European Union consumer, you will benefit
        from any mandatory provisions of the law of the country in which you are
        resident in.
      </p>
      <h2 id="heading-35">
        United States Federal Government End Use Provisions
      </h2>
      <p>
        If <strong>You</strong> are a U.S. federal government end user, our{" "}
        <strong>Service</strong> is a "Commercial Item" as that term is defined
        at 48 C.F.R. §2.101.
      </p>
      <h2 id="heading-36">United States Legal Compliance</h2>
      <p>
        <strong>You </strong>represent and warrant that (i) <strong>You</strong>{" "}
        are not located in a country that is subject to the United States
        government embargo, or that has been designated by the United States
        government as a "terrorist supporting" country, and (ii){" "}
        <strong>You </strong>are not listed on any United States government list
        of prohibited or restricted parties.
      </p>
      <h1 id="heading-37">Severability and Waiver</h1>
      <h2 id="heading-38">Severability</h2>
      <p>
        If any provision of these <strong>Terms</strong> is held to be
        unenforceable or invalid, such provision will be changed and interpreted
        to accomplish the objectives of such provision to the greatest extent
        possible under applicable law and the remaining provisions will continue
        in full force and effect.
      </p>
      <h2 id="heading-39">Waiver</h2>
      <p>
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under these<strong> Terms</strong> shall
        not effect a party's ability to exercise such right or require such
        performance at any time thereafter nor shall the waiver of a breach
        constitute a waiver of any subsequent breach.
      </p>
      <h2 id="heading-40">Translation Interpretation</h2>
      <p>
        These <strong>Terms and Conditions</strong> may have been translated if{" "}
        <strong>We</strong> have made them available to <strong>You</strong> on
        our <strong>Service</strong>. <strong>You </strong>agree that the
        original<strong> English </strong>text shall prevail in the case of a
        dispute.
      </p>
      <h2 id="heading-41">Changes to These Terms and Conditions</h2>
      <p>
        <strong>We </strong>reserve the right, at <strong>Our </strong>sole
        discretion, to modify or replace these <strong>Terms </strong>at any
        time. If a revision is material <strong>We</strong> will make reasonable
        efforts to provide at least 30 days' notice prior to any new terms
        taking effect. What constitutes a material change will be determined at{" "}
        <strong>Our</strong> sole discretion.
      </p>
      <p>
        By continuing to access or use <strong>Our Service</strong> after those
        revisions become effective, You agree to be bound by the revised terms.
        If <strong>You </strong>do not agree to the new terms, in whole or in
        part, please stop using the website and the <strong>Service</strong>.
      </p>
      <h1 id="heading-42">Contact Us</h1>
      <p>
        If <strong>you</strong> have any questions about these
        <strong> Terms and Conditions</strong>, <strong>You</strong> can contact
        us:
      </p>
      <p>By visiting this page on our website: https://www.16bit.ai/contact</p>
    </div>
  );
};

export default TermsOfServiceContent;
