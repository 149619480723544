import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiLogin } from "../api/auth";
import BasicLayout from "../components/Layout/BasicLayout";
import ReactGA from "react-ga";

const LoginForm = () => {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await apiLogin(username, password);

    if (res === "denied") {
      setErrorMessage("Invalid username or password");
    } else {
      navigate(res.redirectTo);
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
        <input
          placeholder="email"
          type="text"
          onChange={(e) => setUserName(e.target.value)}
        />
        <input
          placeholder="password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="mt-1 text-red-400">
          {errorMessage && <p> {errorMessage} </p>}
        </div>
        <div>
          <button
            className="bg-blue-600 hover:bg-blue-700 mt-2 mb-2 w-full text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default function Login() {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   const cookies = document.cookie.split(";");
  //   cookies.map((cookie) => {
  //     if (cookie.includes("access")) {
  //       navigate("/dashboard");
  //     }
  //   });
  // }, [navigate]);

  const textStyle = "max-w-md text-justify text-sm my-4";
  return (
    <BasicLayout navbar={false}>
      <div className="flex w-full justify-center place-items-center p-4">
        <div className="items-center">
          <div className="flex flex-col items-center justify-center">
            <img
              className="hidden sm:block object-scale-down h-16"
              src="images/16bit-logo.png"
              alt="logo"
            />
            <h5 className="text-2xl font-medium mt-4 mb-4 text-center">
              Physis&trade; Login
            </h5>
            <p className={textStyle}>
              Pediatric bone age examinations are hand x-rays performed to
              assess a patient's skeletal age. Physis™ is a bone age calculator
              that provides a rapid, automated estimate using AI, based on the
              bone age atlas of Gruelich and Pyle. If you choose, your
              subscription can include access to a digital version of the G&P
              Atlas and automatic report generation.{" "}
            </p>
            <p className={textStyle}>
              {" "}
              The use of this device is reserved for physicians who have been
              trained to assess skeletal age. Physis™ is not meant to replace
              the judgment and clinical expertise of a physician. See{" "}
              <a href="https://www.16bit.ai/physis">here</a> for more
              information.
            </p>
          </div>

          <LoginForm />

          <p className="justify-self-start">
            Don't have an account?{" "}
            <Link to="/register">Register for 7 days free trial</Link>
          </p>
          <p>
            Forgot password? <Link to="/send-reset-password-link">Reset</Link>
          </p>

          <p className="text-md mt-4 text-gray-400">
            Licensed by Health Canada&#174;
          </p>
        </div>
      </div>
    </BasicLayout>
  );
}
