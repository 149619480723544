export const PrimaryButton = ({ addOnStyle, onClick, label, isSubmit }) => {
  return (
    <button
      className={
        'bg-primary hover:bg-blue-700 max-w-sm min-w-[100px] min-h-11 text-white py-2 px-4 rounded border border-primary focus:outline-none focus:shadow-outline ' +
        addOnStyle
      }
      type={isSubmit === true ? 'submit' : 'button'}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export const CancelButton = ({ addOnStyle, onClick, label }) => {
  return (
    <button
      className={   
        'bg-black text-gray-200 hover:bg-gray-700 max-w-sm min-w-[100px] min-h-11 py-2 px-4 rounded focus:outline-none border border-gray-200 focus:shadow-outline ' +
        addOnStyle
      }
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export const DeleteButton = ({addOnStyle, onClick, label }) => {
  return (
    <button
      className={   
        'bg-red-600 hover:bg-red-700 max-w-sm min-w-[150px] min-h-11text-white py-2 px-4 rounded border border-red-700 focus:outline-none focus:shadow-outline ' +
        addOnStyle
      }
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export const UtilButton = ({ addOnStyle, onClick, label }) => {
  return (
    <button
      className={
        'mt-4 p-2 max-w-sm min-w-200 bg-blue-600 hover:bg-blue-700' +
        addOnStyle
      }
      onClick={onClick}
    >
      {label}
    </button>
  );
};
