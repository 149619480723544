import { Link } from "react-router-dom";

const Footer = () => {
  const currDate = new Date(); // Programmatically use the date.
  return (
    // <footer className="w-full bg-black border-t border-gray-700  flex flex-col sm:fixed sm:bottom-0 p-2 items-center">
    <footer className="w-full bg-black border-t border-gray-700  flex flex-col p-2 items-center">
      <p className="text-gray-500">
        Copyright © 16BIT. {currDate.getFullYear()}. All Rights Reserved.
      </p>
      <div className="flex flex-row gap-2">
        <Link to="/privacy" target="_blank">
          Privacy
        </Link>
        <Link to="/terms" target="_blank">
          Terms of Use
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
