const RegisterPlanCard = (props) => {
  const { planDetails, currentPlanValue, schedule } = props;
  const { onPlanChange } = props;

  const isSelected =
    planDetails.stripe_pricing_id[schedule] === currentPlanValue;
  const planStyle = isSelected ? "pricing-card-featured" : "pricing-card";

  const btnStyle = isSelected ? "sub-button-select" : "sub-button";

  const blueTextStyle = isSelected
    ? "text-white grid place-items-center"
    : "text-blue-800 grid place-items-center";

  const slateTextStyle = isSelected ? "text-white" : "text-slate-800";

  const featureTextStyle = isSelected
    ? "feature-text w-5/6 text-white"
    : "feature-text w-5/6";

  const checkCircle = isSelected
    ? "/images/Check-Circle-White.svg"
    : "/images/check-circle-1.svg";

  return (
    <div className={planStyle}>
      {planDetails.plan_name === "Advanced" && (
        <div className={slateTextStyle + " text-center font-small"}>
          Most Popular Plan
        </div>
      )}
      {planDetails.plan_name !== "Advanced" && (
        <div className={slateTextStyle + " font-small"}>‎ </div>
      )}
      <h3 className={blueTextStyle}>
        {planDetails.plan_name.charAt(0).toUpperCase() +
          planDetails.plan_name.slice(1)}
      </h3>
      {planDetails.plan_name !== "pay-as-you-go" ? (
        schedule === "monthly" ? (
          <center>
            <h2 className={slateTextStyle}>
              {"$" + planDetails.plan_price.monthly.toFixed(2).slice(0, -3)}{" "}
              <sup className="relative top-px align-top text-sm">
                {planDetails.plan_price.monthly.toFixed(2).slice(-2)}
              </sup>
            </h2>
          </center>
        ) : (
          <center>
            <h2 className={slateTextStyle}>
              {"$" + planDetails.plan_price.yearly.toFixed(2).slice(0, -3)}{" "}
              <sup className="relative top-px align-top text-sm">
                {planDetails.plan_price.yearly.toFixed(2).slice(-2)}
              </sup>
            </h2>
          </center>
        )
      ) : (
        <div>
          <center>
            <h2 className={slateTextStyle + " inline"}>
              {"$" + planDetails.plan_price["per-use"].toFixed(2).slice(0, -3)}{" "}
              <sup className="relative top-px align-top text-sm">
                {planDetails.plan_price["per-use"].toFixed(2).slice(-2)}
              </sup>
            </h2>
            <div className={slateTextStyle + " inline"}>/Inference</div>
          </center>
        </div>
      )}
      {planDetails.plan_name !== "pay-as-you-go" ? (
        schedule === "monthly" ? (
          <center>
            <h5 className={slateTextStyle + " font-medium"}>per month</h5>
          </center>
        ) : (
          <center>
            <h5 className={slateTextStyle + " font-medium"}>per year</h5>
          </center>
        )
      ) : (
        <></>
      )}
      <div className="pricing-features">
        {planDetails.plan_description.map((_perk, _ind) => {
          if (_ind <= 0) return <></>;
          return (
            <div className="flex items-center box-border">
              <img src={checkCircle} alt="" className="mr-4 w-6" />
              <div className={featureTextStyle}>{_perk}</div>
            </div>
          );
        })}
      </div>
      <button className={btnStyle} onClick={onPlanChange}>
        {planDetails.plan_name !== "pay-as-you-go"
          ? isSelected
            ? "Confirm"
            : "7 days Free Trial"
          : isSelected
          ? "Confirm"
          : "Start Now"}
      </button>
    </div>
  );
};

export default RegisterPlanCard;
