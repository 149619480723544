import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PrimaryButton,
  CancelButton,
} from "../components/utils/DefaultButtons";
import { apiInitialPaymentSuccess, apiCheckIfCardUsed } from "../api/payment";
import PaymentLoading from "../components/utils/PaymentLoading";
import BasicLayout from "../components/Layout/BasicLayout";
import StepOrProgress from "../components/Payment/StepOrProgress";
import ReactGA from "react-ga";

const RegisterSuccess = () => {
  const [cardUsed, setCardUsed] = useState(null);
  const [paymentFailed, setPaymentFailed] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    apiCheckIfCardUsed().then((res) => {
      //console.log(res);
      //if user is already active, ignore this request
      if (res.is_active === true) {
        setCardUsed(false);
        setPaymentFailed(false);
        setIsLoading(false);
      } else if (res.cardUsed === true) {
        setIsLoading(false);
        setCardUsed(true);
      } else {
        // setIsLoading(true);
        apiInitialPaymentSuccess().then((res) => {
          //console.log(res);
          if (res["msg"] === "failed") {
            setPaymentFailed(true);
          } else {
            setPaymentFailed(false);
          }

          setIsLoading(false);
        });
        // setIsLoading(false);
      }
    });
  }, []);

  const clickConfirmCardModal = async () => {
    setIsLoading(true);
    apiInitialPaymentSuccess().then((res) => {
      setIsLoading(false);
      if (res["msg"] === "failed") {
        setPaymentFailed(true);
      } else {
        setPaymentFailed(false);
      }
    });
    setCardUsed(false);
  };

  const clickCancelCardModal = async () => {
    navigate("/setup-payment");
  };

  // if (isLoading === true) {
  //   return <Loading />;
  // }
  return (
    <BasicLayout>
      <div className="flex flex-col gap-2">
        <StepOrProgress step={4} />
        {isLoading ? (
          <div>
            <PaymentLoading />
          </div>
        ) : (
          <></>
        )}
        <div className="flex w-full justify-center place-items-center h-full">
          {paymentFailed === false ? (
            <div className="flex flex-col gap-2 items-center mt-20">
              <h2 className="mb-10 ">Registration Success!</h2>
              <PrimaryButton
                onClick={() => navigate("/dashboard")}
                label="Start Using Physis"
              />
            </div>
          ) : (
            <></>
          )}
          {cardUsed === true && paymentFailed === null ? (
            <div className="flex flex-col gap-2 items-center mt-20">
              <h2 className="mb-2 text-center">Card used before</h2>
              <h5 className="mb-10 text-center">
                You will not get a trial and will pay immediately for Physis
              </h5>
              <div className="flex flex-col sm:flex-row justify-center gap-2 my-4">
                <PrimaryButton onClick={clickConfirmCardModal} label="Confirm" />
                <CancelButton onClick={clickCancelCardModal} label="Go Back" />
              </div>
            </div>
          ) : (
            <></>
          )}
          {paymentFailed === true ? (
            <div className="flex flex-col gap-2 items-center mt-20">
              <h2 className="mb-10 text-center">
                Payment processing failed, please try again!
              </h2>
              <PrimaryButton
                onClick={() => navigate("/setup-payment")}
                label="OK"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </BasicLayout>
  );
};

export default RegisterSuccess;
