export function getIndexFemale(goal){
  const female_age = [0,3,6,9,12,15,18,24,30,36,42,50,60,69,82,94,106,120,132,144,156,162,168,180,192,204,216];
  let output = female_age.reduce((prev, curr) => Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
  return female_age.findIndex(e => e === output)
  
}

export function getIndexMale(goal){
  const male_age = [0,3,6,9,12,15,18,24,32,36,42,48,54,60,72,84,96,108,120,132,138,150,156,162,168,180,186,192,204,216,228];  
  let output1 = male_age.reduce((prev, curr) => Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
  return  male_age.findIndex(e => e === output1)
}
