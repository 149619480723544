import BasicLayout from "../components/Layout/BasicLayout";
import PaymentHistory from "../components/Payment/PaymentHistory";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { apiGetUserPayData } from "../api/payment";
import PaymentMethodDisplay from "../components/Payment/PaymentMethodDisplay";
import ChangePlan from "../components/Payment/ChangePlan";
import Loading from "../components/utils/Loading";
import ReactGA from "react-ga";

const renderPaymentComponent = (tab, userPayData, setIsLoading) => {
  if (!userPayData) {
    return;
  }

  switch (tab) {
    case 0:
      return (
        <ChangePlan
          currentSchedule={userPayData.currentPlan.schedule}
          planDetails={userPayData.planDetails}
          currentPlanID={userPayData.currentPlan.planID.toString()}
          currentPlanGrade={userPayData.currentPlan.planGrade}
          maxGrade={userPayData.maxGrade}
          futurePlan={userPayData.futurePlan}
          setLoading={setIsLoading}
          inTrial={userPayData.inTrial}
          unsubDate={userPayData.unsubDate}
        />
      );
    case 1:
      return (
        <PaymentMethodDisplay
          paymentMethod={userPayData.currentPaymentMethod}
        />
      );
    case 2:
      return (
        <PaymentHistory
          paymentHistory={userPayData.paymentHistory}
          upcomingDetails={userPayData.upcomingDetails}
          currentPlanID={userPayData.currentPlan.planID.toString()}
          unsubDate={userPayData.unsubDate}
          isPayToGo={userPayData.maxGrade === userPayData.currentPlan.planGrade}
        />
      );
    default:
      return;
  }
};

const Payment = () => {
  let navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [userPayData, setUserPayData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const styles = {
    selected:
      "bg-gray-700 hover:bg-gray-700 h-11 text-white py-2 px-4 border border-gray-600",
    notSelected:
      "bg-black hover:bg-gray-700 h-11 text-white py-2 px-4 border border-gray-600",
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    apiGetUserPayData().then((res) => {
      if (res.msg === "failed") {
        navigate("/setup-payment");
      }
      setUserPayData(res);
      setIsLoading(false);
    });
  }, [navigate]);

  if (isLoading === true) {
    return <Loading />;
  }

  return (
    <BasicLayout navbar={true}>
      <div className="m-10">
        <div className="flex flex-col sm:w-[70%] sm:mx-auto md:w-full md:grid md:grid-cols-3 lg:w-[80%]">
          <button
            className={tab === 0 ? styles.selected : styles.notSelected}
            onClick={() => setTab(0)}
          >
            Change Plan
          </button>
          <button
            className={tab === 1 ? styles.selected : styles.notSelected}
            onClick={() => setTab(1)}
          >
            Current Payment Method
          </button>
          <button
            className={tab === 2 ? styles.selected : styles.notSelected}
            onClick={() => setTab(2)}
          >
            Payment History
          </button>
        </div>
      </div>
      {renderPaymentComponent(tab, userPayData, setIsLoading)}
    </BasicLayout>
  );
};

export default Payment;
