import client from './axios';

export const apiUpgradePlan = async (planType) => {
  try {
    const res = await client.get(`/change-plan/upgrade-plan?new_price=${planType}`);
  return res.data;
} catch (err) {
  return { msg: 'failed' };
}
};

export const apiDowngradePlan = async (planType) => {
  try {
    const res = await client.get(`/change-plan/downgrade-plan?new_price=${planType}`);
    return res.data;
  } catch (err) {
    return { msg: 'failed' };
  }
};

export const apiFromPayAsYouGo = async (planType) => {
  try {
    const res = await client.get(`/change-plan/from-pay-as-you-go-to-plan?new_price=${planType}`);
    return res.data;
  } catch (err) {
    return { msg: 'failed' };
  }
};

export const apiToPayAsYouGo = async (planType) => {
  try {
    const res = await client.get(`/change-plan/from-plan-to-pay-as-you-go?new_price=${planType}`);
    return res.data;
  } catch (err) {
    return { msg: 'failed' };
  }
};

export const apiChangePlanInTrial = async (planType) => {
  try {
    const res = await client.get(`/change-plan/in-trial?new_price=${planType}`);
    return res.data;
  } catch (err) {
    return { msg: 'failed' };
  }
};

// export const apiUnsubscribe = async () => {
//   try {
//     const res = await client.get('/change-plan/unsubscribe');
//   return res.data;
// } catch (err) {
//   return { msg: 'failed' };
// }
// };

export const apiSetUnsubscribe = async () => {
  try {
    const res = await client.get('/change-plan/set-unsubscribe-date')
    return res.data;
  } catch (err) {
    return {msg: 'failed'}
  }
}

export const apiSetUnsubFeedback = async (feedback) => {
  try {
    const res = await client.post('/change-plan/send-unsubcribe-feedback-email', feedback);
    return res.data;
  } catch (err) {
    return {msg: 'failed'};
  }
}
