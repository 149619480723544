import { PrimaryButton } from "../utils/DefaultButtons";
import TableKeyValue from "../Table/TableKeyValue";

const DisplayItem = (props) => {
  const { header, info } = props;
  return (
    // <div className="flex flex-row gap-1">
    <div className="flex gap-1 justify-center">
      <h3 className="text-gray-500">{header + ": "}</h3>
      <h3 className="font-light">{info}</h3>
    </div>
  );
};

const DisplayUser = (props) => {
  const { userData, setEditMode } = props;

  const rows = [
    {
      key: "First Name",
      value: userData.firstName,
    },
    {
      key: "Last Name",
      value: userData.lastName,
    },
    {
      key: "Email",
      value: userData.email,
    },
    {
      key: "Phone Number",
      value: userData.phoneNum,
    },
    {
      key: "Institution",
      value: userData.institution,
    },
    {
      key: "Country",
      value: userData.country,
    },
    {
      key: "Speciality",
      value: userData.speciality,
    },
  ];

  return (
    <div className="p-3">
      <div className="text-center">
        <h1>Profile</h1>
      </div>
      <TableKeyValue rows={rows} />
      <div className="my-6 flex justify-center">
        <PrimaryButton label="Edit" onClick={() => setEditMode(true)} />
      </div>
    </div>
  );
};

export default DisplayUser;
