import { AreaChart, Area, Label, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine } from 'recharts';

export default function NormalDistGraph(props) {
  const { resultData, graphData } = props;

  const xarray = (chronologic_age_months, std_dev) => {
    return [
      ((chronologic_age_months - 4 * std_dev) / 12).toFixed(1),
      ((chronologic_age_months - 2 * std_dev) / 12).toFixed(1),
      (chronologic_age_months / 12).toFixed(1),
      ((chronologic_age_months + 2 * std_dev) / 12).toFixed(1),
      ((chronologic_age_months + 4 * std_dev) / 12).toFixed(1),
    ];
  };
//  console.log("MIN", (resultData.mean - Math.abs(resultData.z_score * resultData.std_dev))/12 , (resultData.mean - 4 * resultData.std_dev) / 12);
//  console.log("MAX", (resultData.mean + Math.abs(resultData.z_score * resultData.std_dev))/12, (resultData.mean + 4 * resultData.std_dev) / 12);
return (
    <AreaChart width={500} height={150} data={graphData} margin={{ top: 0, right: 0, left: 0, bottom: 25 }}>
      <CartesianGrid horizontal={false} strokeDasharray="3 3" />
      <XAxis
        allowDecimals={true}
        minTickGap="0"
        allowDuplicatedCategory={true}
        dataKey="name"
        type="number"
        ticks={xarray(resultData.chronologic_age_months, resultData.std_dev)}
//        domain={[(resultData.mean - 4 * resultData.std_dev) / 12, (resultData.mean + 4 * resultData.std_dev) / 12]}
        domain={[Math.min((resultData.chronologic_age_months - Math.abs(resultData.z_score * resultData.std_dev))/12 - 3/12, (resultData.chronologic_age_months - 4 * resultData.std_dev) / 12), Math.max((resultData.chronologic_age_months + Math.abs(resultData.z_score * resultData.std_dev))/12 + 3/12, (resultData.chronologic_age_months + 4 * resultData.std_dev) / 12)]}
      >
        <Label value="Bone Age (years)" position="bottom" style={{ fill: 'white', textAnchor: 'middle' }} />
      </XAxis>
      <YAxis type="number" ticks={[0,1]} >
        <Label angle={-90} value="Proportion" position="insideLeft" style={{ fill: 'white', textAnchor: 'middle'}} dx={15} />
      </YAxis>
      <Tooltip />
      <ReferenceLine
        stroke="yellow"
        strokeWidth={3}
        label={{ position: 'right', value: 'patient', angle: '90', fill: '#E69F00', fontSize: 14 }}
        segment={[
          {
            x: (resultData.bone_age_months/12).toFixed(2),
            y: 0,
          },
          {
            x: (resultData.bone_age_months/12).toFixed(2),
            y: 1,
          },
        ]}
      />
      <ReferenceLine
        stroke="indigo"
        label={{ position: 'right', angle: '90', value: '-2 SD', fill: '#3e68ff', fontSize: 14 }}
        segment={[
          { x: (resultData.chronologic_age_months / 12 - 2 * (resultData.std_dev / 12)).toFixed(2), y: 0 },
          { x: (resultData.chronologic_age_months / 12 - 2 * (resultData.std_dev / 12)).toFixed(2), y: 1 },
        ]}
      />
      <ReferenceLine
        stroke="indigo"
        label={{ position: 'right', angle: '90', value: '+2 SD', fill: '#3e68ff', fontSize: 14 }}
        segment={[
          { x: resultData.chronologic_age_months / 12 + 2 * (resultData.std_dev / 12).toFixed(2), y: 0 },
          { x: resultData.chronologic_age_months / 12 + 2 * (resultData.std_dev / 12).toFixed(2), y: 1 },
        ]}
      />
      <Area type="monotone" dataKey="pv" stroke="#D3D3D3" fill={resultData.sex === true ? '#88CCEE' : '#CC6677'} /> {/* #c1e1ec '#FFB6C1'*/}
    </AreaChart>
  );
}
