import React from "react";
import BasicLayout from "../components/Layout/BasicLayout";
import ResultFunc from "../components/Assessment/Result";
import { useEffect } from "react";
import ReactGA from "react-ga";

export default function Result() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BasicLayout navbar={true}>
      <ResultFunc />
    </BasicLayout>
  );
}
