import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { trackIncrementalProperty } from "../../api/mixpanel";
import { getToken } from "../../api/auth";
import { Link, useNavigate } from "react-router-dom";
import { Base64EncodeUrl } from "../../api/urlEncoding";
import { PromptModalLayout } from "../utils/ModalLayout";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState, createContext, useContext } from "react";
import { apiDashboardDeleteInf } from "../../api/dashboard";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const ModalContext = createContext(null);

const handleDeleteInference = async (inferenceId, navigate) => {
  const email = getToken().sub;
  trackIncrementalProperty(email, "Deleted Assessments");
  await apiDashboardDeleteInf(inferenceId);
  navigate(0);
};

const handleViewAssessment = () => {
  const email = getToken().sub;
  trackIncrementalProperty(email, "Viewed Assessments");
};

const ViewAssessment = ({ params }) => {
  const link = "/result/?id=" + Base64EncodeUrl(params.row.inference_id);
  return (
    <Link to={link}>
      <Tooltip title="View">
        <VisibilityIcon onClick={handleViewAssessment} />
      </Tooltip>
    </Link>
  );
};

const DeleteAssessment = ({ params }) => {
  const { setDeleteConfirmModal } = useContext(ModalContext);
  return (
    <Tooltip title="Delete Assessment">
      <DeleteForeverIcon
        onClick={() => setDeleteConfirmModal(params.row.inference_id)}
        sx={{ cursor: "pointer" }}
      />
    </Tooltip>
  );
};

const columns = [
  {
    field: "id",
    headerName: "ID",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "ID",
    flex: 1,
    minWidth: 60,
    type: "number",
  },
  {
    field: "dateAnalysis",
    headerName: "Date of Analysis",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "Date of Analysis (YYYY-MM-DD)",
    flex: 1,
    type: "string",
    minWidth: 130,
  },
  {
    field: "chronologicalAge",
    headerName: "Chronological Age",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "Chronological age in months",
    flex: 1,
    sortable: false,
    type: "string",
    minWidth: 150,
  },
  {
    field: "sex",
    headerName: "Sex",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "Sex of the x-ray",
    flex: 1,
    type: "string",
    minWidth: 60,
  },
  {
    field: "physisBoneAgeMonths",
    headerName: "Physis Bone Age",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "Bone age as predicted by physis",
    flex: 1,
    type: "string",
    sortable: false,
    minWidth: 160,
  },
  {
    field: "feedbackAgeMonths",
    headerName: "User Bone Age",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "Bone age as provided by user",
    flex: 1,
    sortable: false,
    type: "string",
    minWidth: 120,
  },
  {
    field: "notes",
    headerName: "Notes",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "User notes",
    flex: 1,
    sortable: false,
    type: "string",
    minWidth: 120,
  },
  {
    field: "view",
    headerName: "View",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    flex: 1,
    minWidth: 60,
    sortable: false,
    filterable: false,
    renderCell: (params) => <ViewAssessment params={params} />,
  },
  {
    field: "delete",
    headerName: "Delete",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    flex: 1,
    minWidth: 60,
    sortable: false,
    filterable: false,
    renderCell: (params) => <DeleteAssessment params={params} />,
  },
];

const rowProcessing = (rows) => {
  let processedRows = [];
  rows.map((row, _index) => {
    let usefulAttributes = {};
    usefulAttributes.id = _index + 1;
    usefulAttributes.dateAnalysis = row.inference_date;
    usefulAttributes.chronologicalAge = row.chronologic_age_months;
    usefulAttributes.sex = row.sex === false ? "F" : "M";
    usefulAttributes.physisBoneAgeMonths = row.bone_age_months;

    if (row.agreement === null) {
      usefulAttributes.feedbackAgeMonths = "No Response";
    } else {
      usefulAttributes.feedbackAgeMonths = row.agreement
        ? "Same as Physis"
        : row.feedback_age_months;
    }
    usefulAttributes.notes = row.notes === "null" ? "No notes" : row.notes;
    usefulAttributes.inference_id = row.inference_id;

    processedRows.push(usefulAttributes);
  });
  return processedRows;
};

const CustomNoRowsOverlay = () => (
  <div className="h-full flex justify-center items-center">
    <p>No inferences found</p>
  </div>
);

const Table = ({ rows }) => {
  const processedRows = rowProcessing(rows);
  return (
    <DataGrid
      sx={{
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
          py: "8px",
        },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "15px",
        },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "22px",
        },
      }}
      rows={processedRows}
      getRowHeight={() => "auto"}
      columns={columns}
      autoHeight={true}
      //   disableColumnFilter
      //   disableColumnSelector
      disableDensitySelector
      slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true },
        },
      }}
      pageSizeOptions={[10, 20, 50, 100, 500]}
      getRowSpacing={(params) => ({
        top: params.isFirstVisible ? 0 : 2,
        bottom: params.isLastVisible ? 0 : 2,
      })}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
    />
  );
};

const TableDashboard = (props) => {
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(null);
  const navigate = useNavigate();

  return (
    <div className="my-8">
      <ModalContext.Provider
        value={{ deleteConfirmModal, setDeleteConfirmModal }}
      >
        <ThemeProvider theme={theme}>
          <Table rows={props.rows} />
        </ThemeProvider>
      </ModalContext.Provider>
      {deleteConfirmModal && (
        <PromptModalLayout
          title="Confirm Deletion"
          text="Are you sure you want to delete this assessment? This action is irreversible."
          onActionFn={() => handleDeleteInference(deleteConfirmModal, navigate)}
          actionTitle="Confirm"
          onCancelFn={() => setDeleteConfirmModal(null)}
          cancelTitle="Cancel"
        />
      )}
    </div>
  );
};

export default TableDashboard;
