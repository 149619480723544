import PaymentTable from "../Table/TablePayment";

const PaymentHistoryTable = ({ paymentHistory }) => {
  if (paymentHistory.length > 0) {
    const rows = paymentHistory.map((history, _ind) => {
      let stringToDate = new Date(history.created);
      let date_id = stringToDate.toISOString().split("T")[0];
      return {
        id: _ind + 1,
        date: date_id,
        cardBrand: history.cardBrand,
        amount: history.amount / 100,
        card: `**** **** **** ${history.last4}`,
        receipt: history.receiptURL
      };
    });
    return <PaymentTable rows={rows} />;
  }
  return <h3 className="text-center">No Payment History Found</h3>;
};

const PaymentHistory = (props) => {
  const { paymentHistory, upcomingDetails, unsubDate, isPayToGo } = props;

  return (
    <>
      {upcomingDetails !== null && unsubDate === null && isPayToGo == false ? (
        <p className="text-center my-4">
          You will be charged ${(upcomingDetails.amount / 100).toFixed(2)} on{" "}
          {upcomingDetails.payDate}
        </p>
      ) : (
        <></>
      )}
      <PaymentHistoryTable paymentHistory={paymentHistory} />
    </>
  );
};

export default PaymentHistory;
