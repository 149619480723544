const PaymentLoading = () => {
  return (
    <div className="my-10">
      {/* <div className="flex flex-col mt-10 h-full justify-center"> Use this if you want to center it*/}
      <p className="my-10 text-center">
        Please wait... your payment is being processed.
        <br />
        Do not refresh or close the browser.
      </p>
      <div className="flex justify-center">
        <div className="my-auto animate-spin rounded-full h-32 w-32 border-b-8 border-primary z-2"></div>
      </div>
    </div>
  );
};

export default PaymentLoading;
