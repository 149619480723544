import { Formik, Form } from "formik";
import { FormikInput, FormikSelect } from "../utils/FormikFields";
import { PrimaryButton, CancelButton } from "../utils/DefaultButtons";
import { apiUpdateUser } from "../../api/user";
import { useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { trackUserDetailsUpdate } from "../../api/mixpanel";
import { apiUpdateSubscriber } from "../../api/mailchimp";
import * as Yup from "yup";

const handleSubmit = async (
  values,
  setUserData,
  setEditMode,
  setCountryErr
) => {
  if (values.Country === "") {
    setCountryErr("Required");
  } else {
    setCountryErr("");
    apiUpdateUser(values).then(() => {
      setUserData(values);
      setEditMode(false);
    });
    trackUserDetailsUpdate(values);
    apiUpdateSubscriber();
  }
};

export default function EditForm(props) {
  const { userData, setUserData, setEditMode } = props;
  const [countryErr, setCountryErr] = useState("");
  const [nonClinicalModal, setNonClinicalModal] = useState(false);
  const [, setAgreementClicked] = useState(false);
  const initialValues = { ...userData };

  const checkCountry = (e) => {
    if ((e.target.value !== "Canada") & (e.target.value !== "")) {
      setNonClinicalModal(true);
    } else {
      setAgreementClicked(false);
    }
  };

  // https://stackoverflow.com/questions/52483260/validate-phone-number-with-yup
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const registerValidation = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phoneNum: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    institution: Yup.string().required("Required"),
    // country: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
    // whyPhysis: Yup.string(),
    speciality: Yup.string().required("Required"),
  });

  const styles = {
    disabledOptions: "hidden hover:none focus:none active:none",
  };

  return (
    <div className="w-[90%] my-4 md:w-3/5 lg:w-2/5 mx-auto">
      {nonClinicalModal && (
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 p-3 max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    I attest that I am not using Physis<sup>TM</sup> for
                    clinical purposes, and I acknowledge that it does not yet
                    have regulatory approval in my jurisdiction.
                  </p>
                </div>
                <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-primary hover:bg-blue-700 max-w-sm h-11 text-white py-2 px-4 rounded border border-primary focus:outline-none focus:shadow-outline ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setNonClinicalModal(false)}
                  >
                    I Understand
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-row justify-center mb-8">
        <h2>Edit Profile</h2>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={registerValidation}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(val) =>
          handleSubmit(
            val,
            setUserData,
            setEditMode,
            setCountryErr,
            setNonClinicalModal
          )
        }
      >
        {({ values, handleChange, handleBlur }) => (
          <Form className="grid grid-cols-1 gap-4 content-evenly">
            <div className="grid grid-cols-2 gap-4">
              <FormikInput
                label="First Name"
                name="firstName"
                type="text"
                placeholder="First Name"
              />
              <FormikInput
                label="Last Name"
                name="lastName"
                type="text"
                placeholder="Last name"
              />
            </div>

            <FormikInput
              label="Institution"
              name="institution"
              type="text"
              placeholder="Your Institution"
            />

            <FormikInput
              label="Phone Number"
              name="phoneNum"
              type="tel"
              placeholder="Phone Number"
            />

            <p className="mt-4 text-white-500">Institution Country</p>

            <CountryDropdown
              id="CountryDropdown1"
              className="p-3 placeholder:text-gray-400 border border-gray-500 bg-gray-700 text-gray-200 focus:border-gray-600"
              label="Country"
              name="country"
              value={values.country}
              onChange={(_, e) => {
                handleChange(e);
                checkCountry(e);
              }}
              onBlur={handleBlur}
            />

            {countryErr && <div className="text-red-400">{countryErr}</div>}

            <FormikSelect label="Specialty" name="speciality">
              <option className={styles.disabledOptions} value="" disabled>
                Select a specialty
              </option>
              <option value="Radiology (non-pediatric)">
                Radiology (non-pediatric)
              </option>
              <option value="Radiology (pediatric)">
                Radiology (pediatric)
              </option>
              <option value="Family Medicine">Family Medicine</option>
              <option value="Endocrinology">Endocrinology</option>
              <option value="Orthopedics">Orthopedics</option>
              <option value="other">Other</option>
            </FormikSelect>
            <div className="flex flex-row justify-self-end gap-2">
              <CancelButton label="Cancel" onClick={() => setEditMode(false)} />
              <PrimaryButton isSubmit={true} label="Save" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
