import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY
);
const StripeWrapper = (props) => {
  const { clientSecret } = props;

  const appearance = { theme: 'night' };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          {props.children}
        </Elements>
      )}
    </div>
  );
};

export default StripeWrapper;
