import PrivacyContent from "../components/StaticInfo/PrivacyContent";
import TableOfContent from "../components/StaticInfo/TableOfContents";
import GoTop from "../components/StaticInfo/GoTop";
import BasicLayout from "../components/Layout/BasicLayout";
import { useEffect } from "react";
import ReactGA from "react-ga";

const Privacy = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BasicLayout noFooter={true}>
      <div className="w-full sm:w-10/12 mx-auto p-3">
        <GoTop />
        <p className="text-4xl text-center p-2 m-4">Privacy Policy</p>
        <div className="block md:grid md:grid-cols-6 md:gap-x-20">
          <PrivacyContent />
          <TableOfContent />
        </div>
      </div>
    </BasicLayout>
  );
};
export default Privacy;
