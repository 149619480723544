import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

const PaymentMethod = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const { clientSecret, returnUrl } = props;

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    // let clientSecret = searchParams.get('stripeClient');

    if (!clientSecret) {
      return;
    }

    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      switch (setupIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe, clientSecret]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isStripeNotUp = !stripe || !elements;
    if (isStripeNotUp) return;

    setIsLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_RETURN_URL + `/${returnUrl}`,
      },
    });
    setIsLoading(false);
    //console.log(error);

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occured.');
    }

    setIsLoading(false);
  };

  return (
    <div>
      <div className="m-2 p-2">
        <form id="payment-form" className="border border-gray-700 rounded p-2" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" />
          <button
            disabled={isLoading || !stripe || !elements}
            className="bg-blue-600 px-2 h-10 rounded-md shadow my-4 hover:bg-blue-800"
            id="submit"
          >
            <span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : 'Submit'}</span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
      </div>
    </div>
  );
};

export default PaymentMethod;
