import { useState, useEffect } from "react";
import { apiGetPlanDetails } from "../../api/payment";
// import PlanCard from './ChangePlanCard';
import RegisterPlanCard from "./RegisterPlanCard";
import { apiSubscribe } from "../../api/payment";

const RegisterPlans = (props) => {
  const { planValue, setPlanType, schedule, setSchedule } = props;
  const { step, setStep, setClientSecret } = props;

  const [planDetails, setPlanDetails] = useState([]);

  const styles = {
    selected:
      "bg-blue-600 hover:bg-blue-700 h-11 text-white py-2 px-4 border border-blue-600",
    notSelected:
      "bg-black hover:bg-gray-700 h-11 text-blue-600 py-2 px-4 border border-blue-600",
  };

  //console.log(planDetails);
  /* 
plan_description: (4) ['Access to the winning RSNA machine learning model', '5 bone age predictions per month', 'Graphical plots based on the Brush Foundation population', 'Dashboard and analytics of all your cases']
plan_grade: 1
plan_name: "Basic"
plan_price: {monthly: 9.99, yearly: 95.9}
scan_limit: 5
stripe_pricing_id: {monthly: 'price_1KqHPWEI3ln3HtoV4sk8V7IU', yearly: 'price_1KqHQcEI3ln3HtoVaFvC5gVL'}
trial_info: {duration_in_days: 30, scan_limit: 5}

  */

  useEffect(() => {
    // Updating plan details from DB
    apiGetPlanDetails().then((res) => setPlanDetails(res));
  }, []);

  const handleChangePlan = async (selectedPlanDetails) => {
    const selectedPlanID = selectedPlanDetails.stripe_pricing_id[schedule];
    const isSelected = selectedPlanID === planValue;
    if (isSelected === true) {
      // setPlanType(''); Unselect plan
      setStep(step + 1); // Go forward
    } else {
      setPlanType(selectedPlanID);
      const res = await apiSubscribe(selectedPlanID);
      setClientSecret(res.clientSecret);
    }
  };

  return (
    <>
      <div className="flex flex-row my-4 justify-center">
        <button
          className={
            schedule === "monthly" ? styles.selected : styles.notSelected
          }
          onClick={() => setSchedule("monthly")}
        >
          Monthly
        </button>
        <button
          className={
            schedule === "yearly" ? styles.selected : styles.notSelected
          }
          onClick={() => setSchedule("yearly")}
        >
          Yearly (save 10%)
        </button>
      </div>
      <div className="mt-2">
        <div className="flex flex-col gap-2 w-[95%] mx-auto md:grid md:md-layout-grid lg:w-layout-grid">
          {/* <div className=""> */}
          {planDetails.map((_details, _ind) => {
            return (
              <RegisterPlanCard
                planDetails={_details}
                currentPlanValue={planValue}
                onPlanChange={() => handleChangePlan(_details)}
                schedule={schedule}
                key={_ind}
              />
            );
          })}
        </div>
      </div>
      <p className="my-4 text-gray-500 text-center">
        All prices in USD. You have the freedom to change your plan or cancel
        online at any time.
      </p>
    </>
  );
};

export default RegisterPlans;
