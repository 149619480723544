export const DropZoneTextDiv = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex mb-4">
        <div className="m-auto">
          <img
            className="object-scale-down h-12 w-24 blue-300"
            src="/images/upload.png"
            alt="uploadImg"
          />
        </div>
      </div>
      <p>
        ✓ Drop de-identified JPEG or PNG of a single AP hand (left or right)
        x-ray here, or click to find one on your device.
      </p>
      <p>
        ✓ The window/level setting of the image must be set to normal diagnostic
        levels and free of artifacts.
      </p>
      <p>✓ Orient image with fingers pointing upwards.</p>
      <p>
        ✓ Images captured on a mobile device are not supported as they can lead
        to unexpected changes in the image orientation when provided as input to
        the algorithm.
      </p>
    </div>
  );
};

export default DropZoneTextDiv;
