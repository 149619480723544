import { useField } from 'formik';

export const FormikInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="flex flex-col gap-2">
      <label className="mt-2" htmlFor={props.id || props.name}>
        {label}
      </label>
      <input {...field} {...props} />
      {meta.touched && meta.error ? <div className="text-red-400">{meta.error}</div> : null}
    </div>
  );
};

export const FormikSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const selectStyle =
    'p-3 placeholder:text-gray-400 border border-gray-500 bg-gray-700 text-gray-200 focus:border-gray-600';
  return (
    <div className="mt-4">
      <label className="mr-5" htmlFor={props.id || props.name}>
        {label}
      </label>
      <select className={selectStyle} title={props.name} {...field} {...props} />
      {meta.touched && meta.error ? <div className="text-red-400">{meta.error}</div> : null}
    </div>
  );
};
