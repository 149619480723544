export const format_age = (months) => {
  // round the months to the nearest int
  months = Math.round(months);

  let string = '';
  let age_years = Math.floor(months / 12);
  let age_months = months % 12;

  if (age_years === 1) {
    string = age_years.toString() + ' year ';
  } else if (age_years > 1) {
    string = age_years.toString() + ' years ';
  }

  if (age_months === 1) {
    string += age_months.toString() + ' month';
  } else if (age_months > 1) {
    string += age_months.toString() + ' months';
  } else {
    string += '0 months';
  }
  return string;
};

export const normalY = (x, mean, stdDev) => Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));
