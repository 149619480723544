import ProgressBar from "./ProgressBar";
import StepBar from "./StepBar";

const StepOrProgress = (props) => {
  let precentage = "0%";
  let title = "";
  switch (props.step) {
    case 0:
      precentage = "25%";
      title = "Create Account";
      break;
    case 1:
      precentage = "50%";
      title = "Choose Plan";
      break;
    case 2:
      precentage = "75%";
      title = "Enter Payment Information";
      break;
    case 3:
      precentage = "100%";
      title = "Start With Physis";
      break;
    case 4:
      precentage = "100%";
      title = "Start With Physis";
      break;

    default:
      break;
  }
  return (
    <div>
      <div className="block my-6 md:hidden">
        <ProgressBar title={title} percentage={precentage} />
      </div>
      <div className="hidden my-20 md:block">
        <StepBar step={props.step} />
      </div>
    </div>
  );
};

export default StepOrProgress;
