import client from './axios';
import { getToken } from './auth';
import { apiGetProfileData } from './user';

export const apiPredict = async (file, sex, dob, dos, notes, indication, isPerUseInference, 
  imgRotation, crop) => {
  try {
    // populate a new form with username/password
    const cropStr = JSON.stringify(crop);
    let formData = new FormData();
    formData.set('file', file);
    formData.set('sex', sex);
    formData.set('dob', dob);
    formData.set('dos', dos);
    formData.set('notes', notes);
    formData.set('indication', indication);
    formData.set('isperuseinference', isPerUseInference);
    formData.set('imgRotation', imgRotation);
    formData.set('crop', cropStr);

    let user_email = getToken()['sub'];
    let userid = await apiGetProfileData(user_email);

    formData.set('userid', userid['user_id']);

    let prediction = await client.post('/predict/single2', formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return prediction.data;
  } catch (err) {
    return err.response.data.detail;
  }
};

export const apiGetInference = async (inference_id) => {
  try {
    // populate a new form with username/password
    let data = {
      inference_id: inference_id,
    };

    let inference = await client.post('/predict/get-inference-info', data);
    return inference.data;
  } catch (err) {
    return err.response.data.detail;
  }
};

export const apiGetUserAccessInfo = async () => {
  try {
    let result = await client.get('/user/get-access-info');
    return result.data;
  } catch (err) {
    console.log(err);
    return err.response.data.detail;
  }
};

export const getAllInferenceIds = async () => {
  try {
    // populate a new form with username/password

    let inferences = await client.get('/predict/get-all-inferences');
    return inferences.data;
  } catch (err) {
    return err.response.data.detail;
  }
};

export const apiUpdateInference = async (data, inference_id) => {
  try {
    data['inference_id'] = inference_id;
    let update = await client.post('/predict/update-inference', data);
    return update;
  } catch (err) {
    return err.response.data.detail;
  }
};
