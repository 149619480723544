export default function ModalLayout(props) {
  return (
    <div className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none mx-2">
      <div
        className="absolute bg-black opacity-50 inset-0 z-0"
        onClick={props.onClose}
      ></div>
      <div className="w-full max-w-lg relative mx-auto my-auto shadow-xl border-2 border-gray-600 bg-gray-800 py-4 px-2 rounded-lg">
        {props.children}
      </div>
    </div>
  );
}

export function PromptModalLayout(props) {
  const { onActionFn, onCancelFn, title, text, actionTitle, cancelTitle } =
    props;

  const styles = {
    optionsBtn: "bg-gray-700 hover:bg-gray-600 text-gray-100 py-2 px-4 rounded",
  };

  return (
    <ModalLayout>
      <div className="flex justify-center text-xl text-slate-300 py-1">
        {title}
      </div>
      <hr />
      <div className="flex text-sm text-gray-400 justify-center my-2">
        {text}
      </div>
      <div className="flex flex-row gap-4 justify-center">
        <button className={styles.optionsBtn} onClick={onActionFn}>
          {actionTitle}
        </button>
        <button className={styles.optionsBtn} onClick={onCancelFn}>
          {cancelTitle}
        </button>
      </div>
    </ModalLayout>
  );
}
