import Header from "./Header";
import Footer from "./Footer";
// import Navbar from "./Navbar";
import ResponsiveAppbar from "./ResponsiveAppBar";

const BasicLayout = (props) => {
  return (
    <div className="min-h-screen basic-layout">
      {/* <div className="bg-red-100 min-h-screen basic-layout"> */}
      {/* <div className="min-h-screen flex flex-col justify-between"> */}
      {props.navbar ? <ResponsiveAppbar /> : <Header />}
      <div className="flex flex-col">{props.children}</div>
      {!props.noFooter && <Footer />}
    </div>
  );
};

export default BasicLayout;
