import React, { useState, useEffect } from "react";
import AssessmentForm from "../components/Assessment/Form";
import { apiGetScanCount } from "../api/user";
import Loading from "../components/utils/Loading";
import BasicLayout from "../components/Layout/BasicLayout";
import ReactGA from "react-ga";

export default function Assessment() {
  const [scanInfo, setScanInfo] = useState(null);

  useEffect(() => {
    apiGetScanCount().then((res) => setScanInfo(res));
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (!scanInfo) {
    return <Loading />;
  }

  return (
    <BasicLayout navbar={true}>
      <AssessmentForm
        scanRemaining={scanInfo.scanRemaining}
        perUsePrice={scanInfo.perUsePrice}
        payAsGoUser={scanInfo.payAsGoUser}
      />
    </BasicLayout>
  );
}
