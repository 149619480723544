import { format_age } from "./ResultHelperFuncs";
import { useState } from "react";
import { useEffect } from "react";
import { apiGetInference } from "../../api/prediction";
import { useSearchParams } from "react-router-dom";
import { Base64DecodeUrl } from "../../api/urlEncoding";
import { PrimaryButton } from "../utils/DefaultButtons";
import { useNavigate } from "react-router-dom";

export default function ResultReport(props) {
  const {
    resultData,
    MouseOff,
    MouseOver,
    getDivText,
    buttonText,
    reportBoneAge,
  } = props;

  const [data, setData] = useState({
    opinion: null,
    indication: null,
    within: null,
  });
  const [, setInferenceId] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const returnDashboard = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    const fetchData = async (setter) => {
      let inference_id = Base64DecodeUrl(searchParams.get("id")); // get the inference id from props
      setInferenceId(inference_id);
      let r = await apiGetInference(inference_id);
      setter(r);
      let bone_age = 0;
      let indication = "Assess bone age";
      if (
        r.indication !== null &&
        r.indication !== undefined &&
        r.indication !== "null"
      ) {
        indication = r.indication;
      }
      if (r.agreement === false) {
        bone_age = r.feedback_age_months;
      } else {
        bone_age = r.bone_age_months;
      }
      if (
        bone_age >= r.chronologic_age_months - 2 * r.std_dev &&
        bone_age <= r.chronologic_age_months + 2 * r.std_dev
      ) {
        setData({
          opinion: "Appropriate bone age",
          indication: indication,
          within: "in",
        });
      }
      if (bone_age < r.chronologic_age_months - 2 * r.std_dev) {
        setData({
          opinion: "Delayed bone age",
          indication: indication,
          within: "out",
        });
      }
      if (bone_age > r.chronologic_age_months + 2 * r.std_dev) {
        setData({
          opinion: "Advanced bone age",
          indication: indication,
          within: "out",
        });
      }
    };
    fetchData(setData);
  }, [searchParams]);

  return (
    <div className="my-4">
      <h3 className="text-2xl text-center mt-2">Report</h3>
      <div
        contentEditable="true"
        onMouseOver={MouseOver}
        onMouseLeave={MouseOff}
        suppressContentEditableWarning={true}
        id="edit_div"
        className="mt-6 border-2 rounded border-white p-2 bg-gray-800"
      >
        <h3 className="text-lg mb-4">BONE AGE STUDY{"\n\n"}</h3>
        <div>
          <h2 className="text-lg">INDICATION: {data["indication"]}</h2>
          <p className="mb-4">{"\n\n"}</p>
        </div>

        <div>
          <h2 className="text-lg">COMPARISON: None</h2>
          <p className="mb-4">{"\n\n"}</p>
        </div>

        <div>
          <h2 className="text-lg">FINDINGS:{"\n"}</h2>
          <p>
            Sex: {resultData.sex === true ? "Male" : "Female"}
            {"\n"}
          </p>
          <p className="mb-4">
            Chronological Age: {format_age(resultData.chronologic_age_months)}
            {"\n\n"}
          </p>
          <p className="mb-4">
            The standard deviation at this age is{" "}
            {parseFloat(resultData.std_dev).toFixed(2)} months, giving a normal
            range of{" "}
            {format_age(
              resultData.chronologic_age_months - 2 * resultData.std_dev
            )}{" "}
            to {""}
            {format_age(
              resultData.chronologic_age_months + 2 * resultData.std_dev
            )}{" "}
            (+/- 2 standard deviations).{"\n\n"}
          </p>

          <p className="mb-4">
            By the method of Greulich and Pyle, the bone age is estimated to be{" "}
            {reportBoneAge}, which falls {data["within"]}side the normal range.
          </p>
          <p>{"\n\n"}</p>
          <h2 className="text-lg">OPINION: {"\n"}</h2>
          <p>{data["opinion"]}.</p>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-center gap-2 my-4">
        <PrimaryButton onClick={getDivText} label={buttonText} />
        <PrimaryButton onClick={returnDashboard} label={"Back to Dashboard"} />
      </div>
    </div>
  );
}
