import mixpanel from 'mixpanel-browser';
try {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, { api_host: process.env.REACT_APP_MIXPANEL_HOST, ignore_dnt: true, debug: (process.env.REACT_APP_MIXPANEL_DEBUG_MODE === "true") });
}
catch(err) {
    console.log('Mixpanel init failed:ignored');
}
// missing events: 20 

export const trackUserDetails = async (userInfo) => {
    try {
        mixpanel.reset();
        mixpanel.alias(userInfo.email);
        mixpanel.identify(userInfo.email);
        mixpanel.people.set({
            '$name': userInfo.firstName.concat(" ", userInfo.lastName),
            '$email': userInfo.email,
            'Country': userInfo.country,
            'Institution': userInfo.institution,
            'User Type': userInfo.specialty,
            'Plan': '',
            'Account Status': '',
            'Trial Status': '',
            'Assessments': 0,
            'Viewed Assessments': 0,
            'Deleted Assessments': 0,
            'Viewed Results': 0,
            'Viewed Atlas': 0,
            'Customized Report': 0,
            'Right Arrow Atlas': 0,
            'Left Arrow Atlas': 0,
            'Hint Toggles': 0,
            'Analysis Agreement': 0,
            'Copied to Clipboard': 0,
            'Payment Details Form': 0,
        })
        mixpanel.track('Attestation', {
            'Accepted': true
        });
    }
    catch(err){
        console.log('Mixpanel error in trackuserdetails : ignored')
    }
}

// this is for events 17, 18, 19, 21, 22, 23, 24, 25, 26, 27, 28, 30
export const trackIncrementalProperty = (email, property) => {
    try {
        mixpanel.identify(email)
        mixpanel.people.increment(property);
        mixpanel.track(property, {
            property: true
        });
    }
    catch(err){
        console.log('Mixpanel error in trackincrementalprop : ignored')
    }
}

export const trackAssessmentForm = () => {
    try {
        mixpanel.track('Assessment Form', {
            'Assessment Form': true
        });
    }
    catch(err){
        console.log('Mixpanel error in trackassessmentform : ignored')
    }
}

// move to backend
export const trackUserDetailsUpdate = (userInfo) => {
    try {
        mixpanel.people.set({
            '$name': userInfo.firstName.concat(" ", userInfo.lastName),
            '$email': userInfo.email,
            'Country': userInfo.country,
            'Institution': userInfo.institution,
            'User Type': userInfo.specialty,
        })
    }
    catch(err){
        console.log('Mixpanel error in trackuserdetailsupdate : ignored')
    }

}

export const trackLogout = async () => {
    try {
        mixpanel.reset();
    }
    catch(err){
        console.log('Mixpanel error in tracklogout : ignored')
    }

}