export const maleAtlasData = {
  0: {
    filepath: '/atlas/male_000.png',
    caption: 'Male Newborn',
    hints:
      'The degree of flaring of the distal ends of the radius and ulna is that usually seen in ' +
      'hand-films of full-term infants.\n\n' +
      'The shafts of the second to fifth metacarpals are slightly constricted in their middle ' +
      'portions. If the hand has been positioned properly with the palm and fingers flat ' +
      'on the film holder, the proximal ends of the metacarpals are somewhat closer ' +
      'together than their distal ends and, consequently, the shafts appear to radiate out ' +
      'from the carpal area. At birth, the metacarpals of premature infants are usually ' +
      'more parallel to each other.\n\n' +
      'The distal ends of the proximal and middle phalanges are rounded and their proximal ' +
      'ends are wider and flat.',
  },
  1: {
    filepath: '/atlas/male_003.png',
    caption: 'Male 3 months',
    hints:
      'The flaring of the distal ends of the radius and ulna is slightly more pronounced than ' +
      'it was in the preceding standard. A beaklike projection on the radial side of the ' +
      'distal end of the ulna, such as the one visible in this plate, usually persists for several ' +
      'years.\n\n' +
      'A center of ossification is now visible in both the capitate and the hamate. The capitate ' +
      'usually reaches this stage of development slightly earlier than the hamate, as is ' +
      'indicated here by its larger ossification center and somewhat rounder shape.\n\n' +
      'Both the proximal and the distal ends of the second to fifth metacarpals are relatively ' +
      'larger than they were in the preceding standard and the central portions of their ' +
      'shafts are now more constricted. The proximal ends of the second and fifth ' +
      'metacarpals tend also to be more rounded than they were at birth. The proximal or ' +
      'future epiphysial margin of the first metacarpal is now distinctly flattened and so ' +
      'resembles the corresponding portion of the proximal phalanges.\n\n' +
      'The phalanges have increased relatively more in length than in breadth and are ' +
      'beginning to show individual differentiation.',
  },
  2: {
    filepath: '/atlas/male_006.png',
    caption: 'Male 6 months',
    hints:
      'The ulnar side of the radial epiphysis is pointed and its radial (lateral) side is thicker and convex.\n\n' + 
      'Centers of ossification are now visible in the heads of the second, third, and fourth metacarpals, in the proximal ' +
      'phalanges of the same fingers, and in the distal phalanx of the thumb. Ossification in these epiphyses usually appears first ' +
      'centrally and subsequently extends transversely. These metacarpal epiphyses, especially that of the fourth metacarpal, are slightly ' +
      'advanced in their development.',
  },
  3: {
    filepath: '/atlas/male_009.png',
    caption: 'Male 9 months',
    hints:
      'The surface of the capitate adjacent to the hamate has begun to flatten.\n\n' +
      'The bases of the second, third, fourth, and fifth metacarpals have become relatively ' +
      'larger and more rounded. A similar change has taken place in the distal end of ' +
      'the first metacarpal.',
  },
  4: {
    filepath: '/atlas/male_012.png',
    caption: 'Male 1 year',
    hints:
      'The ossification centers of the capitate and hamate have grown larger and are now ' +
      'close together. Some further flattening has occurred in the hamate surface of the ' +
      'capitate.\n\n' +
      'The proximal phalanges have grown somewhat more in length than in width and the ' +
      'tips (distal ends) of the third and fourth appear to be slightly compressed laterally.',
  },
  5: {
    filepath: '/atlas/male_015.png',
    caption: 'Male 1 year 3 months',
    hints:
      'A small center of ossification is visible in the distal epiphysis of the radius.\n\n' +
      'The flattening of the hamate surface of the capitate is now more pronounced than it ' +
      'was in Standard 5 [1 year] and the adjacent surface of the hamate has become somewhat ' +
      'less convex. At this stage, the future trapezoid margin of the capitate may be ' +
      'convex, distinctly flattened, or slightly concave, as it is in this standard. If a notch ' +
      'subsequently appears at the junction of the capitate, scaphoid, and trapezoid, it ' +
      'will develop in the region where this concavity first begins. Such a notch was ' +
      'present in the capitate of about 40 per cent of the children in our study. In the ' +
      'others, this osseous feature was not seen at any stage of their development.\n\n' +
      'That portion of the base of the second metacarpal which will later articulate with the ' +
      'capitate has begun to flatten.\n\n' +
      'The sides of the distal ends of the proximal phalanges of the third and fourth fingers ' +
      'are now somewhat flattened. The trochlear surface of each phalanx will form later ' +
      'between and immediately distal to those flattened areas.',
  },
  6: {
    filepath: '/atlas/male_018.png',
    caption: 'Male 1 year 6 months',
    hints:
      'The ulnar side of the radial epiphysis is pointed and its radial (lateral) side is thicker ' +
      'and convex.\n\n' +
      'Centers of ossification are now visible in the heads of the second, third, and fourth ' +
      'metacarpals, in the proximal phalanges of the same fingers, and in the distal ' +
      'phalanx of the thumb. Ossification in these epiphyses usually appears first centrally and subsequently extends transversely. These metacarpal epiphyses, ' +
      'especially that of the fourth metacarpal, are slightly advanced in their development.',
  },

  7: {
    filepath: '/atlas/male_024.png',
    caption: 'Male 2 years',
    hints:
      'The capitate and hamate have increased further in size.\n\n' +
      'A tiny center of ossification seems to be present in the epiphysis of the fifth metacarpal, ' +
      'in the original film. It is not visible in this illustration, however.\n\n' +
      'Ossification has now begun in the epiphysis of the proximal phalanx of the fifth finger ' +
      'and in the epiphyses of the middle and distal phalanges of the third and fourth ' +
      'fingers. The epiphyses of the proximal phalanges of the second, third, fourth, and ' +
      'fifth fingers and that of the distal phalanx of the thumb are now disc-shaped and ' +
      'their margins are smooth.',
  },
  8: {
    filepath: '/atlas/male_032.png',
    caption: 'Male 2 years 8 months',
    hints:
      'The epiphysis of the radius has become wedge-shaped through the relative flattening ' +
      'of its proximal margin and the ulnar half of its distal margin.\n\n' +
      'The triquetral, one of the most variable of the carpals in the time of its beginning ' +
      'ossification, now contains a slightly advanced bony center, which has the form ' +
      'of a rounded nodule with smooth margins.\n\n' +
      'It is apparent that the ossification of the epiphysis of the first metacarpal began more ' +
      'recently than that of the fifth metacarpal, in which the process probably began ' +
      'soon after the stage of development depicted in the preceding standard.\n\n' +
      'Ossification centers are now visible in the proximal phalanx of the thumb (where, in ' +
      'the original film, it takes the form of one larger and several much smaller discrete ' +
      'nodules which are disposed transversely), in the middle phalanx of the second ' +
      'finger, the distal phalanges of the second and fifth fingers, and in the first metacarpal. The width of the epiphyses of the proximal phalanges of the second, third, ' +
      'fourth, and fifth fingers now equals or exceeds half the width of the adjacent ' +
      'margins of their shafts. The central portion of their growth cartilage plates is now ' +
      'as thin as it will become until those epiphyses begin to fuse with their shafts. The ' +
      'epiphysis of the distal phalanx of the thumb has flattened to conform to the shape ' +
      'of the adjacent surface of its shaft. Its growth cartilage has also attained its definitive thickness.',
  },

  9: {
    filepath: '/atlas/male_036.png',
    caption: 'Male 3 years',
    hints:
      'The volar and dorsal surfaces of the radial epiphysis can now be distinguished. The ' +
      'volar margin is visible as a rather thick white line. Distally, the thin dorsal margin ' +
      'of the epiphysis projects beyond the volar margin.\n\n' +
      'The concavity in the margin of the capitate adjacent to the hamate suggests the beginning of reciprocal shaping, but its depth is an individual variation and should ' +
      'not be regarded as a characteristic feature of this standard.\n\n' +
      'Ossification has begun, somewhat precociously, in the lunate.\n\n' +
      'The epiphyses of the second, third, fourth, and fifth metacarpals have enlarged and ' +
      'have become more uniformly rounded and their margins somewhat smoother.\n\n' +
      'The epiphyses of the middle phalanges of the second, third, and fourth fingers have ' +
      'widened transversely to form disc-like structures which are thickest in the middle ' +
      'and taper toward each end. Their margins are smooth.',
  },

  10: {
    filepath: '/atlas/male_042.png',
    caption: 'Male 3 years 6 months',
    hints:
      'The lunate is much advanced in its development as compared with the other bones.\n\n' +
      'The approximately transverse position of its future long axis is already indicated.\n\n' +
      'That surface of the base of the second metacarpal, which will later articulate with the ' +
      'trapezoid, has begun to flatten. The trapezoid facet makes a wide angle with the ' +
      'smaller capitate facet, which forms the remainder of the proximal border of the ' +
      'shaft.\n\n' +
      'The epiphyses of the distal phalanges of the second and fifth fingers are now clearly ' +
      'visible. The corresponding epiphyses of the third and fourth fingers are now disc-' +
      'shaped and their margins are smooth.\n\n' +
      'A series of hand-films of this boy are used as Standards 11-23 [3 years 6 months - 13 years], inclusive.',
  },

  11: {
    filepath: '/atlas/male_048.png',
    caption: 'Male 4 years',
    hints:
      'The irregular white line which is visible in the ulnar half of the radial epiphysis about ' +
      'midway between its proximal and distal margins and which extends toward its ' +
      'ulnar tip, forms with a similar line along the proximal margin of the epiphysis ' +
      'the outline of its volar surface. Similar white lines which will be seen more distinctly in this and some other bones in subsequent standards help one to distinguish the dorsal from the volar surface.\n\n' +
      'Centers of ossification are now present in all of the phalangeal epiphyses and, somewhat ' +
      'precociously, in the trapezium as well. An early onset of ossification in the ' +
      'trapezium and the occurrence of multiple rather than single centers in the proximal ' +
      'phalanx of the thumb are not uncommon.\n\n' +
      'The epiphyses of the proximal phalanges of the second and third fingers, which had ' +
      'already begun to thicken in the preceding standard, are now somewhat wedge ' +
      'shaped, tapering toward their ulnar ends.',
  },

  12: {
    filepath: '/atlas/male_054.png',
    caption: 'Male 4 years 6 months',
    hints:
      'The distal margin of the ulnar tip of the radial epiphysis, which will subsequently ' +
      'articulate with the lunate, has flattened slightly.\n\n' +
      'The ossification center of the trapezium is now a small, rounded nodule with fairly ' +
      'smooth margins. A faint white line just proximal to the capitate surface of the ' +
      'lunate indicates this portion of its volar margin. This feature becomes more distinct in subsequent standards.\n\n' +
      'The epiphyses of the second, third, and fourth metacarpals now show a comparatively ' +
      'flattened ulnar side and a more rounded distal margin. The thinnest part of the ' +
      'cartilage plate of the first metacarpal is now as thin as it will become until its ' +
      'epiphysis begins to fuse with the shaft.\n\n' +
      'The articular surfaces of the proximal phalangeal epiphyses of the second and third ' +
      'fingers have become slightly concave as they begin to shape to the heads of the ' +
      'corresponding metacarpals. The proximal phalanx of the thumb has an epiphysis ' +
      'which has not yet completely coalesced to form a single nodule. This center is, ' +
      'however, about three-fourths as wide as its metaphysis. The middle phalangeal ' +
      'epiphyses of the third and fourth fingers are now slightly more than half as wide as ' +
      'their shafts.',
  },

  13: {
    filepath: '/atlas/male_060.png',
    caption: 'Male 5 years',
    hints:
      'Both a lunate and a hamate facet can now be distinguished on the triquetral. Its free ' +
      '(non-articular) surface remains convex.\n\n' +
      'The epiphysis of the first metacarpal is now more than half as wide as its metaphysis.\n\n' +
      'The trapezoid facet on the proximal end of the second metacarpal is now slightly ' +
      'concave. In Standard I [Newborn], the metacarpals have a fan-shaped arrangement: the bases ' +
      'of the fourth and fifth metacarpals now partially overlap. The five metacarpal-' +
      'phalangeal joint spaces have become progressively smaller as the osseous epiphyses ' +
      'of metacarpal and proximal phalanges increased in size.\n\n' +
      'The partial flexion of the fingers in this standard makes it impossible to compare the ' +
      'amount of reduction which has taken place in the size of the inter-phalangeal ' +
      'spaces since the previous standard. The epiphysis of the distal phalanx of the fifth ' +
      'finger is now about two-thirds as wide as its shaft.',
  },

  14: {
    filepath: '/atlas/male_072.png',
    caption: 'Male 6 years',
    hints:
      'The part of the radial epiphysis from which the styloid process develops is beginning ' +
      'to enlarge. The distal segment of the white line which outlines a portion of the ' +
      'volar surface of the radial epiphysis now extends to the distal surface of that ' +
      'epiphysis. Ossification probably began in the ulnar epiphysis shortly after the ' +
      'stage of development depicted in Standard 14 [5 years]. It is now somewhat farther advanced than the rest of the hand.\n\n' +
      'The capitate and hamate have increased further in size and their margins now show ' +
      'beginning regional differentiation. The triquetral has become more elongated, ' +
      'its ulnar margin somewhat less convex, and its hamate and lunate margins further ' +
      'flattened. The distinct curved white line which occupies part of the distal margin ' +
      'of the lunate represents a portion of its volar surface. This feature is first seen in ' +
      'Standard 13 [4 years 6 months]. The spaces between the hamate and triquetral, capitate and lunate, ' +
      'and lunate and radial epiphysis have been further reduced. The size of the osseous ' +
      'centers of both the trapezoid and the scaphoid suggests that they would have been ' +
      'visible shortly after the stage of development shown in Standard 14 [5 years]. The surface ' +
      'of the trapezium adjacent to the epiphysis of the first metacarpal has begun to ' +
      'flatten.\n\n' +
      'The base of the second metacarpal is now distinctly indented in the region which will ' +
      'later articulate with the trapezoid.\n\n' +
      'The epiphyses of the proximal phalanges are not yet as wide as the adjacent borders ' +
      'of their shafts. The future articular surfaces of the proximal epiphyses of the fourth ' +
      'and fifth fingers are now slightly concave.',
  },
  15: {
    filepath: '/atlas/male_084.png',
    caption: 'Male 7 years',
    hints:
      'Since the stage of development depicted in Standard 15 [6 years], the radial epiphysis has ' +
      'increased in width and its future styloid portion has become thicker. The ulnar ' +
      'epiphysis has extended predominantly radialward from the site of its first appearance. Its proximal surface appears flattened.\n\n' +
      'The hamate surface of the capitate is now slightly concave, and the adjacent surface of ' +
      'the hamate has a corresponding convexity. The metacarpal margin of the hamate ' +
      'is now distinctly flattened, and a slight flattening has also occurred on the surface ' +
      'of the trapezoid adjacent to the capitate. The trapezium and trapezoid will overlap ' +
      'in all subsequent standards when the hand is properly positioned.\n\n' +
      'The proximal margin of the epiphysis of the first metacarpal is distinctly flattened.\n\n' +
      'An articular concavity will subsequently develop in the same region.\n\n' +
      'The epiphysis of the middle phalanx of the fifth finger, which is here somewhat delayed in its development, is now slightly more than half as wide as its shaft. The ' +
      'central portion of its epiphysial cartilage plate is now as thin as it will become ' +
      'until fusion begins. As seen in this oblique view, there is now a distinct flattening ' +
      'of most of the articular surface of the distal phalanx of the thumb.',
  },
  16: {
    filepath: '/atlas/male_096.png',
    caption: 'Male 8 years',
    hints:
      'The white lines adjacent to the metacarpal surface of the hamate, capitate, and trapezoid mark a part of their respective volar margins. These markings become more ' +
      'distinct and complete in subsequent standards. As the scaphoid has elongated, its ' +
      'capitate surface has become somewhat less convex.\n\n' +
      ' ' +
      'The concavity in the base of the second metacarpal adjacent to the trapezoid has become more pronounced. The ulnar portion of the base has begun to extend toward ' +
      'the capitate, with which it will later articulate. The oblique view of the first metacarpal, which the ordinary hand-film provides, permits the observation that the ' +
      'epiphysis of this bone reaches the palmar or volar margin of the shaft before it ' +
      'grows far enough dorsally to be aligned with the dorsal margin of the shaft. The ' +
      'proximal epiphysis of the thumb also appears in the oblique view to be the same ' +
      'width as its shaft.\n\n' +
      ' ' +
      'The epiphyses of the distal phalanges of the second, third, fourth, and fifth fingers are ' +
      'now as wide as their shafts. All middle phalangeal epiphyses and the epiphyses ' +
      'of the distal phalanges of the second and third fingers have shaped further to the ' +
      'contours of the trochlear surfaces of the phalanges with which they articulate.\n\n',
  },
  17: {
    filepath: '/atlas/male_108.png',
    caption: 'Male 9 years',
    hints:
      'The epiphysis of the ulna has widened and thickened to form a bony plate, the exact ' +
      'shape of which varies in different children. Its radial margin is usually thinner ' +
      'than its ulnar margin and a slight indentation is sometimes visible on its distal ' +
      'surface. The central portion of its epiphysial cartilage plate has now attained its ' +
      'definitive thickness, i.e., it is now as thin as it will become until the epiphysis ' +
      'begins to fuse with its shaft. Its styloid process is beginning to appear.\n\n' +
      ' ' +
      'The two metacarpal articular surfaces of the capitate are beginning to differentiate.\n\n' +
      'That portion of the distal margin of the hamate which will later articulate with ' +
      'the base of the fifth metacarpal can now be seen as a small but distinct projection.\n\n' +
      'A process of the trapezium is beginning to project from its distal surface toward ' +
      'the base of the second metacarpal. It will become more distinct as development ' +
      'proceeds. The spaces between the scaphoid, trapezoid, and trapezium and that ' +
      'between the scaphoid and lunate remain wide. It should be remembered that, at ' +
      'birth, the various carpal units are completely cartilaginous and in reasonably close ' +
      'apposition to each other. What is depicted in this series of films is the form of the ' +
      'osseous centers which develop within the substance of the cartilaginous carpal ' +
      'units. The spaces just referred to are a measure of the extent to which the ' +
      'replacement of the cartilage by bone is as yet incomplete.\n\n' +
      ' ' +
      'The distal ends of the shafts of the second and third proximal phalanges have become ' +
      'slightly concave as their trochlear surfaces begin to differentiate.\n\n',
  },
  18: {
    filepath: '/atlas/male_120.png',
    caption: 'Male 10 years',
    hints:
      'A part of the outline of the volar margin of the capitate surface of the scaphoid can ' +
      'now be seen as a rather heavy white line. A slight indentation has appeared in the ' +
      'distal surface of the trapezium in the area of its future articulation with the first ' +
      'metacarpal. Its scaphoid surface has begun to flatten. A similar but less marked ' +
      'flattening is visible in the surface of the trapezoid which is adjacent to the scaphoid. ' +
      'Ossification has begun in the pisiform, the irregularly circular outline of which can ' +
      'be seen indistinctly through the triquetral.\n\n' +
      'The epiphysis of the first metacarpal has a slight indentation on its future articular ' +
      'surface. The radial tip of this epiphysis has not yet reached the corresponding ' +
      'margin of its shaft.\n\n' +
      'The more proximal part of the articular surface of the distal phalanx of the thumb ' +
      'is now slightly concave. The proximal phalanges of the second, third, fourth, and ' +
      'fifth fingers are not yet as wide as their shafts. The epiphyses of the middle ' +
      'phalanges have thickened central portions, angular proximal surfaces, and relatively flattened distal margins. The epiphyses of the distal phalanges of the second ' +
      'to fifth fingers are all wider than their shafts.\n\n',
  },
  19: {
    filepath: '/atlas/male_132.png',
    caption: 'Male 11 years',
    hints:
      'The volar and dorsal margins of the surface of the hamate which will articulate with ' +
      'the fourth metacarpal can now be seen. The distal tip of the hamulus of the ' +
      'hamate is just becoming discernible. The future scaphoid and radial articular ' +
      'surfaces of the lunate now are beginning to be defined. The articular facets of ' +
      'the distal row of carpals, particularly those of the capitate, have become progressively more sharply delimited. The distal margin of the scaphoid is now somewhat flattened and its capitate articular surface distinctly concave. The pisiform is now more distinct than in the preceding standard, its shadow being visible through and between the hamate and triquetral.\n\n' +
      ' ' +
      'The faint white lines which are more distinct along the ulnar and proximal margins ' +
      'of the epiphysis of the second metacarpal outline a portion of the volar surface of ' +
      'that epiphysis. The process of reciprocal shaping of the proximal surface of this ' +
      'epiphysis to its shaft is slightly farther advanced than the same process in the ' +
      'other metacarpals.\n\n' +
      ' ' +
      'The epiphysis of the proximal phalanx of the second finger is now as wide as its shaft.\n\n' +
      'The epiphyses of the distal phalanges of the second, third, fourth, and fifth fingers ' +
      'are beginning to conform in shape to that of the trochlear surfaces of their respective middle phalanges. This process is farthest advanced in the third finger.\n\n' +
      ' ' +
      'The epiphysis of the proximal phalanx of the thumb now extends farther medially ' +
      '(toward the volar surface) than does the corresponding border of its shaft.\n\n' +
      'Reciprocal shaping has progressed in all the carpals and epiphyses according to their ' +
      'contours in the preceding standard.\n\n' +
      ' ',
  },
  20: {
    filepath: '/atlas/male_138.png',
    caption: 'Male 11 years 6 months',
    hints:
      'The developing styloid process of the radial epiphysis has become more distinct and the white line immediately ' +
      'adjacent to the cartilage plate, which marks a part of its volar margin, now extends farther laterally.\n\n' +
      'As compared with Standard 20 [11 years], the carpals are now somewhat larger, the spaces between them have become ' +
      'further reduced, and there has been an increase in the degree of reciprocal shaping of the individual bones to each ' +
      'other, to the bases of the metacarpals, and to the radial and ulnar epiphyses. The capitate surface of the scaphoid ' +
      'now slightly overlaps the adjacent portion of the capitate. The volar margin of the lunate in the preceding standard ' +
      'can be seen as a heavy white line. This line has now expanded centrally to form a blunt protuberance which is directed toward the capitate.\n\n',
  },

  21: {
    filepath: '/atlas/male_150.png',
    caption: 'Male 12 years 6 months',
    hints:
      'Since the preceding standard, the carpals have enlarged, their articular surfaces have ' +
      'become more distinct, and the spaces between them have been further reduced.\n\n' +
      'This differentiation has progressed farther in the distal than in the proximal row ' +
      'of carpals and is most marked in the carpal-metacarpal area.\n\n' +
      ' ' +
      'The outline of the hook of the hamate is now visible. The volar and dorsal margins ' +
      'of the triquetral are becoming distinguishable on the side adjacent to the hamate.\n\n' +
      'A dorsal extension of the capitate articular surface of the scaphoid has appeared, ' +
      'since the preceding standard. It can best be seen immediately medial to the white ' +
      'line formed by the volar margin of the scaphoid.\n\n' +
      ' ' +
      'The base of the second metacarpal has adjusted further to the shape of the adjacent ' +
      'surfaces of the trapezoid. This has been a slow process, which was already under ' +
      'way in Standard 21 [11 years 6 months]. The nature of the change can best be appreciated by ' +
      'comparing Standards 20 [11 years] and 22 [12 years 6 months]. Parts of the volar margins of the epiphyses of the ' +
      'third, fourth, and fifth metacarpals are now distinguishable as fine to heavy white ' +
      'linear markings along the sides or proximal margins of those epiphyses. Reciprocal shaping has progressed in the adjacent surfaces of the metacarpal epiphyses and their shafts.\n\n' +
      ' ' +
      'The epiphyses of the proximal phalanges of the third, fourth, and fifth fingers and of ' +
      'the middle phalanges of the second and third fingers are now as wide as their ' +
      'shafts.\n\n' +
      ' ' +
      'The lateral (radial) side of the epiphysis of the distal phalanx of the third finger ends ' +
      'in a tip which is bending distally. This is an early page of a process which will ' +
      'result in the capping of the shaft by its epiphysis.\n\n' +
      ' ',
  },
  22: {
    filepath: '/atlas/male_156.png',
    caption: 'Male 13 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 13 years.\n\n' +
      'The radial epiphysis and the epiphyses of the second to fifth metacarpals are now as ' +
      'wide as the adjacent margins of their shafts.\n\n' +
      'The ossification center of the sesamoid in the tendon of the adductor pollicis is now ' +
      'visible, just medial to the head of the first metacarpal.\n\n' +
      'The epiphyses of the proximal phalanges of the second, third, fourth, and fifth fingers ' +
      'have increased somewhat in thickness and their radial margins end in distally ' +
      'directed tips. The epiphysis of the middle phalanx of the fifth finger is now as ' +
      'wide as its shaft. The tips of the epiphyses of the distal phalanges of the second ' +
      'to fifth fingers are bent slightly distally and the distal ends of the corresponding ' +
      'middle phalanges are now slightly concave.\n\n' +
      'Note: This film is of the hand of a different boy from the one in the preceding standard.\n\n',
  },
  23: {
    filepath: '/atlas/male_162.png',
    caption: 'Male 13 years 6 months',
    hints:
      'The skeletal age assigned to each bone in this standard is 13 years 6 months.\n\n' +
      'The proximal margins of the radial and ulnar epiphyses have adjusted further to the ' +
      'shape of the adjacent surface of their shafts. The ulnar articular surface of the ' +
      'radius is now flattened. Reciprocal shaping is under way in the radioulnar joint.\n\n' +
      ' ' +
      'The complete outline of the hamulus of the hamate can now be seen distinctly. This ' +
      'scaphoid is more elongated than that in Standard 23 [13 years]; there the scaphoid is shorter ' +
      'and has a flatter and wider distal end than the bone in this standard. These are ' +
      'two contrasting and commonly occurring types of scaphoids. A comparable difference in shape is seen also in the lunates and triquetrals of these two standards. At this stage of development, the distal end of the scaphoid flattens further and increases in size. The white marking on the external margin of a portion of the outline of the tubercle of the scaphoid, traces of which can be seen in earlier standards, indicates the approximate distal limit of its radial articular surface.\n\n' +
      'The surface of the trapezium which articulates with the first metacarpal has ' +
      'become more concave, and the proximal borders of its dorsal and volar surfaces ' +
      'are now readily distinguishable. The articular surfaces of the trapezoid are now ' +
      'well differentiated.\n\n' +
      ' ' +
      'The epiphyses of all of the metacarpals are now clearly as wide as their shafts, and ' +
      'these adjacent margins conform closely in shape.\n\n' +
      ' ' +
      'All of the proximal epiphyses have begun to cap their shafts. The process has advanced ' +
      'farthest in the thumb and in the fifth, fourth, and third fingers. It is more pronounced in the volar than in the dorsal side of the epiphysis of the thumb, and on the radial than on the ulnar side of the epiphyses of the fingers.\n\n' +
      ' ',
  },
  24: {
    filepath: '/atlas/male_168.png',
    caption: 'Male 14 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 14 years.\n\n' +
      'The epiphysis of the radius and the epiphyses of all phalanges of the second, third, ' +
      'fourth, and fifth fingers have now begun to cap their shafts. In the proximal ' +
      'phalanges the capping is more clearly visible on the radial than on the ulnar sides ' +
      'of the epiphyses.\n\n' +
      'The sides of the epiphyses of the second to the fifth metacarpals are now aligned closely ' +
      'with the sides of their shafts. The growth cartilage plates are uniformly narrow ' +
      'and some portions of the epiphysial-shaft spaces are fuzzy, indicating that the late ' +
      'pre-fusion stage has been reached.\n\n' +
      'The extent to which the individual bones in this standard have attained their early ' +
      'adult form can be appreciated by comparing them with those of the same boy at ' +
      'age 18 years as shown in Standard 29 [17 years].\n\n',
  },
  25: {
    filepath: '/atlas/male_180.png',
    caption: 'Male 15 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 15 years.\n\n' +
      'The epiphysis of the radius has capped its shaft. The epiphysis of the ulna is now ' +
      'as wide as its shaft and follows its contour closely. The spaces which separated ' +
      'the radial and ulnar epiphyses from their shafts in the preceding standard have ' +
      'been somewhat reduced.\n\n' +
      'All carpals have now attained their early adult shape.\n\n' +
      'Fusion is under way in the epiphyses of all distal phalanges. It is farthest advanced in ' +
      'the thumb and third finger and least advanced in the fifth. Beginning of fusion ' +
      'is apparent, also, in the dorsal third of the first metacarpal.\n\n',
  },
  26: {
    filepath: '/atlas/male_186.png',
    caption: 'Male 15 years 6 months',
    hints:
      'The skeletal age assigned to each bone in this standard is 15 years and 6 months.\n\n' +
      'A part of the outline of the tubercle of the scaphoid can now be seen.\n\n' +
      'The flexor sesamoid can now be seen through the head of the first metacarpal, immediately lateral to the adductor sesamoid. It begins to ossify shortly after the adductor sesamoid and is clearly visible in the original film of Standard 24 [13 years 6 months].\n\n' +
      'The epiphysis of the first metacarpal has recently fused with its shaft. The epiphyses ' +
      'of the other metacarpals are about to begin their fusion.\n\n' +
      'Fusion has recently been completed in all of the distal phalanges.\n\n' +
      'This standard illustrates the stage of skeletal development usually attained by boys at ' +
      'the time of puberty.\n\n' +
      'This is the same stage of skeletal development which in girls is usually attained at ' +
      'about the time of their menarche.\n\n',
  },
  27: {
    filepath: '/atlas/male_192.png',
    caption: 'Male 16 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 16 years.\n\n' +
      'The epiphyses of the second, third, fourth, and fifth metacarpals have begun to fuse ' +
      'with their shafts.\n\n' +
      'Epiphysial-diaphysial fusion is well advanced in all the proximal phalanges and in the ' +
      'middle phalanges of the second and fifth fingers. It has also begun in the middle ' +
      'phalanges of the third and fourth fingers.\n\n' +
      'An accessory sesamoid bone is present over the volar surface of the distal end of the ' +
      'proximal phalanx of the thumb and the heads of the second and fifth metacarpals.\n\n' +
      'Other accessory sesamoids are occasionally present. If these accessory sesamoids ' +
      'are going to develop they are usually visible radiographically at this stage.',
  },
  28: {
    filepath: '/atlas/male_204.png',
    caption: 'Male 17 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 17 years.\n\n' +
      'The thickness of the growth cartilage of the radius has become reduced preparatory ' +
      'to epiphysial-diaphysial fusion. Fusion has already begun in the ulna.\n\n' +
      'The epiphyses of the second, third, fourth, and fifth metacarpals have recently fused ' +
      'with their shafts.\n\n' +
      'With the recent completion of fusion in the middle phalanges of the third and fourth ' +
      'fingers, all of the phalangeal epiphyses have now fused with their shafts.\n\n' +
      'With the fusion of an epiphysis with its shaft, the epiphysial cartilage plate which ' +
      'previously has separated them is, of course, eliminated, and the radiolucent line ' +
      'or area which represents the cartilage plate in the radiograph disappears.',
  },
  29: {
    filepath: '/atlas/male_216.png',
    caption: 'Male 18 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 18 years.\n\n' +
      'All the epiphyses except that of the radius have fused with their shafts.\n\n' +
      'The vertical white lines on the heads of the second to fifth metacarpals outline a portion ' +
      'of their volar surface. Before epiphysial-diaphysial fusion occurs, these linear ' +
      'markings are interrupted by the epiphysial cartilage plate, as seen in Standard 24 [13 years 6 months].\n\n' +
      'With the beginning of fusion, the epiphysial segment of these markings becomes ' +
      'continuous with the portion on the shaft. This union usually begins first and is, ' +
      'consequently, farther advanced on the ulnar than on the radial side of the metacarpal head.',
  },
  30: {
    filepath: '/atlas/male_228.png',
    caption: 'Male 19 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 19 years.\n\n' +
      'The radial epiphysis has now fused with its shaft.\n\n' +
      'The fusion of the radial epiphysis with its shaft completes the skeletal maturation of ' +
      'the hand and wrist.\n\n' +
      'The radial epiphysial line has been almost eliminated and will probably completely ' +
      'disappear as this boy grows older. In many persons, however, the line persists ' +
      'throughout adult life.',
  },
};

export const femaleAtlasData = {
  0: {
    filepath: '/atlas/female_000.png',
    caption: 'Female Newborn',
    hints:
      'The degree of flaring of the distal ends of the radius and ulna is that usually seen in ' +
      'hand-films of full-term infants.\n\n' +
      'The shafts of the second to fifth metacarpals are slightly constricted in their middle ' +
      'portions. If the hand has been positioned properly with the palm and fingers flat ' +
      'on the film holder, the proximal ends of the metacarpals are somewhat closer together than their distal ends and, consequently, the shafts appear to radiate out from the carpal area. At birth, the metacarpals of premature infants are usually more parallel to each other.\n\n' +
      'The distal ends of the proximal and middle phalanges are rounded and their proximal ' +
      'ends are wider and flat.',
  },
  1: {
    filepath: '/atlas/female_003.png',
    caption: 'Female 3 months',
    hints:
      'The flaring of the distal ends of the radius and ulna is slightly more pronounced than ' +
      'it was in the preceding standard. A beaklike projection on the radial side of the ' +
      'distal end of the ulna, such as the one visible in this plate, usually persists for ' +
      'several years.\n\n' +
      'A center of ossification is now visible in both the capitate and the hamate. The capitate ' +
      'usually reaches this stage of development slightly earlier than the hamate, as is ' +
      'indicated here by its larger ossification center and rounder shape. The shafts of ' +
      'the metacarpals and phalanges are becoming individualized in size and shape.\n\n' +
      'The ends of the metacarpals have increased in width relatively more than has ' +
      'the central portion of their shafts. The proximal ends of metacarpals II and V ' +
      'and the distal end of metacarpal I are more rounded than they were at birth.\n\n' +
      'The proximal or future epiphysial margin of metacarpal I is flattened and so ' +
      'resembles the corresponding portion of the proximal phalanges.\n\n' +
      'The phalanges have increased relatively more in length than in breadth. Their ' +
      'epiphysial ends are flat and their nonepiphysial ends are rounded.',
  },
  2: {
    filepath: '/atlas/female_006.png',
    caption: 'Female 6 months',
    hints:
      'When the osseous particles are first organized into a carpal or an epiphysial center, ' +
      'the resulting nodule is rounded or oval in form. The next stage of differentiation ' +
      'is localized flattening. The hamate surface of the capitate is beginning to show ' +
      'this flattening by a reduction in the degree of its convexity. The growth of the ' +
      'capitate and hamate centers has brought them closer together. It should be remembered, of course, that the cartilaginous carpals in which these centers of ' +
      'ossification are developing are in close apposition to each other, as their adequate ' +
      'functioning would require. The future long axis of the capitate is now evident.\n\n' +
      ' ' +
      'Individual differences in the shape and dimensions of the metacarpal shafts were established shortly after the stage of development seen in the preceding standard.\n\n' +
      'The bases of the third and fourth metacarpals are now distinctly rounded.',
  },
  3: {
    filepath: '/atlas/female_009.png',
    caption: 'Female 9 months',
    hints:
      'The capitate is now larger and farther advanced in its development than the hamate. The base of the second metacarpal has begun to enlarge.',
  },
  4: {
    filepath: '/atlas/female_012.png',
    caption: 'Female 1 year',
    hints:
      'A flattened oval ossification center is now present in the distal epiphysis of the radius.\n\n' +
      'Its size and form suggest that its ossification began soon after the stage of development shown in the preceding standard.\n\n' +
      'The hamate is now somewhat wedge-shaped, its proximal end being narrower than its ' +
      'distal end.\n\n' +
      'The capitate articular surface of the second metacarpal has begun to flatten. The ' +
      'epiphyses of the second and third metacarpals and the epiphyses of the proximal ' +
      'phalanges of the second, third, and fourth fingers now contain small, recently ' +
      'formed ossification centers.',
  },
  5: {
    filepath: '/atlas/female_015.png',
    caption: 'Female 1 year 3 months',
    hints:
      'The capitate and hamate have not advanced significantly in development since the ' +
      'preceding standard.\n\n' +
      'Ossification has begun in the epiphysis of the fourth metacarpal.\n\n' +
      'Ossification in the epiphysis of the distal phalanx of the thumb has recently begun.',
  },
  6: {
    filepath: '/atlas/female_018.png',
    caption: 'Female 1 year 6 months',
    hints:
      'Ossification has begun in the epiphysis of the fifth metacarpal and in the proximal ' +
      'epiphysis of the fifth finger.\n\n' +
      'The epiphysis of the proximal phalanges of the second, third, fourth, and fifth fingers ' +
      'are now disc-shaped and their margins are fairly smooth. The central portions ' +
      'of the growth cartilage plates of the proximal epiphyses of the third and fourth ' +
      'fingers are now as thin as they will become until epiphysial-diaphysial fusion ' +
      'begins.',
  },
  7: {
    filepath: '/atlas/female_024.png',
    caption: 'Female 2 years',
    hints:
      'The radial epiphysis now has a rounded lateral margin and a rather pointed ulnar tip.\n\n' +
      'The capitate surface of the hamate has flattened (i.e., it has become less convex) and ' +
      'its proximal end is distinctly narrower than its distal end. Its triquetral surface ' +
      'has begun to flatten. Ossification has begun in the triquetral, in the epiphysis of ' +
      'the first metacarpal, and in the proximal phalanx of the thumb. The epiphysis of ' +
      'the proximal phalanx of the thumb occasionally forms from multiple centers rather ' +
      'than from a single center. The multiple centers are usually arrayed transversely ' +
      'before they coalesce.\n\n' +
      'The epiphyses of the second to fifth metacarpals are now rounded and their margins ' +
      'are smooth. The proximal margin of the epiphysis of the second metacarpal has ' +
      'flattened slightly as it begins to conform to the shape of the adjacent surface of its ' +
      'shaft.\n\n' +
      'The epiphyses of the proximal phalanges of the second, third, fourth, and fifth fingers ' +
      'are now more than half as wide as their shafts, and the growth cartilage plate of the ' +
      'fifth has attained its definitive thickness. Ossification has begun in the epiphyses ' +
      'of the distal phalanges of the third, fourth, and fifth fingers.\n\n' +
      ' ',
  },
  8: {
    filepath: '/atlas/female_030.png',
    caption: 'Female 2 years 6 months',
    hints:
      'The distal margins of the volar and dorsal surfaces of the radial epiphysis are now ' +
      'distinguishable. The distal volar margin can be seen as an oblique white line ' +
      'which lies just proximal to the distal margin of the epiphysis and extends laterally ' +
      'from it ulnar tip.\n\n' +
      'The triquetral center is rounded and its margins are fairly smooth. It is slightly advanced as compared with other bones of this hand.\n\n' +
      'That portion of the base of the second metacarpal which will later articulate with the ' +
      'trapezoid has begun to flatten.\n\n' +
      'The proximal surfaces of the epiphyses of the third, fourth, and fifth metacarpals are ' +
      'now beginning to shape to their respective shafts.\n\n' +
      'The epiphysis of the proximal phalanx of the thumb is disc-shaped and its margins ' +
      'are smooth. It is now more than half as wide as its shaft. The central portion of ' +
      'the growth cartilage plate has now attained its definitive thickness. The distal ' +
      'margin of the epiphysis of the distal phalanx of the thumb has flattened to conform to the shape of the adjacent surface of its shaft. The epiphyses of the distal phalanges of the third and fourth fingers are now disc-shaped. The epiphyses of the distal phalanx of the second finger and middle phalanx of the fifth finger have small, recently formed ossification centers.\n\n' +
      ' ',
  },
  9: {
    filepath: '/atlas/female_036.png',
    caption: 'Female 3 years',
    hints:
      'Most of the proximal margin of the volar surface of the radial epiphysis can now be ' +
      'seen.\n\n' +
      'Ossification began in the lunate soon after the stage of development represented by ' +
      'Standard 9 [2 years 6 months]. The lunate in this hand is considerably more advanced in its development than the other bones. This center is now rounded and its margins are smooth.\n\n' +
      'The epiphyses of the second to fifth metacarpals have enlarged and their proximal ' +
      'margins are flattened. The epiphysis of the first metacarpal is oval and its long ' +
      'axis is transverse.\n\n' +
      'The epiphyses of the proximal phalanges of the second, third, fourth, and fifth fingers ' +
      'have increased more in thickness than in width, and the outlines of their proximal margins can be seen as dense white lines. The epiphyses of the middle phalanges of the second, third, and fourth fingers are now slightly more than half as wide as their shafts.\n\n' +
      'Standard 10 [3 years] and Standards 13-19 [5 - 11 years] are radiographs of the same girl.\n\n' +
      ' ',
  },
  10: {
    filepath: '/atlas/female_042.png',
    caption: 'Female 3 years 6 months',
    hints:
      'The ulnar portion of the distal margin of the radial epiphysis flattens slightly as its ' +
      'lunate articular surface begins to differentiate.\n\n' +
      'Some reciprocal shaping of the adjacent surfaces of the capitate and hamate is now ' +
      'apparent. A tiny center of ossification has appeared, somewhat precociously, in ' +
      'the trapezium.\n\n' +
      'The ulnar, distal, and radial margins of the epiphyses of the second and third metacarpals are becoming dissimilar in shape as their joint surfaces begin to differentiate.\n\n' +
      'The articular surfaces of the epiphyses of the proximal phalanges of the second and ' +
      'third fingers have become slightly concave as they shape to the heads of the corresponding metacarpals.\n\n' +
      ' ',
  },
  11: {
    filepath: '/atlas/female_050.png',
    caption: 'Female 4 years 2 months',
    hints:
      'Ossification centers are now visible in the scaphoid and trapezoid, the latter having ' +
      'appeared shortly before that in the scaphoid. The center for the trapezium is oval ' +
      'and its margins are smooth. The capitate and radial surfaces of the lunate are ' +
      'rather flat. A small indentation, which is more prominent in the next standard, ' +
      'is now visible in the lateral margin of the capitate. If a distinct notch is subsequently to appear in this margin of the capitate at the junction of the capitate, scaphoid, and trapezoid, it will develop at the site of the small indentation.\n\n' +
      ' ' +
      'The epiphyses of the proximal phalanges of the fourth and fifth fingers are becoming ' +
      'slightly concave as they shape to the heads of the metacarpals.',
  },
  12: {
    filepath: '/atlas/female_060.png',
    caption: 'Female 5 years',
    hints:
      'The part of the radial epiphysis from which the styloid process develops is beginning ' +
      'to enlarge.\n\n' +
      'The hamate surface of the triquetral has begun to flatten, and its ulnar margin has ' +
      'become less convex. The dorsal and volar aspects of the capitate surface of the ' +
      'lunate are beginning to be distinguishable. The curved white linear marking near ' +
      'the distal margin represents a part of the volar surface. The center for the trapezoid is now rounded and its margins are fairly smooth. The surface of the trapezium which will later articulate with the first metacarpal has begun to flatten.\n\n' +
      'The ossification center of the scaphoid is ovoid and its margins are still somewhat ' +
      'irregular.\n\n' +
      'The radial, ulnar, and distal margins of the epiphyses of the fourth and fifth metacarpals are becoming dissimilar in shape as their joint surfaces begin to differentiate.\n\n' +
      'The epiphyses of the middle phalanges of the second, third, and fourth fingers are ' +
      'shaping to the contours of the trochlear surfaces of the proximal phalanges. The ' +
      'distal phalangeal epiphyses of the third, fourth, and fifth fingers are now as wide ' +
      'as their shafts.',
  },
  13: {
    filepath: '/atlas/female_069.png',
    caption: 'Female 5 years 9 months',
    hints:
      'The relative enlargement of the lateral (thumb side) half of the radial epiphysis, which ' +
      'began in the preceding standard, is now distinct.\n\n' +
      'The contiguous margins of the capitate and hamate now overlap. The space between ' +
      'the trapezoid and trapezium has become much reduced.\n\n' +
      'The epiphysis of the distal phalanx of the third finger has shaped further to the contour ' +
      'of the trochlear surface of the middle phalanx.\n\n' +
      ' ',
  },
  14: {
    filepath: '/atlas/female_082.png',
    caption: 'Female 6 years 10 months',
    hints:
      'The styloid process of the radius begins to form. Further modeling of the carpal ' +
      'articular surface of the radius has occurred. An ossification center is now present ' +
      'in the distal epiphysis of the ulna.\n\n' +
      'As the triquetral has elongated, its lunate surface has flattened slightly, and its distal ' +
      'end has become relatively narrower. The capitate surface of the scaphoid is now ' +
      'slightly concave. The portion of the proximal margin of the trapezium which will ' +
      'later articulate with the scaphoid is now somewhat flattened. A similar flattening ' +
      'can be seen in the future capitate articular surface of the trapezoid. The spaces ' +
      'between the scaphoid, lunate, and capitate and between the lunate and triquetral ' +
      'have been considerably reduced. The persisting spaces provide a measure of the ' +
      'extent to which replacement of the cartilage by bone is as yet incomplete.\n\n' +
      'The base of the second metacarpal adjacent to the trapezoid is now slightly concave.\n\n' +
      'There is an indication of the beginning of this concavity in Standard 14 [5 years 9 months], and it ' +
      'becomes more pronounced in subsequent standards.\n\n' +
      'The oblique view of the thumb, which the ordinary hand-film provides, permits the ' +
      'observation that the epiphyses of its phalanges reach the palmar or volar margin ' +
      'of the shafts before they grow far enough dorsally to be aligned with that margin ' +
      'of the shaft. The epiphysis of the middle phalanx of the fifth finger is shaping to ' +
      'the contour of the trochlear surface of the proximal phalanx.',
  },
  15: {
    filepath: '/atlas/female_094.png',
    caption: 'Female 7 years 10 months',
    hints:
      'The ulnar epiphysis has increased in size, and its proximal margin has begun to shape ' +
      'to its shaft.\n\n' +
      'The lunate surface of the triquetral is now distinctly flattened. The lunate has shaped ' +
      'further to the adjacent surfaces of the capitate and the radial epiphysis. The outline of the volar margin of the capitate surface of the scaphoid is beginning to be discernible. That portion of the trapezium which will later articulate with the bases of the first and second metacarpals now forms a distinct, rounded projection. The middle of its surface which will later articulate with the first metacarpal is now concave. The most medial part of the trapezium overlaps the lateral margin of the trapezoid. The scaphoid surface of the trapezoid has flattened. The margins ' +
      'of its volar and dorsal surfaces are now distinguishable.\n\n' +
      'The portion of the base of the second metacarpal which articulates with the trapezoid ' +
      'is now concave, and its capitate articular surface has begun to elongate.\n\n' +
      'The epiphysis of the proximal phalanx of the thumb now extends farther medially ' +
      '(i.e., toward the volar surface) than does the corresponding border of its shaft; it ' +
      'has now reached the dorsal margin. The articular surface on the distal ends of the ' +
      'shafts of the proximal phalanges of the second and third fingers are now slightly ' +
      'concave. The articular surface of the epiphysis of the distal phalanx of the thumb ' +
      'has flattened somewhat. It will later become concave to conform to the convexity ' +
      'of the adjacent surfaces of the proximal phalanx. The epiphyses of the distal ' +
      'phalanges of the second, third, fourth, and fifth fingers are shaping to the trochlear ' +
      'surfaces of the middle phalanges.',
  },
  16: {
    filepath: '/atlas/female_106.png',
    caption: 'Female 8 years 10 months',
    hints:
      'The ulnar epiphysis has now flattened and widened to form a bony plate, the exact ' +
      'shape of which varies in different children. Its distal margin is concave and there ' +
      'is a distinct styloid process.\n\n' +
      'The metacarpal articular surfaces of the capitate are beginning to form. The portion ' +
      'of the hamate which will later articulate with the base of the fifth metacarpal has ' +
      'begun to elongate. The volar and dorsal margins of the radial half of its metacarpal ' +
      'surface can now be distinguished. The scaphoid and radial surfaces of the lunate ' +
      'are beginning to be defined. The distal margin of the scaphoid has begun to flatten ' +
      'and its medial portion is enlarging toward the capitate. Much of the volar and ' +
      'dorsal surfaces of the trapezoid are now distinguishable. The rather advanced ' +
      'ossification center of the pisiform can be seen volar to the triquetral.\n\n' +
      'The proximal surfaces of the epiphyses of the second, third, fourth, and fifth ' +
      'metacarpals are shaping further to the ends of their shafts. Parts of the volar margins of ' +
      'these epiphyses are now visible as white linear markings near their radial and ' +
      'ulnar borders.\n\n' +
      'The epiphyses of the proximal and middle phalanges of the second, third, and fourth ' +
      'fingers are now as wide as their shafts.',
  },
  17: {
    filepath: '/atlas/female_120.png',
    caption: 'Female 10 years',
    hints:
      'The outline of the tip of the hamulus of the hamate is now discernible. The distal half ' +
      'of the triquetral has enlarged and its hamate surface has begun to adjust to the ' +
      'shape of that bone. A part of the volar margin of its hamate surface can now be ' +
      'seen. The scaphoid and radial articular surfaces of the lunate are beginning to be ' +
      'defined.\n\n' +
      'An indentation has developed in the articular surface of the epiphysis of the first ' +
      'metacarpal. Parts of the volar margin of the epiphysis of the fifth metacarpal are now ' +
      'discernible as white linear markings along its radial and ulnar borders.\n\n' +
      'The developing trochlear surfaces on the distal ends of the proximal phalanges of the ' +
      'second, third, and fourth fingers now have shallow central indentations. The ' +
      'epiphyses of the proximal and middle phalanges of the fifth finger are now as ' +
      'wide as their shafts. The radial tip of the epiphysis of the distal phalanx of the ' +
      'third finger has begun to cap its shaft.',
  },
  18: {
    filepath: '/atlas/female_132.png',
    caption: 'Female 11 years',
    hints:
      'The proximal margin of the radial epiphysis has adjusted further to the shape of the ' +
      'distal end of its shaft. The ulnar epiphysis is shaping to the adjacent surface of ' +
      'the radius and to the radial portion of the end of its own shaft.\n\n' +
      'The hook of the hamulus appears as a triangular outline within the shadow of the ' +
      'hamate. Further reciprocal shaping has occurred in the adjacent surfaces of the ' +
      'capitate and scaphoid. The trapezoid has increased more in its transverse than in ' +
      'its other dimensions, since the preceding standard. Its articular surfaces are now ' +
      'well defined. The medial half of the trapezium now projects distally toward the ' +
      'base of the second metacarpal, with which it will later articulate.\n\n' +
      'The base of the second metacarpal has shaped further to the adjacent surface of the ' +
      'trapezoid. As the epiphysis of the first metacarpal enlarges, it conforms more ' +
      'closely to the shape of the adjacent surface of the trapezium and to that of its own ' +
      'shaft. The sesamoid of the adductor pollicis and that of the flexor pollicis brevis ' +
      'have begun to ossify. The flexor sesamoid can be seen through the head of the ' +
      'first metacarpal, directly opposite the adductor sesamoid. It is more distinct in ' +
      'Standard 20 [12 years]. The epiphyses of the second, third, fourth, and fifth metacarpals are ' +
      'now as wide as the distal ends of their shafts, to which they conform closely in ' +
      'shape. There is as yet no apparent reduction in the thickness of their epiphysial ' +
      'cartilage plates.\n\n' +
      'The epiphyses of the proximal and middle phalanges of the second, third, fourth, and ' +
      'fifth fingers and those of the distal phalanges of the thumb and the second, fourth, ' +
      'and fifth fingers now cap their shafts. The capping is more pronounced on the ' +
      'radial than on the ulnar tips of the epiphyses.',
  },
  19: {
    filepath: '/atlas/female_144.png',
    caption: 'Female 12 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 12 years.\n\n' +
      'The radial epiphysis caps its shaft and its ulnar articular surface has flattened. The ' +
      'epiphysis of the ulna has shaped further to the distal end of its shaft, and its styloid ' +
      'process has become more prominent.\n\n' +
      'The various articular surfaces of the capitate, hamate, trapezoid, and trapezium are ' +
      'now well defined. The carpals in the proximal row are only slightly less advanced ' +
      'in their differentiation. In Standard 19 [11 years], a faint white marking is visible on the ' +
      'lateral surface of the scaphoid. This marking, which outlines the developing ' +
      'tubercle of the scaphoid, has become more distinct. The definitive shape of the ' +
      'joint between the scaphoid, trapezoid, and trapezium is now established. The surface ' +
      'of the trapezium which articulates with the first metacarpal has become ' +
      'more concave, and the proximal borders of its dorsal and volar surfaces are now ' +
      'distinguishable.\n\n' +
      'The dorsovolar concavity in the articular surface of the epiphysis of the first metacarpal ' +
      'which is visible in this oblique view, has become more pronounced as it shaped ' +
      'further to the adjacent surface of the trapezium.\n\n' +
      'The radiolucent spaces between the various epiphyses and their shafts represent the ' +
      'epiphysial cartilage plates, all of which are now as thin as they will become until ' +
      'epiphysial-diaphysial fusion begins.',
  },
  20: {
    filepath: '/atlas/female_156.png',
    caption: 'Female 13 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 13 years.\n\n' +
      'The form of all of the carpals is now essentially adult, and each bone now possesses all, ' +
      'or almost all, of those peculiarities in form which distinguish the carpals of one ' +
      'young adult from those of another. The tubercle of the scaphoid indicates the ' +
      'approximate distal limit of its radial articular surface.\n\n' +
      'The epiphysis of the first metacarpal now caps its shaft. The base of the second ' +
      'metacarpal caps the trapezoid.\n\n' +
      'The epiphysis of the distal phalanx of the thumb has begun to fuse with its shaft.\n\n' +
      'The thickness of all of the epiphysial cartilages, except those of the radius and ulna, ' +
      'has been appreciably reduced.\n\n' +
      'A small accessory sesamoid bone is visible through the shadow of the epiphysis of ' +
      'the fifth metacarpal. Other accessory sesamoids are occasionally present. If these ' +
      'accessory sesamoids are going to develop, they are usually visible radiographically ' +
      'at this stage.\n\n' +
      'The extent to which all individual bones in this standard have attained their early ' +
      'adult form can be appreciated by comparing them with those of the same girl at ' +
      'age 16 years as shown in Standard 25 [16 years].',
  },
  21: {
    filepath: '/atlas/female_162.png',
    caption: 'Female 13 years 6 months',
    hints:
      'The skeletal age assigned to each bone in this standard is 13 years 6 months.\n\n' +
      "This radiograph was made three weeks after the girl's first menstruation. It illustrates " +
      'the stage of skeletal development usually attained by girls at about the time of the ' +
      'menarche. Male Standard 27 [15 years 6 months] depicts a comparable stage of skeletal development.\n\n' +
      'Epiphysial-diaphysial fusion began in the first metacarpal since the previous standard ' +
      'and is now well under way.\n\n' +
      'Fusion has begun also in the proximal phalanges of the third, fourth, and fifth fingers.\n\n' +
      'The epiphysial cartilage of the proximal phalanx of the second finger has become ' +
      'noticeably reduced in thickness. The epiphyses of all distal phalanges are now ' +
      'fused with their shafts.\n\n' +
      ' ',
  },
  22: {
    filepath: '/atlas/female_168.png',
    caption: 'Female 14 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 14 years.\n\n' +
      'The epiphysial cartilage plates of the radius and ulna are now appreciably reduced in ' +
      'thickness.\n\n' +
      'The fusion of the epiphysis of the first metacarpal with its shaft is now completed, ' +
      'although the line of fusion is still distinct. Epiphysial-diaphysial fusion is well ' +
      'under way, also, in the second, third, fourth, and fifth metacarpals.\n\n' +
      'Fusion is now almost complete in the proximal phalanges of the second, third, and ' +
      'fourth fingers, and it is completed in the fifth. This process is almost completed ' +
      'in the middle phalanx of the second finger, and it is well advanced in the middle ' +
      'phalanges of the third, fourth, and fifth fingers.',
  },
  23: {
    filepath: '/atlas/female_180.png',
    caption: 'Female 15 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 15 years.\n\n' +
      'The radial and ulnar epiphyses have begun to fuse with their shafts. This fusion has ' +
      'progressed farther in the ulna than in the radius.\n\n' +
      'Fusion is completed in the second, third, fourth, and fifth metacarpals and in all the ' +
      'proximal phalanges, and the lines of fusion are almost entirely obliterated. The ' +
      'epiphyses of the middle phalanges of the second, third, fourth, and fifth fingers ' +
      'are fusing with their shafts, but the process is not as far advanced as it is in either ' +
      'the proximal or distal phalanges.\n\n' +
      'Fusion is completed first in the distal, next in the proximal, and last in the middle ' +
      'phalanges of the second, third, fourth, and fifth fingers.',
  },
  24: {
    filepath: '/atlas/female_192.png',
    caption: 'Female 16 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 16 years.\n\n' +
      'Beginning fusion of the distal epiphysis of the radius with its shaft can be seen in Standard 24 [15 years]. ' +
      'The process has now extended to include all except the periphery of the epiphysial-diaphysial junction.' +
      ' Resulting gaps, like those visible on both margins of the radius and near the base of the styloid process on the ulna, ' +
      'persist for a variable length of time. Fusion in the ulna is now almost complete.',
  },
  25: {
    filepath: '/atlas/female_204.png',
    caption: 'Female 17 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 17 years.\n\n' +
      'Fusion is now complete in the radius and ulna.\n\n' +
      'In the ulna and in the middle phalanges of the second, third, fourth, and fifth fingers ' +
      'the epiphysial lines have been almost completely obliterated.\n\n' +
      'In the ulna and in the heads of the second to fifth metacarpals, the linear cortical ' +
      'markings and the continuity of the trabecular patterns across the former epiphysial-diaphysial ' +
      'junction indicate that fusion has been completed for at least several months. In the radius, in the first metacarpal,' +
      ' and, to a lesser degree, in some of the phalanges, thin terminal lines extend completely across the shafts.',
  },
  26: {
    filepath: '/atlas/female_216.png',
    caption: 'Female 18 years',
    hints:
      'The skeletal age assigned to each bone in this standard is 18 years.\n\n' +
      'This hand is indistinguishable from that of a young adult. ' +
      'Traces of some epiphysial lines of fusion can still be seen. ' +
      'Some of these, especially that of the radius and of the first metacarpal, ' +
      'may persist throughout life.',
  },
};
