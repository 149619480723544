import ChangePlanCard from "./ChangePlanCard";
import Loading from "../utils/Loading";
import { useState } from "react";
import {
  apiUpgradePlan,
  apiDowngradePlan,
  apiFromPayAsYouGo,
  apiToPayAsYouGo,
  apiChangePlanInTrial,
  apiSetUnsubscribe,
} from "../../api/change_plans";
import ModalLayout, { PromptModalLayout } from "../utils/ModalLayout";
import UnsubscribeFormModal from "./UnsubscribeFormModal";

const ChangePlan = (props) => {
  const {
    inTrial,
    currentPlanID,
    currentSchedule,
    planDetails,
    maxGrade,
    currentPlanGrade,
    futurePlan,
    unsubDate,
  } = props;

  const [planValue, setPlanType] = useState(currentPlanID);
  const [schedule, setSchedule] = useState(currentSchedule);

  const [showConfirmUnsub, setShowConfirmUnsub] = useState(false);
  const [showDowngrade, setShowDowngrade] = useState(null);
  const [showUpgrade, setShowUpgrade] = useState(null);
  const [showFromPayAsGo, setShowFromPayAsGo] = useState(null);
  const [showToPayAsGo, setShowToPayAsGo] = useState(null);
  const [showSuccUnsub, setShowSuccUnsub] = useState("");
  const [showUnsubFeedbackForm, setShowUnsubFeedbackForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [paymentError, setPaymentError] = useState("");
  const reloadPage = () => {
    window.location.reload();
    // window.location.href = window.location.href;
  };

  const closeUnsubSuccModal = () => {
    setShowSuccUnsub("");
    setShowUnsubFeedbackForm(true);
  };

  const handleUnsubscribe = async () => {
    setIsLoading(true);
    if (unsubDate) {
      setIsLoading(false);
      setShowConfirmUnsub(false);
      setPaymentError(
        "You are already set to be unsubscribed from service on " + unsubDate
      );
      return;
    }

    const res = await apiSetUnsubscribe();
    setShowConfirmUnsub(false);
    setIsLoading(false);
    if (res.msg === "failed") {
      setPaymentError("Requested action failed. Please try again.");
    } else {
      setShowSuccUnsub(res.msg);
    }
  };

  const onPlanChange = async (selectedPlanDetails) => {
    const selectedPlanID = selectedPlanDetails.stripe_pricing_id[schedule];
    const changeToAnotherPlan = selectedPlanID !== currentPlanID;

    if (changeToAnotherPlan === true) {
      const changeSchedule = schedule !== currentSchedule;
      // moving from monthly to yearly is an upgrade
      const scheduleStatus = () => {
        if (schedule === currentSchedule) {
          return "same";
        } else if (schedule === "monthly" && currentSchedule === "yearly") {
          return "down";
        } else if (schedule === "yearly" && currentSchedule === "monthly") {
          return "up";
        }
      };
      const isPlanUpgrade = currentPlanGrade <= selectedPlanDetails.plan_grade;

      const fromPayToGo = maxGrade === currentPlanGrade;
      const toPayToGo = selectedPlanDetails.plan_grade === maxGrade;

      if (inTrial === true && toPayToGo === false) {
        setIsLoading(true);
        await apiChangePlanInTrial(selectedPlanID);
        setIsLoading(false);
        reloadPage();
      } else {
        if (fromPayToGo === true) {
          setShowFromPayAsGo(selectedPlanID);
        } else if (toPayToGo === true) {
          setShowToPayAsGo(selectedPlanID);
        } else if (scheduleStatus() === "same" && isPlanUpgrade === false) {
          // console.log(
          //   "Downgrade! since plan downgraded while schedule remained the same"
          // );
          setShowDowngrade(selectedPlanID);
        } else if (scheduleStatus() === "down") {
          // console.log("Downgrade! since shifting from yearly to monthly");
          setShowDowngrade(selectedPlanID);
        } else {
          // console.log("Upgrade!");
          setShowUpgrade(selectedPlanID);
        }
      }
    } else {
      if (!unsubDate) setShowConfirmUnsub(true);
    }
  };

  const confirmUpgrade = async () => {
    setIsLoading(true);
    const res = await apiUpgradePlan(showUpgrade);
    if (res.msg !== "successful") {
      setShowUpgrade(null);
      setPaymentError("Requested action failed. Please try again.");
    } else {
      reloadPage();
    }
    setIsLoading(false);
  };

  const confirmDowngrade = async () => {
    setIsLoading(true);
    const res = await apiDowngradePlan(showDowngrade);
    setShowDowngrade(null);
    setIsLoading(false);

    if (res.msg !== "successful") {
      setPaymentError("Requested action failed. Please try again.");
    } else {
      reloadPage();
    }
  };

  const confirmFromPayAsGo = async () => {
    setIsLoading(true);
    const res = await apiFromPayAsYouGo(showFromPayAsGo);
    if (res.msg !== "successful") {
      setIsLoading(false);
      setPaymentError("Requested action failed. Please try again.");
      setShowFromPayAsGo(null);
    } else {
      reloadPage();
    }
  };

  const confirmToPayAsGo = async () => {
    setIsLoading(true);
    const res = await apiToPayAsYouGo(showToPayAsGo);
    if (res.msg !== "successful") {
      setIsLoading(false);
      setShowToPayAsGo(null);
      setPaymentError("Requested action failed. Please try again.");
    } else {
      reloadPage();
    }
  };

  const styles = {
    default:
      "bg-primary hover:bg-blue-700 h-11 rounded text-white py-2 px-4 rounded border border-primary focus:outline-none focus:shadow-outline",
    disabled:
      "bg-gray-500 text-white rounded hover:bg-gray-500 cursor-not-allowed pointer-events-none py-2 px-4 h-11",
    selected:
      "bg-blue-600 hover:bg-blue-700 h-11 text-white py-2 px-4 border border-blue-600",
    notSelected:
      "bg-black hover:bg-gray-700 h-11 text-blue-600 py-2 px-4 border border-blue-600",
    optionsBtn: "bg-gray-700 hover:bg-gray-600 text-gray-100 py-2 px-4 rounded",
  };

  if (isLoading === true) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex flex-row mb-10 justify-center">
        <button
          className={
            schedule === "monthly" ? styles.selected : styles.notSelected
          }
          onClick={() => setSchedule("monthly")}
        >
          Monthly
        </button>
        <button
          className={
            schedule === "yearly" ? styles.selected : styles.notSelected
          }
          onClick={() => setSchedule("yearly")}
        >
          Yearly (save 10%)
        </button>
      </div>
      {futurePlan ? (
        <div className="text-center mb-10">
          <div className="inline mx-2 bg-gray-100 text-gray-700 rounded-xl px-2 py-1">
            <span className="text-primary">NOTE</span>
            {`: You will be switching to `}
            <span className="text-black capitalize font-bold">
              {futurePlan.name}
            </span>
            {` on ${futurePlan.startDate}`}
          </div>
        </div>
      ) : (
        ""
      )}
      {unsubDate ? (
        <div className="mx-auto mb-10 bg-gray-100 text-gray-700 rounded-xl px-2 py-1">
          <span className="text-primary">NOTE</span>
          {`: You will lose all access to Physis on `}
          <span className="text-black capitalize font-bold">{unsubDate}</span>
        </div>
      ) : (
        ""
      )}
      <div className="mb-6 mt-2">
        <div className="flex flex-col gap-2 w-[95%] mx-auto md:grid md:md-layout-grid lg:w-layout-grid">
          {planDetails.map((_details, _ind) => {
            return (
              <ChangePlanCard
                planDetails={_details}
                currentPlanValue={planValue}
                schedule={schedule}
                onPlanChange={() => onPlanChange(_details)}
                savedSchedule={currentSchedule}
                unsubDate={unsubDate}
                key={_ind}
              />
            );
          })}
        </div>
      </div>
      {showConfirmUnsub === true ? (
        <PromptModalLayout
          title="Confirm Plan Cancellation"
          text="* Are you sure you want to unsubscribe? You will lose access to Physis dashboard and assessment features. If you meant to switch plans, click Go Back and click Subscribe on the plan of your choice."
          onActionFn={handleUnsubscribe}
          onCancelFn={() => setShowConfirmUnsub(false)}
          actionTitle="Unsubscribe"
          cancelTitle="Go Back"
        />
      ) : (
        ""
      )}
      {showDowngrade !== null ? (
        <PromptModalLayout
          title="Confirm Change in Plan"
          text="* Change will take effect at the end of the next billing cycle"
          onActionFn={confirmDowngrade}
          onCancelFn={() => setShowDowngrade(null)}
          actionTitle="Confirm"
          cancelTitle="Cancel"
        />
      ) : (
        ""
      )}
      {showUpgrade !== null ? (
        <PromptModalLayout
          title="Confirm Change in Plan"
          text="* You are being charged prorated and change will take effect immediately"
          onActionFn={confirmUpgrade}
          onCancelFn={() => setShowUpgrade(null)}
          actionTitle="Confirm"
          cancelTitle="Cancel"
        />
      ) : (
        ""
      )}
      {showFromPayAsGo !== null ? (
        <PromptModalLayout
          title="Confirm Change in Plan"
          text="* You are being charged immediately and change will also take effect immediately"
          onActionFn={confirmFromPayAsGo}
          onCancelFn={() => setShowFromPayAsGo(null)}
          actionTitle="Confirm"
          cancelTitle="Cancel"
        />
      ) : (
        ""
      )}
      {showToPayAsGo !== null ? (
        <PromptModalLayout
          title="Confirm Change in Plan"
          text="*You are NOT being charged prorated and change will take effect immediately. If you are in trial, it will also be canceled."
          onActionFn={confirmToPayAsGo}
          onCancelFn={() => setShowToPayAsGo(null)}
          actionTitle="Confirm"
          cancelTitle="Cancel"
        />
      ) : (
        ""
      )}
      {paymentError !== "" ? (
        <ModalLayout>
          <div className="flex justify-center text-xl">Error message</div>
          <div className="flex text-gray-400 justify-center my-2">
            {paymentError}
          </div>
          <div className="flex flex-row gap-4 justify-center">
            <button
              className="bg-gray-700 hover:bg-gray-600 text-gray-100 py-2 px-4 rounded"
              onClick={() => setPaymentError("")}
            >
              OK
            </button>
          </div>
        </ModalLayout>
      ) : (
        ""
      )}
      {showSuccUnsub !== "" ? (
        <ModalLayout onClose={closeUnsubSuccModal}>
          <div className="flex justify-center text-xl">
            Unsubscribed Successfully!
          </div>
          <div className="flex text-gray-400 justify-center my-2">
            {showSuccUnsub}
          </div>
          <div className="flex flex-row gap-4 justify-center">
            <button
              className="bg-gray-700 hover:bg-gray-600 text-gray-100 py-2 px-4 rounded"
              onClick={closeUnsubSuccModal}
            >
              OK
            </button>
          </div>
        </ModalLayout>
      ) : (
        <></>
      )}
      {showUnsubFeedbackForm === true ? (
        <UnsubscribeFormModal
          setShowUnsubFeedbackForm={setShowUnsubFeedbackForm}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default ChangePlan;
