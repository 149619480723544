import client from './axios';

export const apiGetDashboardData = async (pageNum, pageSize, sort, setData) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const res = await client.get(`/dashboard/get-data?page_num=${pageNum}&page_size=${pageSize}&sort=${sort}&timezone=${timezone}`);
  setData(res.data);
  return res;
};

export const apiDashboardDeleteInf = async (infID) => {
  const res = await client.get(`/dashboard/delete-inference?inference_id=${infID}`);
  return res;
}
