import axios from "axios";
import Cookies from "js-cookie";
import { Buffer } from "buffer";
import { trackLogout } from "./mixpanel.js";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export const apiLogin = async (username, password) => {
  try {
    // populate a new form with username/password
    let formData = new FormData();
    formData.set("username", username);
    formData.set("password", password);

    let res = await axios.post("/login", formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // expire the cookie after 3 days
    Cookies.set("access", res.data.access_token, { expires: 3 });
    return res.data;
  } catch (err) {
    console.log(err);
    return "denied";
  }
};
export const apiSendResetPasswordLink = async (userDict) => {
  try {
    let res = await axios.post("/send-reset-password-link", userDict, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return {
      status: "failed",
      status_message: "Unexcepted error has occurred. Try again!",
    };
  }
};

export const apiEmailUsingToken = async (userDict) => {
  try {
    let res = await axios.post("/verify-reset-password-token", userDict, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return {
      status: "failed",
      status_message: "Unexcepted error has occurred. Try again!",
    };
  }
};

export const apiRegister = async (userDict) => {
  try {
    const res = await axios.post("/register", userDict);
    Cookies.set("access", res.data.access_token);
    // return res.data.id;
  } catch (err) {
    return err.response.data.detail;
  }
};

export const getToken = () => {
  let token = Cookies.get("access");
  if (token) {
    var tokens = token.split(".");
    var payload = JSON.parse(Buffer.from(tokens[1], "base64").toString());
    //console.log(tokens, payload);
    return payload;
  }
};

export const clearToken = () => {
  trackLogout();
  Cookies.remove("access");
};

export const apiResetPassword = async (userDict) => {
  try {
    let res = await axios.post("/reset-password", userDict, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return {
      status: "failed",
      status_message: "Unexcepted error has occurred. Try again!",
    };
  }
};
