import { Buffer } from "buffer";

export const Base64EncodeUrl = (s) => {
  const str = Buffer.from(String(s)).toString('base64');
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export const Base64DecodeUrl = (str)  => {
  if (str.length % 4 !== 0) str += '==='.slice(0, 4 - (str.length % 4));
  let s = str.replace(/-/g, '+').replace(/_/g, '/');
  return String(Buffer.from(s, 'base64').toString('ascii'));
}

