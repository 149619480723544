import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { createTheme, ThemeProvider} from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';
import { GridActionsCellItem } from '@mui/x-data-grid';

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
}

const columns = [
  {
    field: "id",
    headerName: "No.",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "Sequence No",
    flex: 1,
    minWidth: 50,
    type: "number",
  },
  {
    field: "date",
    headerName: "Date",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "Date of payment",
    flex: 1,
    minWidth: 100,
    type: "string",
  },
  {
    field: "cardBrand",
    headerName: "Card Brand",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "Brand of the card",
    flex: 1,
    type: "string",
    minWidth: 100,
  },
  {
    field: "card",
    headerName: "Card",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "Last 4 digits of the card",
    flex: 1,
    type: "string",
    minWidth: 150,
  },
  {
    field: "amount",
    headerName: "Amount (USD)",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "Amount deducted from the card in USD",
    flex: 1,
    type: "number",
    minWidth: 120,
  },
  {
    field: "actions",
    headerName: "Receipt",
    headerClassName: "table-header",
    align: "center",
    headerAlign: "center",
    description: "Get Receipt",
    flex: 1,
    minWidth: 70,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem icon={<LaunchIcon />} onClick={() => openInNewTab(params.row.receipt)} label="Open Receipt" />
    ]
  }
];

const Table = ({ rows }) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      autoHeight={true}
      // disableColumnFilter
      // disableColumnSelector
      disableDensitySelector
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true },
        },
      }}
      pageSizeOptions={[5, 10, 15]}
      getRowSpacing={(params) => ({
        top: params.isFirstVisible ? 0 : 2,
        bottom: params.isLastVisible ? 0 : 2,
      })}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
    />
  );
};

const PaymentTable = (props) => {
  return (
    <div className="w-[95%] mx-auto my-4 md:w-[80%] lg:w-[70%]">
      <ThemeProvider theme={theme}>
        <Table rows={props.rows} />
      </ThemeProvider>
    </div>
  );
};

export default PaymentTable;
