import { Pie, PieChart, Cell, Legend, Tooltip } from 'recharts';

export default function AgreePieChart(props) {
  const { data } = props;

  //const COLORS = ['#459b76', '#dc3220', '#37474f'];
  const COLORS = ['#44AA99',  '#332288', '#DDCC77'];
  const renderBlackText = (value, entry) => {
    const color = '#ffffff';
    return <span style={{ color }}>{value}</span>;
  };

  return (
    <div className="grid grid-rows-6 bg-black text-white h-290px items-end mb-4">
      <span className="table-cell align-bottom text-center text-lg">Agree with assessment?</span>
      <div className="flex m-auto text-center row-span-5">
        <PieChart width={200} height={210} className="block">
          <Tooltip />
          <Pie
            isAnimationActive={false}
            blendStroke
            dataKey="value"
            startAngle={90}
            endAngle={-270}
            data={data}
            outerRadius={60}
            fill="#8884d8"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend formatter={renderBlackText} />
        </PieChart>
      </div>
    </div>
  );
}
