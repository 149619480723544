import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactCrop from "react-image-crop";
import { apiPredict } from "../../api/prediction";
import { Base64EncodeUrl } from "../../api/urlEncoding";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { trackIncrementalProperty } from "../../api/mixpanel";
import { getToken } from "../../api/auth";
import { useDropzone } from "react-dropzone";
import { PromptModalLayout } from "../utils/ModalLayout";
import { useNavigate } from "react-router-dom";
import { apiChargeUserOutOfScan } from "../../api/payment";
import ModalLayout from "../utils/ModalLayout";
import { PrimaryButton, DeleteButton } from "../utils/DefaultButtons";
import DropZoneTextDiv from "./DropZoneText";
import Loading from "../utils/Loading";
import "react-image-crop/dist/ReactCrop.css";

// styles for the drop zone
const baseStyle = {
  padding: "7%",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#374151",
  color: "#D1D5DB",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const imgBorderStyle = {
  display: "flex",
  justifyContent: "center",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
const notesDefault =
  "Please do not include any confidential or patient identifying information";

export default function AssessmentForm(props) {
  const { scanRemaining, perUsePrice, payAsGoUser } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [actualFile, setActualFile] = useState(null);
  const [dob, setDob] = useState(null);
  const [scanDate, setScanDate] = useState(new Date());
  const [notUploaded, setNotUploaded] = useState(null);
  const [errorDateDiff, setErrorDateDiff] = useState(null);
  const [confirmDateRange, setConfirmDateRange] = useState(null);
  const [, setContinueAnalyze] = useState(false);
  const [showOutOfScanModal, setOutOfScanShowModal] = useState();
  const [otherIndicationInput, setOtherIndicationInput] = useState(null);
  const [disabledContinue, setDisabledContinue] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const ref = useRef();

  // Used to control the rotation of the image
  const [imgRotation, setImgRotation] = useState(0);

  // For controling the Crop
  const [crop, setCrop] = useState(null);
  const [toCrop, setToCrop] = useState(false);

  const navigate = useNavigate();
  const onDrop = useCallback((file) => {
    if (file[0] != null || file[0] !== undefined) {
      setNotUploaded(null);
      setActualFile(file[0]);
      setSelectedFile(
        URL.createObjectURL(new Blob(file, { type: "application/zip" }))
      );
    }
  }, []);

  useEffect(() => {
    const checkDateError = () => {
      if (dob != null && scanDate != null) {
        dob.setHours(0, 0, 0, 0);
        scanDate.setHours(0, 0, 0, 0);

        // remove the time before making a check
        if (dob < scanDate) {
          setErrorDateDiff(null);
        } else {
          // show an error
          setErrorDateDiff("Date of scan must be after date of birth");
        }
      }
    };
    checkDateError();
  }, [dob, scanDate]);

  const onInsertFromClipboard = async () => {
    try {
      const clipboardData = await navigator.clipboard.read();
      if (clipboardData.length > 0) {
        const clipBoardItem = clipboardData[0];
        if (!clipBoardItem.types.includes("image/png")) {
          throw new Error("Clipboard contains non-image data");
        } else {
          const blob = await clipBoardItem.getType("image/png");
          const file = new File([blob], "uploadedFile.png");
          onDrop([file]);
        }
      } else {
        throw new Error("No items in the clipboard");
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isFocused, isDragReject } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: "image/jpeg,image/png,image/jpg",
    });

  const clickRotate = () => {
    if (imgRotation + 90 === 360) {
      setImgRotation(0);
    } else {
      setImgRotation(imgRotation + 90);
    }
  };

  /* Crop Functions */

  const startCrop = () => {
    var img = new Image();
    img.src = selectedFile;
    if (crop == null)
      setCrop({ x: 25, y: 25, width: 50, height: 50, unit: "%" });
    setToCrop(true);
  };

  const confirmCrop = () => {
    setToCrop(false);
    console.log("crop", crop);
  };

  const resetCrop = () => {
    // setCrop({x:0, y:0, width: 100, height: 100, unit: '%'});
    setCrop(null);
    setToCrop(false);
  };
  /***************************** */

  const handleSubmit = async (event) => {
    // Analyze function, makes a prediction
    event.preventDefault();

    if (selectedFile == null) {
      setNotUploaded("Image not found!");
    }

    if (selectedFile && errorDateDiff == null) {
      if (checkDateWithinRange()) {
        if (scanRemaining > 0) {
          const predict = await handleSubmitAnalyze(event);
          //console.log("click..",predict, Base64EncodeUrl(predict))
          const email = getToken().sub;
          trackIncrementalProperty(email, "Assessments");
          navigate("/result/?id=" + Base64EncodeUrl(predict));
        } else {
          setOutOfScanShowModal(event);
        }
      } else {
        setConfirmDateRange(event);
      }
    }
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const removeSelectedImage = (event) => {
    event.preventDefault();
    setSelectedFile(null);
    setActualFile(null);

    // Reset File Changes
    setCrop(null);
    setToCrop(false);
    setImgRotation(0);
  };

  const checkDateWithinRange = () => {
    // check if its within 19 years and 1 month
    if (dob != null) {
      // remove the time before making a check
      dob.setHours(0, 0, 0, 0);
      scanDate.setHours(0, 0, 0, 0);
      let date = new Date(scanDate.getTime()); //new Date();
      let date2 = new Date(scanDate.getTime());
      date.setFullYear(scanDate.getFullYear() - 19);
      date2.setMonth(scanDate.getMonth() - 1);

      return dob > date && dob < date2;
    }
  };

  if (isLoading === true) {
    return <Loading />;
  }

  const handleSubmitAnalyze = async (event) => {
    let sex = event.target.sex.value;
    let notes = event.target.notes.value; // grabs value
    let indication = event.target.indication.value;

    // format dates for the api request
    let dobNew = new Date(dob).toISOString().split("T");
    dobNew = dobNew[0];

    let scanDateNew = new Date(scanDate).toISOString().split("T");
    scanDateNew = scanDateNew[0];

    if (!notes || notes === notesDefault) {
      notes = null;
    }

    // set values to null if they don't exist since their optional
    if (indication === "none") {
      indication = null;
    } else if (indication === "other") {
      indication = event.target.otherIndication.value;
    }

    setLoading(true);
    //console.log("is true=", payAsGoUser, (showOutOfScanModal?!payAsGoUser:false));
    const predict = await apiPredict(
      actualFile,
      sex,
      dobNew,
      scanDateNew,
      notes,
      indication,
      showOutOfScanModal ? !payAsGoUser : false,
      imgRotation,
      crop
    );
    return predict;
  };

  const dateRangeModalContinue = async () => {
    if (scanRemaining <= 0) {
      setOutOfScanShowModal(confirmDateRange);
    } else {
      const predict = await handleSubmitAnalyze(confirmDateRange);
      navigate("/result/?id=" + Base64EncodeUrl(predict));
    }
  };

  const dateRangeModalBack = () => {
    setContinueAnalyze(false);
    setConfirmDateRange(null);
  };

  const outOfScanModalContinue = async () => {
    if (disabledContinue) {
      return;
    }
    setDisabledContinue(true);
    const res = await apiChargeUserOutOfScan();
    if (res.msg === "successful") {
      const predict = await handleSubmitAnalyze(showOutOfScanModal);
      setDisabledContinue(false);
      navigate("/result/?id=" + Base64EncodeUrl(predict));
    } else {
      setPaymentFailed(true);
      setDisabledContinue(false);
    }
  };

  const checkOtherSelected = (event) => {
    if (event.target.value === "other") {
      setOtherIndicationInput(true);
    } else {
      setOtherIndicationInput(null);
    }
  };

  const styles = {
    optionsBtn: "bg-gray-700 hover:bg-gray-600 text-gray-100 py-2 px-2 rounded",
  };

  return (
    <div className="text-white w-[95%] mx-auto">
      {confirmDateRange && (
        <PromptModalLayout
          text="The age is not within 1 month and 19 years from the scan date. Are you sure you would like to continue?"
          title="Unexpected Date Range"
          onActionFn={dateRangeModalContinue}
          onCancelFn={dateRangeModalBack}
          actionTitle="Continue"
          cancelTitle="Cancel"
        />
      )}
      {showOutOfScanModal && (
        <ModalLayout>
          <div className="flex justify-center text-xl text-slate-300 py-1">
            {payAsGoUser === true
              ? "Pay As You Go Reminder"
              : "Scan Limit Reached!"}
          </div>
          <hr />
          {payAsGoUser === true ? (
            <div className="flex text-sm text-gray-400 justify-center mt-2 mb-4">
              {`* You are a pay-as-you-go user. You will be charged $${perUsePrice.toFixed(
                2
              )} for assessments.`}
            </div>
          ) : (
            <div className="flex text-sm text-gray-400 justify-center mt-2 mb-4">
              {`* You have reached your scan limit for the current plan. For additional scan assessments $${perUsePrice.toFixed(
                2
              )} charges apply.`}
            </div>
          )}
          <div className="flex flex-row gap-4 justify-center">
            <button
              className={styles.optionsBtn}
              onClick={outOfScanModalContinue}
              disabled={disabledContinue}
            >
              Continue
            </button>
            <button
              className={styles.optionsBtn}
              onClick={() => navigate("/payments")}
              disabled={disabledContinue}
            >
              Upgrade Plan
            </button>
            <button
              className={styles.optionsBtn}
              onClick={() => setOutOfScanShowModal(null)}
              disabled={disabledContinue}
            >
              Cancel
            </button>
          </div>
        </ModalLayout>
      )}
      {paymentFailed && (
        <ModalLayout>
          <div className="flex justify-center text-xl text-slate-300 py-1">
            {" "}
            Your payment processing failed!
          </div>
          <hr />
          <div className="flex flex-row gap-4 justify-center mt-2">
            <button
              className={styles.optionsBtn}
              onClick={() => setPaymentFailed(false)}
            >
              Try again!
            </button>
          </div>
        </ModalLayout>
      )}
      <h2 className="m-4 mt-8 text-center text-3xl">New Bone Age Assessment</h2>
      <form
        className="grid grid-cols-1 mx-auto sm:w-[90%] xl:grid-cols-2 gap-8 m-2 p-4"
        onSubmit={handleSubmit}
      >
        <div>
          <div ref={ref}>
            {actualFile ? (
              <div style={imgBorderStyle}>
                <ReactCrop
                  crop={crop}
                  onChange={(c, pCrop) => setCrop(pCrop)}
                  disabled={!toCrop}
                  style={{
                    backgroundColor: "#374151",
                    // overflow: "visible",
                    // maxWidth: 600,
                  }}
                >
                  <img
                    style={{
                      transform: `rotate(${imgRotation}deg)`,
                    }}
                    src={selectedFile}
                    alt=""
                    className="object-fill w-full h-full"
                    // className="object-contain w-full h-full"
                  ></img>
                </ReactCrop>
              </div>
            ) : (
              /*DROPZONE HERE*/
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <DropZoneTextDiv />
              </div>
            )}
          </div>
          <p className="text-red-500 text-center m-2">{notUploaded}</p>
          {toCrop ? (
            <div className="flex justify-center gap-4 mt-2">
              <PrimaryButton
                onClick={confirmCrop}
                label={"Confirm Crop"}
                isSubmit={false}
              />
              <DeleteButton onClick={resetCrop} label="Reset Crop" />
            </div>
          ) : (
            <></>
          )}
          {!toCrop ? (
            <div className="flex justify-center mt-4 gap-2 flex-wrap">
              <PrimaryButton
                onClick={() => onInsertFromClipboard()}
                label="Insert from clipboard *"
                isSubmit={false}
              />
              {actualFile ? (
                <>
                  <PrimaryButton
                    onClick={clickRotate}
                    label="Rotate Image (90°)"
                    isSubmit={false}
                  />
                  <PrimaryButton
                    onClick={startCrop}
                    label="Crop Image"
                    isSubmit={false}
                  />
                  <DeleteButton
                    onClick={removeSelectedImage}
                    label="Remove Image"
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          ) : null}
          <div className="hidden lg:block">
            <p className=" text-strong text-sm my-4">
              * This feature may require browser update or permissions. See
              <a
                className="ml-1 text-underlined hover:text-blue-500 hover:text-underlined"
                href="/physis_user_manual.pdf"
                target="_blank"
              >
                User Manual
              </a>
            </p>
            <small>
              The training set used contained 12,612 images from two U.S.
              hospitals. Training set had a maximum age of 228 months (19
              years), minimum age of 1 month, mean age of 127 months (10 years 7
              months) and a standard deviation of 42 months (3 years 6 months).
            </small>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          {/*DATE PICKERS HERE*/}
          <label onClick={(e) => e.preventDefault()}>
            Date of Birth:{"  "}
            <DatePicker
              className="w-full"
              name="a"
              placeholderText="YYYY-MM-DD"
              selected={dob}
              dateFormat="yyyy-MM-dd"
              onChange={(val) => setDob(val)}
              autoComplete="off"
              required
            />
          </label>
          <p className="text-red-400">{errorDateDiff}</p>
          <label onClick={(e) => e.preventDefault()}>
            Date of Scan:{"  "}
            <DatePicker
              className="w-full"
              name="b"
              selected={scanDate}
              dateFormat="yyyy-MM-dd"
              onChange={(date) => setScanDate(date)}
              autoComplete="off"
              required
            />
          </label>

          <div className="flex flex-col">
            <label>Sex:</label>
            <div>
              <input
                className="mr-2"
                type="radio"
                value="1"
                name="sex"
                id="male"
                required
              />
              <label htmlFor="male">Male</label>
            </div>
            <div>
              <input
                className="mr-2"
                type="radio"
                value="0"
                name="sex"
                id="female"
                required
              />
              <label htmlFor="female">Female</label>
            </div>
          </div>

          <div>
            <label>Indication (optional):</label>
            <select
              onChange={checkOtherSelected}
              className="w-full p-3 bg-gray-700 text-white border-solid border border-slate-500"
              name="indication"
            >
              <option value="None"> None </option>
              <option value="Precocious puberty">Precocious puberty</option>
              <option value="Short stature">Short stature</option>
              <option value="Advanced growth"> Advanced growth</option>
              <option value="Scoliosis">Scoliosis</option>
              <option value="Pre-therapeutic">Pre-therapeutic</option>
              <option value="Gender Transition">Gender Transition</option>
              <option value="other">Other</option>
            </select>
            {!otherIndicationInput ? null : (
              <div className="flex row ml-2 gap-2">
                <input
                  className="p-1 bg-gray-700 text-white"
                  name="otherIndication"
                  placeholder="Please specify"
                  required
                />
              </div>
            )}
          </div>

          <label>
            Notes (optional):
            <div>
              <input
                className="w-full text-ellipsis"
                type="text"
                name="notes"
                placeholder={notesDefault}
              />
            </div>
          </label>
          <div
            className={
              toCrop
                ? "flex items-center justify-center invisible mt-4"
                : "flex items-center justify-center mt-4"
            }
          >
            <PrimaryButton isSubmit={true} label="Analyze" />
          </div>
        </div>
        <div className="block lg:hidden">
          <p className="text-strong text-sm my-4">
            * This feature may require browser update or permissions. See
            <a
              className="ml-1 text-underlined hover:text-blue-500 hover:text-underlined"
              href="/physis_user_manual.pdf"
              target="_blank"
            >
              User Manual
            </a>
          </p>
          <small>
            The training set used contained 12,612 images from two U.S.
            hospitals. Training set had a maximum age of 228 months (19 years),
            minimum age of 1 month, mean age of 127 months (10 years 7 months)
            and a standard deviation of 42 months (3 years 6 months).
          </small>
        </div>
      </form>
    </div>
  );
}
