import RegisterForm from "../components/Auth/RegisterForm";
import BasicLayout from "../components/Layout/BasicLayout";
import StepOrProgress from "../components/Payment/StepOrProgress";
import { useEffect } from "react";
import ReactGA from "react-ga";

const Register = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BasicLayout>
      <div className="flex flex-col gap-2 p-4">
        <StepOrProgress step={0} />
        <RegisterForm />
      </div>
    </BasicLayout>
  );
};

export default Register;
