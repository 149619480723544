import client from './axios';

export const apiAddToMailingList = async () => {
    try {
        const res = await client.put(`/mailchimp/add-mailing-list`);
        return res.data;
    } catch (err) {
        return { 'msg': 'failed' }
    }
};

export const apiUpdateSubscriber = async () => {
    try {
        const res = await client.post(`/mailchimp/update-subscriber`);
        return res.data;
    } catch (err) {
        return { 'msg': 'failed' }
    }
};