import BasicLayout from "../components/Layout/BasicLayout";
import TableKeyValue from "../components/Table/TableKeyValue";
import {
  PRODUCT_NAME,
  PRODUCT_VERSION,
  COMPANY,
  ADDRESS,
  GTIN,
} from "../constants.js";
import { useEffect } from "react";
import ReactGA from "react-ga";

const rows = [
  {
    key: "Software",
    value: PRODUCT_NAME,
  },
  {
    key: "Version",
    value: PRODUCT_VERSION,
  },
  {
    key: "Company",
    value: COMPANY,
  },
  {
    key: "Address",
    value: ADDRESS,
  },
  {
    key: "GTIN",
    value: GTIN + PRODUCT_VERSION,
  },
];

const About = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BasicLayout navbar={true}>
      <div className="text-center">
        <h1>About Physis</h1>
      </div>
      <TableKeyValue rows={rows} />
    </BasicLayout>
  );
};

export default About;
