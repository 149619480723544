import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const SimpleTable = ({ rows }) => {
  return (
    <TableContainer
      component={Paper}
      // sx={{ backgroundColor: "rgba(52, 52, 52, 0.8)" }}
    >
      <Table>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.key}>
              <TableCell
                width="40%"
                style={{
                  borderBottom: "1px solid gray",
                  borderTop: "1px solid gray",
                  borderLeft: "1px solid gray",
                  borderRight: "1px solid gray",
                  backgroundColor: "#1F2937",
                }}
              >
                {row.key}
              </TableCell>
              <TableCell
                // width="auto"
                width="60%"
                style={{
                  borderBottom: "1px solid gray",
                  borderTop: "1px solid gray",
                  borderRight: "1px solid gray",
                }}
              >
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableKeyValue = (props) => {
  return (
    <div className="w-[95%]  mx-auto my-4 md:w-[80%] lg:w-[60%]">
      <ThemeProvider theme={theme}>
        <SimpleTable rows={props.rows} />
      </ThemeProvider>
    </div>
  );
};

export default TableKeyValue;
