import { useEffect, useState } from "react";
import { apiGetUserPayData } from "../../api/payment";

export const TrialNotification = (props) => {
  const { date } = props;

  const [isTrial, setIsTrial] = useState(false);

  useEffect(() => {
    apiGetUserPayData().then((res) => {
      setIsTrial(res.inTrial);
    });
  }, []);

  return (
    <div>
      {isTrial && (
        <div className="bg-slate-800 rounded-md">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex flex-col">
              <div className="flex-1">
                <p className="p-2 font-small text-white">
                  <span>
                    You are currently on free trial until {date} and will have
                    complimentary access to the G&P Atlas and report generation
                    until then (unless your selected plan also includes those
                    features).
                  </span>
                </p>
              </div>
              <div>
                <button
                  type="button"
                  className="p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
                >
                  <button
                    className="bg-primary hover:bg-blue-700 max-w-sm h-11 text-white py-2 px-4 rounded border border-primary focus:outline-none focus:shadow-outline ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setIsTrial(false)}
                  >
                    Close
                  </button>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrialNotification;
