import { Pie, PieChart, Cell, Legend, Tooltip } from "recharts";

export default function SexPieChart(props) {
  const { data } = props;
  // const data = [
  //   { name: 'Male', value: 12 },
  //   { name: 'Female', value: 24 },
  // ];
  const COLORS = ["#88CCEE", "#CC6677"];

  const renderBlackText = (value, entry) => {
    const color = "#ffffff";
    return <span style={{ color }}>{value}</span>;
  };

  return (
    <div className="grid grid-rows-6 bg-black text-white h-290px items-end mb-4">
      <span className="table-cell align-bottom text-center text-lg">Sex</span>
      <div className="flex m-auto text-center row-span-5">
        <PieChart width={200} height={210} className="block">
          <Tooltip />
          <Pie
            isAnimationActive={false}
            blendStroke
            dataKey="value"
            startAngle={90}
            endAngle={-270}
            data={data}
            outerRadius={60}
            fill="#8884d8"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend formatter={renderBlackText} />
        </PieChart>
      </div>
    </div>
  );
}
