const ChangePlanCard = (props) => {
  const { planDetails, schedule, currentPlanValue, unsubDate } = props;
  const { onPlanChange } = props;

  const isSelected =
    planDetails.stripe_pricing_id[schedule] === currentPlanValue;

  const planStyle = isSelected ? "pricing-card-featured" : "pricing-card";

  // const btnStyle = isSelected ? "sub-button-select" : "sub-button";

  const btnStyle = () => {
    if (!isSelected) {
      return "sub-button";
    }
    if (!unsubDate && planDetails.plan_name !== "pay-as-you-go") return "sub-button-select";
    return "sub-button-disabled";
  }

  const blueTextStyle = isSelected
    ? "text-white grid place-items-center"
    : "text-blue-800 grid place-items-center";

  const slateTextStyle = isSelected ? "text-white" : "text-slate-800";

  const featureTextStyle = isSelected
    ? "feature-text w-5/6 text-white"
    : "feature-text w-5/6";

  const checkCircle = isSelected
    ? "/images/Check-Circle-White.svg"
    : "/images/check-circle-1.svg";

  return (
    // <div className={planStyle}>
    //   <h3 className="mb-5 capitalize">{planDetails.plan_name}</h3>
    //   {planDetails.plan_name !== 'pay-as-you-go' ? (
    //     schedule === 'monthly' ? (
    //       <h2 className="text-blue-400">{'$' + planDetails.plan_price.monthly.toFixed(2)}</h2>
    //     ) : (
    //       <h2 className="text-blue-400">{'$' + planDetails.plan_price.yearly.toFixed(2)}</h2>
    //     )
    //   ) : (
    //     <h2 className="text-blue-400">{'$' + planDetails.plan_price['per-use'].toFixed(2) + '/Inference'}</h2>
    //   )}
    //   <h5 className="text-gray-300 font-medium">{planDetails.plan_description[0]}</h5>
    //   {planDetails.plan_description.map((_perk, _ind) => {
    //     if (_ind <= 0) return <></>;
    //     <h4>{_ind}</h4>
    //     return (
    //       <div className="flex flex-row gap-2 w-full justify-start" key={_ind}>
    //         <div className="text-green-500 w-1/6">
    //           <CheckIcon />
    //         </div>
    //         <span className="w-5/6 mt-2 font-light">{_perk}</span>
    //       </div>
    //     );
    //   })}
    //   <div className="flex-grow"></div>
    //   <div className="h-2 bg-black z-5 w-full rounded-full"></div>
    //   <button className={btnStyle} onClick={onPlanChange}>
    //     {isSelected ? 'Unsubscribe' : 'Subscribe'}
    //   </button>
    // </div>
    <div className={planStyle}>
      {planDetails.plan_name === "Advanced" && (
        <div className={slateTextStyle + " text-center font-small"}>
          Most Popular Plan
        </div>
      )}
      {planDetails.plan_name !== "Advanced" && (
        <div className={slateTextStyle + " font-small"}>‎ </div>
      )}
      <h3 className={blueTextStyle}>
        {planDetails.plan_name.charAt(0).toUpperCase() +
          planDetails.plan_name.slice(1)}
      </h3>
      {planDetails.plan_name !== "pay-as-you-go" ? (
        schedule === "monthly" ? (
          <center>
            <h2 className={slateTextStyle}>
              {"$" + planDetails.plan_price.monthly.toFixed(2).slice(0, -3)}{" "}
              <sup className="relative top-px align-top text-sm">
                {planDetails.plan_price.monthly.toFixed(2).slice(-2)}
              </sup>
            </h2>
          </center>
        ) : (
          <center>
            <h2 className={slateTextStyle}>
              {"$" + planDetails.plan_price.yearly.toFixed(2).slice(0, -3)}{" "}
              <sup className="relative top-px align-top text-sm">
                {planDetails.plan_price.yearly.toFixed(2).slice(-2)}
              </sup>
            </h2>
          </center>
        )
      ) : (
        <div>
          <center>
            <h2 className={slateTextStyle + " inline"}>
              {"$" + planDetails.plan_price["per-use"].toFixed(2).slice(0, -3)}{" "}
              <sup className="relative top-px align-top text-sm">
                {planDetails.plan_price["per-use"].toFixed(2).slice(-2)}
              </sup>
            </h2>
            <div className={slateTextStyle + " inline"}>/Inference</div>
          </center>
        </div>
      )}
      {planDetails.plan_name !== "pay-as-you-go" ? (
        schedule === "monthly" ? (
          <center>
            <h5 className={slateTextStyle + " font-medium"}>per month</h5>
          </center>
        ) : (
          <center>
            <h5 className={slateTextStyle + " font-medium"}>per year</h5>
          </center>
        )
      ) : (
        <></>
      )}
      <div className="pricing-features">
        {planDetails.plan_description.map((_perk, _ind) => {
          if (_ind <= 0) return <></>;
          return (
            <div className="flex items-center box-border">
              <img src={checkCircle} alt="" className="mr-4 w-6" />
              <div className={featureTextStyle}>{_perk}</div>
            </div>
          );
        })}
      </div>
      <button className={btnStyle()} onClick={onPlanChange}>
        {isSelected ? "Unsubscribe" : "Subscribe"}
      </button>
    </div>
  );
};

export default ChangePlanCard;
