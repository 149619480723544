import BasicLayout from "../components/Layout/BasicLayout";
import { PrimaryButton } from "../components/utils/DefaultButtons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga";

const PageNotFound = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const navigate = useNavigate();
  const clickHandler = () => {
    navigate("/dashboard");
  };
  return (
    <BasicLayout navbar={true}>
      <h1 className="text-center">Page Not Found</h1>
      <div className="flex items-center justify-center my-4">
        <PrimaryButton label="Go to Dashboard" onClick={clickHandler} />
      </div>
    </BasicLayout>
  );
};

export default PageNotFound;
