const Header = () => {
  return (
    <div className="w-full bg-black border-b border-gray-700 flex flex-row sticky backdrop-blur-sm">
      <a href="https://www.16bit.ai/" target="_blank" rel="noopener">
        <img
          className="object-scale-down h-16 w-16 ml-4 text-white"
          src="/images/16bit-logo.png"
          alt="logo"
        />
      </a>
    </div>
  );
};

export default Header;
