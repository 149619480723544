import { getToken } from "../../api/auth";
import React from "react";
import { trackIncrementalProperty } from "../../api/mixpanel";
import { PrimaryButton } from "../utils/DefaultButtons";
import { useSwipeable } from "react-swipeable";

export default function ResultAtlas(props) {
  const {
    atlasPosition,
    displayHints,
    setAtlasPosition,
    setNextOff,
    setBackOff,
    setDisplayHints,
    backOff,
    nextOff,
    atlasData,
  } = props;

  const email = getToken().sub;

  const handlers = useSwipeable({
    onSwipedLeft: () => nextImage(),
    onSwipedRight: () => backImage(),
  });

  const keyDownHandler = (e) => {
    if (
      e.keyCode === 39 &&
      atlasPosition !== Object.keys(atlasData).length - 1
    ) {
      nextImage();
    } else if (e.keyCode === 37 && atlasPosition !== 0) {
      backImage();
    }
  };

  const nextImage = () => {
    trackIncrementalProperty(email, "Right Arrow Atlas");
    if (atlasPosition + 1 < Object.keys(atlasData).length - 1) {
      setAtlasPosition(atlasPosition + 1);
    }
    if (atlasPosition + 1 === Object.keys(atlasData).length - 1) {
      setAtlasPosition(Object.keys(atlasData).length - 1);
      setNextOff(true);
    } else {
      setBackOff(false);
    }
  };

  const backImage = () => {
    trackIncrementalProperty(email, "Left Arrow Atlas");
    if (atlasPosition - 1 > 0) {
      setAtlasPosition(atlasPosition - 1);
    }
    if (atlasPosition - 1 === 0) {
      setAtlasPosition(0);
      setBackOff(true);
    } else {
      setNextOff(false);
    }
  };

  const switchDisplayHints = () => {
    // Switch is to its opposite value
    trackIncrementalProperty(email, "Hint Toggles");
    if (!displayHints) {
      setDisplayHints(true);
    } else {
      setDisplayHints(false);
    }
  };

  const disableRightClick = (e) => {
    e.preventDefault(); // prevent default behavior of right-click
  };

  const preventImageDragging = (e) => {
    e.preventDefault();
  };

  return (
    <div className="mt-3" tabIndex={0} onKeyDown={keyDownHandler}>
      <div className="flex flex-col justify-center items-center">
        <h3 className="text-2xl text-center mt-2">
          {atlasData[atlasPosition]["caption"].substring(
            0,
            atlasData[atlasPosition]["caption"].indexOf(" ")
          )}
        </h3>
        <div className="flex justify-center items-center max-w-[600px] max-h-[600px] sm:w-[600px] sm:h-[600px] relative">
          {backOff === false ? (
            <input
              className="object-scale-down h-10 w-10 bg-black absolute left-2 border-white rounded border-1 focus:outline focus:bg-black"
              type="image"
              onClick={backImage}
              src="/images/leftArrow.png"
              alt=""
            />
          ) : (
            <div className="h-10 w-10 bg-black"></div>
          )}
          <img
            {...handlers} // for making the images swippable on mobile devices
            src={atlasData[atlasPosition]["filepath"]}
            alt=""
            className="object-contain w-full h-full select-none"
            onContextMenu={disableRightClick}
            onDragStart={preventImageDragging}
          />
          {nextOff === false ? (
            <input
              className="object-scale-down h-10 w-10 bg-black absolute right-2 border-white rounded border-1 focus:outline focus:bg-black"
              type="image"
              onClick={nextImage}
              src="/images/rightArrow.png"
              alt=""
            />
          ) : (
            <div className="h-10 w-10 bg-black"></div>
          )}
        </div>
      </div>
      <h3 className="text-2xl text-center mt-8">
        {atlasData[atlasPosition]["caption"].substring(
          atlasData[atlasPosition]["caption"].indexOf(" ") + 1
        )}
      </h3>

      {displayHints === false ? (
        <div className="flex flex-row justify-center my-4">
          <PrimaryButton label="Display Hints" onClick={switchDisplayHints} />
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-center my-4">
            <PrimaryButton label="Hide Hints" onClick={switchDisplayHints} />
          </div>
          <div className="my-4 overflow-y-scroll h-40">
            <p className="p-3" style={{ whiteSpace: "pre-line" }}>
              {atlasData[atlasPosition]["hints"]}
            </p>
          </div>
        </>
      )}
    </div>
  );
}
