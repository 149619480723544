import React from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/utils/Loading";
import { PrimaryButton } from "../components/utils/DefaultButtons";
import { useEffect, useState } from "react";
import { apiGetScanCount } from "../api/user";
import { trackIncrementalProperty } from "../api/mixpanel";
import { getToken } from "../api/auth";
import SexPieChart from "../components/Dashboard/SexPieChart";
import AgreePieChart from "../components/Dashboard/AgreePieChart";
import { apiGetDashboardData } from "../api/dashboard";
import IndicPieChart from "../components/Dashboard/IndicPieChart";
import TrialNotification from "../components/Dashboard/TrialNotification";
import BasicLayout from "../components/Layout/BasicLayout";
import TableDashboard from "../components/Table/TableDashboard";
import ReactGA from "react-ga";

export default function Dashboard() {
  let navigate = useNavigate();

  const [data, setData] = useState({
    graphData: {
      sexGraph: [],
      patientAgeDist: [],
      agreeGraph: [],
      indicGraph: [],
    },
    infTableMeta: { tableData: [], page: 1, maxPage: 1, maxRows: 1000 },
  });

  const [scanInfo, setScanInfo] = useState(null);
  const [sort, setSort] = useState("desc:inference_date");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const { infTableMeta } = data;
    apiGetDashboardData(infTableMeta.page, infTableMeta.maxRows, sort, setData);
    apiGetScanCount().then((res) => {
      setScanInfo(res);
      if (res["is_active"] === false) {
        navigate("/setup-payment");
      }
    });
  }, []);

  const clickAssessmentHandler = () => {
    setIsLoading(!isLoading);
    const email = getToken().sub;
    trackIncrementalProperty(email, "Assessments");
    navigate("/assessment");
  };

  if (!scanInfo || isLoading) {
    return <Loading />;
  }

  return (
    <BasicLayout navbar={true}>
      {scanInfo.endDate ? (
        <TrialNotification
          date={scanInfo.endDate.substring(0, scanInfo.endDate.indexOf("("))}
        />
      ) : (
        <></>
      )}
      <div className="w-[95%] mx-auto">
        <h2 className="text-center mb-6">Dashboard</h2>
        <div className="hidden lg:block">
          {data.infTableMeta.tableData.length > 0 ? (
            <div className="flex flex-row justify-evenly gap-2 my-5">
              <SexPieChart data={data.graphData.sexGraph} />
              <IndicPieChart data={data.graphData.indicGraph} />
              <AgreePieChart data={data.graphData.agreeGraph} />
            </div>
          ) : (
            <h4 className="my-5">No inferences found.</h4>
          )}
        </div>
        {scanInfo.scanRemaining <= 0 && scanInfo.payAsGoUser === false ? (
          <div className="flex flex-row justify-end">
            <PrimaryButton
              label="Upgrade Plan"
              onClick={() => navigate("/payments")}
            />
          </div>
        ) : (
          ""
        )}
        <div className="flex flex-row justify-end ">
          {scanInfo.payAsGoUser === false ? (
            <h3>
              {scanInfo.scanRemaining < 0
                ? "Scans Remaining: 0/" + scanInfo.scanLimit
                : "Scans Remaining: " +
                  scanInfo.scanRemaining +
                  "/" +
                  scanInfo.scanLimit}
            </h3>
          ) : (
            ""
          )}
        </div>
        <div className="flex flex-row justify-end mb-6">
          {scanInfo.payAsGoUser === false ? (
            <h5 className="text-gray-400">
              {"Until " +
                scanInfo.endDate.substring(0, scanInfo.endDate.indexOf("("))}
            </h5>
          ) : (
            ""
          )}
        </div>
        <div className="flex items-center justify-center my-4">
          <PrimaryButton
            label="Create a new assessment"
            onClick={clickAssessmentHandler}
          />
        </div>
        <TableDashboard rows={data.infTableMeta.tableData} />
      </div>
    </BasicLayout>
  );
}
