import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BasicLayout from "../components/Layout/BasicLayout";
import { apiSendResetPasswordLink } from "../api/auth";
import ModalLayout from "../components/utils/ModalLayout";
import ReactGA from "react-ga";

export default function SendResetPasswordLink() {
  const [username, setUserName] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await apiSendResetPasswordLink({ email: username });
    if (res["status"] === "failed") {
      setErrorMessage(res["status_message"]);
    } else if (res["status"] === "success") {
      setSuccessMessage(res["status_message"]);
    } else {
      setErrorMessage("Unexpected error has occurred.");
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <BasicLayout>
        <div className="flex p-4 place-content-center mt-12">
          <div className="items-center my-auto">
            <div className="flex flex-col items-center justify-center">
              <img
                className="object-scale-down h-24 w-48"
                src="images/16bit-logo.png"
                alt="logo"
              />
              <h5 className="text-2xl font-medium mt-4 mb-4 text-center">
                Physis&trade; Forgot Password?
              </h5>
              <h5 className="text-md font-medium pr-32">
                Enter your email address
              </h5>
            </div>
            <div>
              {successMessage && (
                <ModalLayout>
                  <div className="flex justify-center text-xl">
                    Password Reset
                  </div>
                  <div className="flex text-gray-400 justify-center my-2">
                    {successMessage}
                  </div>
                  <div className="flex flex-row gap-4 justify-center">
                    <button
                      className="bg-gray-700 hover:bg-gray-600 text-gray-100 py-2 px-4 rounded"
                      onClick={() => navigate("/login")}
                    >
                      OK
                    </button>
                  </div>
                </ModalLayout>
              )}
            </div>
            <div>
              <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
                <input
                  placeholder="email"
                  type="text"
                  onChange={(e) => setUserName(e.target.value.toLowerCase())}
                />
                <div className="mt-1 text-red-400">
                  {errorMessage && <p> {errorMessage} </p>}
                </div>
                <div>
                  <button
                    className="bg-blue-600 hover:bg-blue-700 mt-2 mb-2 w-full text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Reset password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </BasicLayout>
    </div>
  );
}
