import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import About from "./pages/About";
import SetupPayment from "./pages/SetupPayment";
import RegisterSuccess from "./pages/RegisterSuccess";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import { getToken } from "./api/auth";
import Assessment from "./pages/Assessment";
import Payment from "./pages/Payment";
import Result from "./pages/Result";
import SendResetPasswordLink from "./pages/SendResetPasswordLink";
import ResetPassword from "./pages/ResetPassword";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import PageNotFound from "./pages/404";
import ReactGA from "react-ga";
import { GOOGLE_ANALYTICS_TRACKING_ID } from "./constants";

// initialising google analytics
ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

const ProtectedRoute = ({ children, redirectTo }) => {
  const user = getToken();
  if (user === undefined) {
    return <Navigate to={redirectTo} />;
  }
  const expDate = new Date(user.exp * 1000);
  const timeNow = new Date();
  const expired = expDate < timeNow;

  const auth = user && user.sub && expired === false;
  return auth ? children : <Navigate to={redirectTo} />;
};

const App = () => {
  useEffect(() => {
    document.title = "Physis Bone Age Calculator";
  });

  return (
    <BrowserRouter>
      <meta name="keywords" content="Bone Age" />
      <meta name="keywords" content="Bone Age Calculator" />
      <meta name="keywords" content="Bone Age Atlas" />
      <meta name="keywords" content="Greulich and Pyle" />
      <meta name="keywords" content="Skeletal Age" />
      <meta
        name="description"
        content="<Pediatric bone age examinations are hand x-rays performed to assess a patient's skeletal age. Physis is a bone age calculator that provides a rapid, automated estimate using AI, based on the bone age atlas of Gruelich and Pyle. If you choose, your subscription can include access to a digital version of the G&P Atlas and automatic report generation.>"
      />
      <Routes>
        <Route exact path="" element={<Login />} />
        <Route exact path="login" element={<Login />} />
        <Route
          path="send-reset-password-link"
          element={<SendResetPasswordLink />}
        />
        <Route
          exact
          path="reset/:token"
          element={<ResetPassword animate={true} />}
        />
        <Route path="register" element={<Register />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route
          exact
          path="/dashboard"
          element={
            <ProtectedRoute redirectTo="/">
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/about"
          element={
            <ProtectedRoute redirectTo="/">
              <About />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/setup-payment"
          element={
            <ProtectedRoute redirectTo="/">
              <SetupPayment />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/register-success"
          element={
            <ProtectedRoute redirectTo="/">
              <RegisterSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <ProtectedRoute redirectTo="/">
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/payments"
          element={
            <ProtectedRoute redirectTo="/">
              <Payment />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/assessment"
          element={
            <ProtectedRoute redirectTo="/login">
              <Assessment />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/result"
          element={
            <ProtectedRoute redirectTo="/login">
              <Result />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="*"
          element={
            <ProtectedRoute redirectTo="/">
              <PageNotFound />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
