import { apiRegister } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { FormikInput, FormikSelect } from "../utils/FormikFields";
import { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { CountryDropdown } from "react-country-region-selector";
import { trackUserDetails } from "../../api/mixpanel";
import { apiAddToMailingList } from "../../api/mailchimp";
import { Link } from "react-router-dom";

const handleSubmit = async (
  values,
  navigate,
  setEmailErr,
  setAgreementErr,
  setCountryErr
) => {
  const copy = { ...values };
  Object.keys(copy).forEach((key) => {
    if (key === "password" || key === "confirmPswd") {
      delete copy[key];
    }
  });

  if (values.isAgreement === false) {
    setAgreementErr(
      "You must accept the terms and policies to register an account"
    );
    setCountryErr("");
    setEmailErr("");
  } else if (values.country === "") {
    setCountryErr("Required");
    setAgreementErr("");
    setEmailErr("");
  } else {
    setAgreementErr("");
    const err = await apiRegister(values);
    if (!err) {
      await apiAddToMailingList();
      await trackUserDetails(copy);
      navigate("/setup-payment");
      // navigate('/setup-payment', {replace: true});
    } else if (err.msg !== "") {
      setEmailErr(err.msg);
    }
  }
};

export default function RegisterForm() {
  const navigate = useNavigate();
  const [emailErr, setEmailErr] = useState("");
  const [agreementErr, setAgreementErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [nonClinicalModal, setNonClinicalModal] = useState(false);
  const [agreementClicked, setAgreementClicked] = useState(false);

  const formikRef = useRef();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPswd: "",
    institution: "",
    phoneNum: "",
    country: "",
    // whyPhysis: '',
    specialty: "",
    isSubscribed: false,
    modalAgreement: false,
    isAgreement: false,
  };

  const checkCountry = (e) => {
    if ((e.target.value !== "Canada") & (e.target.value !== "")) {
      setNonClinicalModal(true);
    } else {
      setAgreementClicked(false);
    }
  };

  useEffect(() => {
    formikRef.current.setFieldValue("modalAgreement", agreementClicked);
  }, [agreementClicked]);

  // https://stackoverflow.com/questions/52483260/validate-phone-number-with-yup
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const registerValidation = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .min(8, "Must be 8 characters or more")
      .required("Required"),
    confirmPswd: Yup.string()
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      })
      .required("Required"),
    phoneNum: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    institution: Yup.string().required("Required"),
    // country: Yup.string().required('Required'),
    // whyPhysis: Yup.string(),
    specialty: Yup.string().required("Required"),
    // isAgreement: Yup.bool().oneOf([true], 'Field must be checked'),
  });
  const styles = {
    disabledOptions: "hidden hover:none focus:none active:none",
  };
  return (
    <div className="flex flex-col justify-center items-center">
      {nonClinicalModal && (
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 p-3 max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="relative p-6 flex-auto">
                  <p className="m-4 text-slate-500 text-lg leading-relaxed">
                    I attest that I am not using Physis<sup>TM</sup> for
                    clinical purposes, and I acknowledge that it does not yet
                    have regulatory approval in my jurisdiction.
                  </p>
                </div>
                <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-primary hover:bg-blue-700 max-w-sm h-11 text-white py-2 px-4 rounded border border-primary focus:outline-none focus:shadow-outline ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setNonClinicalModal(false);
                      setAgreementClicked(true);
                    }}
                  >
                    I Understand
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={registerValidation}
        innerRef={formikRef}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(val) =>
          handleSubmit(
            val,
            navigate,
            setEmailErr,
            setAgreementErr,
            setCountryErr,
            setNonClinicalModal
          )
        }
      >
        {({ values, handleChange, handleBlur }) => (
          <Form className="grid grid-cols-1 gap-4 content-evenly">
            <div className="grid grid-cols-2 gap-4">
              <FormikInput
                label="First Name"
                name="firstName"
                type="text"
                placeholder="First Name"
              />
              <FormikInput
                label="Last Name"
                name="lastName"
                type="text"
                placeholder="Last name"
              />
            </div>

            <FormikInput
              label="Email Address"
              name="email"
              type="email"
              placeholder="Email Address"
            />
            {emailErr && <div className="text-red-400">{emailErr}</div>}

            <FormikInput
              label="Password"
              name="password"
              type="password"
              placeholder="Password"
            />

            <FormikInput
              label="Confirm Password"
              name="confirmPswd"
              type="password"
              placeholder="Confirm Password"
            />

            <FormikInput
              label="Institution"
              name="institution"
              type="text"
              placeholder="Your Institution"
            />

            <FormikInput
              label="Phone Number"
              name="phoneNum"
              type="tel"
              placeholder="Phone Number"
            />

            <p className="mt-4 text-white-500">Institution Country</p>

            {/* <FormikInput label="Country" name="country" type="text" placeholder="Enter Country" /> */}
            <CountryDropdown
              id="CountryDropdown1"
              className="p-3 placeholder:text-gray-400 border border-gray-500 bg-gray-700 text-gray-200 focus:border-gray-600"
              label="Country"
              name="country"
              value={values.country}
              onChange={(_, e) => {
                handleChange(e);
                checkCountry(e);
              }}
              onBlur={handleBlur}
            />

            {countryErr && <div className="text-red-400">{countryErr}</div>}

            <FormikSelect label="Specialty" name="specialty">
              <option className={styles.disabledOptions} value="" disabled>
                Select a specialty
              </option>
              <option value="Radiology (non-pediatric)">
                Radiology (non-pediatric)
              </option>
              <option value="Radiology (pediatric)">
                Radiology (pediatric)
              </option>
              <option value="Family Medicine">Family Medicine</option>
              <option value="Endocrinology">Endocrinology</option>
              <option value="Orthopedics">Orthopedics</option>
              <option value="other">Other</option>
            </FormikSelect>

            {/* <FormikSelect label="Why are you using Physis?" name="whyPhysis">
            <option className={styles.disabledOptions} value="" disabled>
              Select a reason
            </option>
            <option value="Clinical Use">Clinical Use</option>
            <option value="Research Purposes">Research Purposes</option>
            <option value="Personal">Personal</option>
          </FormikSelect> */}

            <p className="mt-4 text-gray-500">
              <label>
                <Field type="checkbox" name="isAgreement" />{" "}
              </label>
              I agree to 16Bit's{" "}
              <Link to="/terms" target="_blank">
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link to="/privacy" target="_blank">
                Privacy Policy
              </Link>
            </p>

            {agreementErr && <div className="text-red-400">{agreementErr}</div>}

            <p className="mt-4 text-gray-500">
              <label>
                <Field type="checkbox" name="isSubscribed" />{" "}
              </label>
              I would like to opt in to receive news, special offers and general{" "}
              <br />
              information from 16 Bit about other goods, services and events
              offered.
            </p>

            <button
              className="bg-blue-600 hover:bg-blue-700 mt-2 mb-2 w-full text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create Account
            </button>
            <p className="mb-10">
              Already a User? <Link to="/login">Click here to login</Link>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
}
