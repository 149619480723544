import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicLayout from "../components/Layout/BasicLayout";
import { apiEmailUsingToken, apiResetPassword } from "../api/auth";
import ModalLayout from "../components/utils/ModalLayout";
import ReactGA from "react-ga";

const getEmail = async (dict, setEmail, setMessage) => {
  let res = await apiEmailUsingToken(dict);

  if (res["status"] === "success") {
    setEmail(res["status_message"]);
  } else {
    setMessage(res["status_message"]);
  }
};

const ResetPassword = ({ animate }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [pswdValMessage, setPswdValMessage] = useState(false);
  const navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    getEmail(param, setEmail, setMessage);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setPswdValMessage(true);
    } else {
      setPswdValMessage(false);
      let res = await apiResetPassword({ email: email, password: password });
      setMessage(res["status_message"]);
    }
  };
  return (
    <BasicLayout>
      <div className="flex p-4 place-content-center mt-12">
        <div className="items-center">
          <div className="flex flex-col items-center justify-center">
            <img
              className="object-scale-down h-24 w-48"
              src="/images/16bit-logo.png"
              alt="logo"
            />
            <h5 className="text-2xl font-medium mt-4 mb-4 text-center">
              Physis&trade; Reset Password
            </h5>
          </div>
          <div>
            {message && (
              <ModalLayout>
                <div className="flex justify-center text-xl">
                  Password reset
                </div>
                <div className="flex text-gray-400 justify-center my-2">
                  {message}
                </div>
                <div className="flex flex-row gap-4 justify-center">
                  <button
                    className="bg-gray-700 hover:bg-gray-600 text-gray-100 py-2 px-4 rounded"
                    onClick={() => navigate("/login")}
                  >
                    OK
                  </button>
                </div>
              </ModalLayout>
            )}
          </div>
          <div>
            <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
              <input
                placeholder="email"
                type="email"
                name="email"
                readOnly
                value={email}
              />
              <h5 className="text-md font-medium pr-32">Enter new password</h5>
              <input
                placeholder="password"
                type="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {pswdValMessage && (
                <div className="mt-1 text-red-400">
                  Must be 8 characters or more
                </div>
              )}
              <div>
                <button
                  className="bg-blue-600 hover:bg-blue-700 mt-2 mb-2 w-full text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Reset password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default ResetPassword;
