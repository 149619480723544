import { Link } from "react-router-dom";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import { clearToken } from "../../api/auth";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material";

const pages = [{ name: "Dashboard", link: "/dashboard" }];

const settings = [
  { name: "Profile", link: "/profile" },
  { name: "Payments", link: "/payments" },
];

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#000000",
    },
  },
});

const ImageLogo = () => {
  return (
    <a href="https://www.16bit.ai/" target="_blank" rel="noopener">
      <img
        className="object-scale-down h-12 w-16 mr-2 text-white"
        src="/images/16bit-logo.png"
        alt="logo"
      />
    </a>
  );
};

const SupportMenuItems = () => {
  return (
    <>
      <MenuItem key="about" component={Link} to="/about">
        About Physis
      </MenuItem>
      <MenuItem
        key="user-manual"
        component="a"
        href="/physis_user_manual.pdf"
        target="_blank"
        rel="noopener"
      >
        User Manual
      </MenuItem>
      <MenuItem
        key="contact-us"
        component="a"
        href="https://www.16bit.ai/contact"
        target="_blank"
        rel="noopener"
      >
        Contact Us
      </MenuItem>
    </>
  );
};

const UserMenuItems = () => {
  return (
    <>
      {settings.map((setting) => (
        <MenuItem key={setting.name} component={Link} to={setting.link}>
          {setting.name}
        </MenuItem>
      ))}
      <MenuItem key="Logout" component={Link} to="/" onClick={clearToken}>
        Logout
      </MenuItem>
    </>
  );
};

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElSupport, setAnchorElSupport] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenSupportMenu = (event) => {
    setAnchorElSupport(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseSupportMenu = () => {
    setAnchorElSupport(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Small page setup */}
            {/* 16 bit small screen image */}
            <Box
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <ImageLogo />
            </Box>
            {/* display hamburger menu in small screen size */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={page.link}
                  >
                    {page.name}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* ------------------------------------------------- */}
            {/* Wide page setup starts  */}
            {/* Display 16Bit logo on the left on a big screen size and don't display on small
                screen sizes */}
            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, mr: 1 }}
            >
              <ImageLogo />
            </Box>
            {/* Display page buttons on wide screen */}
            <Box sx={{ mr: 1, display: { xs: "none", md: "block" } }}>
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  <Typography textAlign="center">
                    <Link
                      to={page.link}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {page.name}
                    </Link>
                  </Typography>
                </Button>
              ))}
            </Box>

            {/* ------------------------------------------------- */}
            {/* Support Section is the same for both small and wide screen */}
            <Box sx={{ flexGrow: 0, mr: 1 }}>
              <Typography
                onClick={handleOpenSupportMenu}
                sx={{ cursor: "pointer" }}
              >
                Support <ArrowDropDownIcon />
              </Typography>
              <Menu
                sx={{ mt: "45px" }}
                id="support-appbar"
                anchorEl={anchorElSupport}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElSupport)}
                onClose={handleCloseSupportMenu}
              >
                <SupportMenuItems />
              </Menu>
            </Box>
            {/* ------------------------------------------------- */}
            {/* User Icon is the same for both small and wide screen */}
            {/* Display user icon */}
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Person" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <UserMenuItems />
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}

export default ResponsiveAppBar;
